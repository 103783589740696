<div class="content confirm-modal-content">
    <div class="message-wrapper">
      <div class="message">
        <div class="message-content" [innerHtml]="getHtml(data.html)"></div>
      </div>
    </div>
  
    <div class="control-block">
      <button class="button-cancel button" (click)="closeDialog()" [ngStyle]="
      styleService.getStyleRule(
      [data.styles.buttonCancel
      .text],
      [
        data.styles.buttonCancel
        .background,
        { color: data.mainTemplate.globalVariables.buttonsColor.secondaryColor }
      ],
      [data.styles.buttonCancel
      .border])">
            <mat-icon>close</mat-icon>
        {{'Cancel' | conditionalUppercase
        : styleService.getUppercaseValue(
          data.styles.buttonCancel.text
        )}}
      </button>
      <button class="button-confirm button" (click)="confirm()" [ngStyle]="
            styleService.getStyleRule(
            [data.styles.buttonConfirm.text],
            [
              data.styles.buttonConfirm.background,
              { color: data.mainTemplate.globalVariables.buttonsColor.primaryColor }
            ],
            [data.styles.buttonConfirm.border])">
            <mat-icon>check</mat-icon>
        {{'Confirm' | conditionalUppercase
        : styleService.getUppercaseValue(
          data.styles.buttonConfirm.text
        )}}
      </button>
    </div>
  </div>