import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  Injector,
  Input,
} from '@angular/core';
import { BaseComponent } from '../../../../components';
import { MainTemplate } from '../../../../models/template';
import { ProfileService, StyleService } from '../../../../services';
import {
  ConversionType,
  RedemptionTemplateConfigModel,
  RedemptionsConfigModel,
  TierLevels,
} from '../../../../models/redemption-template.model';
import { InfoModalComponent } from './../../../../components/info-modal/info-modal.component';
import { ConfirmRedeemModalComponent } from '../../../../components/confirm-redeem/confirm-redeem-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { TabType } from '../../../../models/template';
import { DataPointService } from '../../../../services/data-point.service';
import { RedemptionTemplateComponent } from '../redemption-template/redemption-template.component';

@Component({
  selector: 'app-redemption-v2-template',
  templateUrl: './redemption-v2-template.component.html',
  styleUrls: ['./redemption-v2-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RedemptionV2TemplateComponent
  extends RedemptionTemplateComponent
  implements OnInit, OnDestroy
{
  public sourceBucket: any = '';

  constructor(protected override readonly injector: Injector) {
    super(injector);
  }

  override ngOnInit(): void {}
}
