<ng-container *ngIf="!isLoading">
  <div
    class="offers-last__item"
    *ngIf="type === 'Campaign'"
    [ngClass]="{
      'ofc-draw-disabled': isPending
    }"
    [ngStyle]="
      styleService.getStyleRule(
        undefined,
        [tab.configurations.cardDrawing.background],
        [
          tab.configurations.cardDrawing.border,
          mainTemplate.globalVariables.borders
        ],
        [tab.configurations.cardDrawing.shadow]
      )
    "
  >
    <div class="offers-last__item-img-wrapper">
      <img [src]="drawing.imageUrl" class="btn-ico" />
    </div>
    <div class="offers-last__item-content">
      <h3
        class="offers-last__item-name"
        [ngStyle]="
          styleService.getStyleRule([
            tab.configurations.cardDrawing.title,
            undefined,
            'heading4'
          ])
        "
      >
        {{
          drawing.title
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tab.configurations.cardDrawing.title
                )
        }}
      </h3>
      <p
        class="offers-last__item-description"
        [ngStyle]="
          styleService.getStyleRule([
            tab.configurations.cardDrawing.description,
            undefined,
            'heading6'
          ])
        "
      >
        {{
          (drawing.entriesAmount + (drawing.ucBalance ?? 0) | number) +
            " Drawing Entries"
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tab.configurations.cardDrawing.description
                )
        }}
      </p>
      <div class="offers-last__item-content-bottom">
        <div class="offers-last__item-line"></div>
        <div class="offers-last__item-btn-group">
          <button
            *ngIf="!tab.configurations.cardDrawing.buttonDetail.isHidden"
            class="offers-last__item-btn"
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.cardDrawing.buttonDetail.text],
                [tab.configurations.cardDrawing.buttonDetail.background],
                [tab.configurations.cardDrawing.buttonDetail.border]
              )
            "
            (click)="openDetail()"
          >
            {{
              "Details"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.cardDrawing.buttonDetail.text
                    )
            }}
          </button>
          <button
            class="offers-last__item-btn second"
            *ngIf="showActivateCampaignBtn()"
            [class.ofc-big-btn]="
              tab.configurations.cardDrawing.buttonDetail.isHidden
            "
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.cardDrawing.buttonActivate.text],
                [tab.configurations.cardDrawing.buttonActivate.background],
                [tab.configurations.cardDrawing.buttonActivate.border]
              )
            "
            (click)="drawingActivate()"
          >
            {{
              "Activate"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.cardDrawing.buttonActivate.text
                    )
            }}
          </button>

          <button
            *ngIf="showActivateCampaignDisabledBtn()"
            class="offers-last__item-btn second disabled"
            [class.ofc-big-btn]="
              tab.configurations.cardDrawing.buttonDetail.isHidden
            "
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.cardDrawing.buttonActivateDisabled?.text],
                [
                  tab.configurations.cardDrawing.buttonActivateDisabled
                    ?.background
                ],
                [tab.configurations.cardDrawing.buttonActivateDisabled?.border]
              )
            "
          >
            {{
              "Activate"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.cardDrawing.buttonActivateDisabled
                        ?.text
                    )
            }}
          </button>

          <button
            *ngIf="showActivatedCampaignBtn()"
            class="offers-last__item-btn second"
            [class.ofc-big-btn]="
              tab.configurations.cardDrawing.buttonDetail.isHidden
            "
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.cardDrawing.buttonActivated.text],
                [tab.configurations.cardDrawing.buttonActivated.background],
                [tab.configurations.cardDrawing.buttonActivated.border]
              )
            "
          >
            {{
              "Activated!"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.cardDrawing.buttonActivated.text
                    )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="offers-last__item"
    *ngIf="type === 'FreeEntry'"
    [ngStyle]="
      styleService.getStyleRule(
        undefined,
        [tab.configurations.cardFreeEntry.background],
        [
          tab.configurations.cardFreeEntry.border,
          mainTemplate.globalVariables.borders
        ],
        [tab.configurations.cardFreeEntry.shadow]
      )
    "
  >
    <div class="offers-last__item-img-wrapper">
      <img [src]="drawing.freeEntryImageUrl" class="btn-ico" />
    </div>
    <div class="offers-last__item-content">
      <h3
        class="offers-last__item-name"
        [ngStyle]="
          styleService.getStyleRule([
            tab.configurations.cardFreeEntry.title,
            undefined,
            'heading4'
          ])
        "
      >
        {{
          drawing.title
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tab.configurations.cardFreeEntry.title
                )
        }}
      </h3>
      <p
        class="offers-last__item-description"
        *ngIf="drawing.awardFreeEntry"
        [ngStyle]="
          styleService.getStyleRule([
            tab.configurations.cardFreeEntry.description,
            undefined,
            'heading6'
          ])
        "
      >
        {{
          "Free Entry"
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tab.configurations.cardFreeEntry.descriptuin
                )
        }}
      </p>
      <div class="offers-last__item-content-bottom">
        <div class="offers-last__item-line"></div>
        <div class="offers-last__item-btn-group">
          <button
            class="offers-last__item-btn"
            *ngIf="!tab.configurations.cardDrawing.buttonDetail.isHidden"
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.cardFreeEntry.buttonDetail.text],
                [tab.configurations.cardFreeEntry.buttonDetail.background],
                [tab.configurations.cardFreeEntry.buttonDetail.border]
              )
            "
            (click)="openDetail()"
          >
            {{
              "Details"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.cardFreeEntry.buttonDetail.text
                    )
            }}
          </button>
          <button
            class="offers-last__item-btn second"
            *ngIf="!isActivated"
            [class.ofc-big-btn]="
              tab.configurations.cardDrawing.buttonDetail.isHidden
            "
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.cardFreeEntry.buttonActivate.text],
                [tab.configurations.cardFreeEntry.buttonActivate.background],
                [tab.configurations.cardFreeEntry.buttonActivate.border]
              )
            "
            (click)="freeEntryActivate()"
          >
            {{
              "Activate"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.cardFreeEntry.buttonActivate.text
                    )
            }}
          </button>
          <button
            class="offers-last__item-btn second"
            *ngIf="isActivated"
            [class.ofc-big-btn]="
              tab.configurations.cardDrawing.buttonDetail.isHidden
            "
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.cardFreeEntry.buttonActivated.text],
                [tab.configurations.cardFreeEntry.buttonActivated.background],
                [tab.configurations.cardFreeEntry.buttonActivated.border]
              )
            "
          >
            {{
              "Activated!"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.cardFreeEntry.buttonActivated.text
                    )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="offers-last__item"
    *ngIf="type === 'TierEntry' && !!tab.configurations?.cardTierEntry"
    [ngStyle]="
      styleService.getStyleRule(
        undefined,
        [tab.configurations.cardTierEntry.background],
        [
          tab.configurations.cardTierEntry.border,
          mainTemplate.globalVariables.borders
        ],
        [tab.configurations.cardTierEntry.shadow]
      )
    "
  >
    <div class="offers-last__item-img-wrapper">
      <img
        [src]="tierEntry?.tierEntriesImageUri || drawing.imageUrl"
        class="btn-ico"
      />
    </div>
    <div class="offers-last__item-content">
      <h3
        class="offers-last__item-name"
        [ngStyle]="
          styleService.getStyleRule([
            tab.configurations.cardTierEntry.title,
            undefined,
            'heading4'
          ])
        "
      >
        {{
          drawing.title
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tab.configurations.cardTierEntry.title
                )
        }}
      </h3>
      <p
        class="offers-last__item-description"
        [ngStyle]="
          styleService.getStyleRule([
            tab.configurations.cardTierEntry.description,
            undefined,
            'heading6'
          ])
        "
      >
        {{
          tierEntry?.amount + " Drawing Tier Entries"
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tab.configurations.cardTierEntry.description
                )
        }}
      </p>
      <div class="offers-last__item-content-bottom">
        <div class="offers-last__item-line"></div>
        <div class="offers-last__item-btn-group">
          <button
            class="offers-last__item-btn"
            *ngIf="!tab.configurations.cardDrawing.buttonDetail.isHidden"
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.cardTierEntry.buttonDetail.text],
                [tab.configurations.cardTierEntry.buttonDetail.background],
                [tab.configurations.cardTierEntry.buttonDetail.border]
              )
            "
            (click)="openDetail()"
          >
            {{
              "Details"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.cardTierEntry.buttonDetail.text
                    )
            }}
          </button>
          <button
            class="offers-last__item-btn second"
            *ngIf="!isActivated"
            [class.ofc-big-btn]="
              tab.configurations.cardDrawing.buttonDetail.isHidden
            "
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.cardTierEntry.buttonActivate.text],
                [tab.configurations.cardTierEntry.buttonActivate.background],
                [tab.configurations.cardTierEntry.buttonActivate.border]
              )
            "
            (click)="tierEntryActivate()"
          >
            {{
              "Activate"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.cardTierEntry.buttonActivate.text
                    )
            }}
          </button>
          <button
            class="offers-last__item-btn second"
            *ngIf="isActivated"
            [class.ofc-big-btn]="
              tab.configurations.cardDrawing.buttonDetail.isHidden
            "
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.cardTierEntry.buttonActivated.text],
                [tab.configurations.cardTierEntry.buttonActivated.background],
                [tab.configurations.cardTierEntry.buttonActivated.border]
              )
            "
          >
            {{
              "Activated!"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.cardTierEntry.buttonActivated.text
                    )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="offers-last__item"
    *ngIf="type === 'Multiplier' && multiplier"
    [ngStyle]="
      styleService.getStyleRule(
        undefined,
        [tab.configurations.cardMultiplier.background],
        [
          tab.configurations.cardMultiplier.border,
          mainTemplate.globalVariables.borders
        ],
        [tab.configurations.cardMultiplier.shadow]
      )
    "
  >
    <div class="offers-last__item-img-wrapper">
      <img [src]="multiplier.multiplierImageUri.value" class="btn-ico" />
    </div>
    <div class="offers-last__item-content">
      <h3
        class="offers-last__item-name"
        [ngStyle]="
          styleService.getStyleRule([
            tab.configurations.cardMultiplier.title,
            undefined,
            'heading4'
          ])
        "
      >
        {{
          drawing.title
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tab.configurations.cardMultiplier.title
                )
        }}
      </h3>
      <p
        class="offers-last__item-description"
        [ngStyle]="
          styleService.getStyleRule([
            tab.configurations.cardMultiplier.description,
            undefined,
            'heading6'
          ])
        "
      >
        {{
          multiplier.rate + "X Drawing Multiplier"
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tab.configurations.cardMultiplier.description
                )
        }}
      </p>
      <div class="offers-last__item-content-bottom">
        <div class="offers-last__item-line"></div>
        <div class="offers-last__item-btn-group">
          <button
            class="offers-last__item-btn"
            *ngIf="!tab.configurations.cardDrawing.buttonDetail.isHidden"
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.cardMultiplier.buttonDetail.text],
                [tab.configurations.cardMultiplier.buttonDetail.background],
                [tab.configurations.cardMultiplier.buttonDetail.border]
              )
            "
            (click)="openDetail()"
          >
            {{
              "Details"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.cardMultiplier.buttonDetail.text
                    )
            }}
          </button>
          <button
            class="offers-last__item-btn second"
            *ngIf="!isActivated"
            [class.ofc-big-btn]="
              tab.configurations.cardDrawing.buttonDetail.isHidden
            "
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.cardMultiplier.buttonActivate.text],
                [tab.configurations.cardMultiplier.buttonActivate.background],
                [tab.configurations.cardMultiplier.buttonActivate.border]
              )
            "
            (click)="multiplierActivate()"
          >
            {{
              "Activate"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.cardMultiplier.buttonActivate.text
                    )
            }}
          </button>
          <button
            class="offers-last__item-btn second"
            *ngIf="isActivated"
            [class.ofc-big-btn]="
              tab.configurations.cardDrawing.buttonDetail.isHidden
            "
            [ngStyle]="
              styleService.getStyleRule(
                [tab.configurations.cardMultiplier.buttonActivated.text],
                [tab.configurations.cardMultiplier.buttonActivated.background],
                [tab.configurations.cardMultiplier.buttonActivated.border]
              )
            "
          >
            {{
              "Activated!"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.cardMultiplier.buttonActivated.text
                    )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
