import { Injectable } from '@angular/core';
import {
  WebLinkClient,
  EventClass,
  TaskSequence,
} from '../../assets/scripts/web-link-api';
import { LogService } from './logs.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TemplateService } from './template.service';
import {
  Snackbars,
} from '../models';
import { SnackbarComponent } from '../components';
import { MDEV_CONFIG, NRTKIOSK_CONFIG, TEMPLATE_VARIABLES, TEMPLATE_VARIABLES_DYNAMICS } from '../constants/print';
import { environment } from 'projects/kiosk-app/src/environments/environment';
import { AuthService } from './auth.service';
import moment from 'moment';
import uniq from 'lodash/uniq';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  public isDebugModeEnabled = false;
  private printUrl: string = '';
  private kioskId: any;
  private WebLink: any;
  private isConnected = false;
  private Machines = {
    NRTKIOSK: NRTKIOSK_CONFIG,
    MDEV: MDEV_CONFIG,
  };
  private snackbar: Snackbars = {} as Snackbars;

  constructor(
    private readonly logService: LogService,
    private readonly templateService: TemplateService,
    private readonly authService: AuthService,
    private snackBar: MatSnackBar
  ) {
    this.templateService.templateData$().subscribe((template) => {
      this.snackbar = template?.components?.snackbars;
    });
  }

  public preparePage(pageTemplate: string, params: any, htmlPath?: string, cssPath?: string): string {
    let page = `${environment.apiUrl}/api/kioskbuilder/voucher`;
    page += `?kioskId=${this.authService.kioskId}`;
    page += `&voucherBasePath=pageLinks[${params.pageIndex}].templateSettings[${params.templateIndex}].configurations.voucher`;
    
    if (htmlPath) {
      page += `&htmlPath=${htmlPath}`;
    }
    if (cssPath) {
      page += `&cssPath=${cssPath}`;
    }
    
    page += '&posCodeScale=0.25';

    this.findVariables(pageTemplate).forEach((variable: string) => {
      let data = '';
      switch (variable) {
        case 'nowDate':
        case 'nowDate12h':
          const now = new Date();
          const is12h = variable === 'nowDate12h';
          const format = is12h ? 'MM/DD/YYYY hh:mm:ss A' : 'MM/DD/YYYY HH:mm:ss';
          data = this.prepareDate(now, format);
          break;
        case 'expiresDateShort':
        case 'expiresDateLong':
        case 'expirationDateShort':
        case 'expirationDateLong':
          const isExpiration = variable === 'expirationDateShort' || variable === 'expirationDateLong';
          const date = isExpiration ? params.expirationDate : params.expiresDate;

          if (date) {
            const isLong = variable === 'expiresDateLong' || variable === 'expirationDateLong';
            const parsedDate = moment(date, 'MMM DD, YYYY', 'en');
            const newFormat = isLong ? 'MMMM D, YYYY' : 'MM/DD/YY';

            data = parsedDate.format(newFormat);
          } else {
            data = '';
          }

          break;
        case 'companyName':
          data = `${this.authService.kioskData?.clientName}`;
          break;
        case 'kioskDeviceId':
          data = `${this.authService.preparedKioskDeviceId}`;
          break;
        default:
          data = params[variable];
          break;
      }

      page += `&${variable}=${encodeURIComponent(data)}`;
    });

    uniq(this.findVariables(pageTemplate, true)).forEach((variable: string) => {
      let data = '';
      const regex = /\('([^']+)'\)/;
      const format = variable.match(regex)?.[1];

      if (format?.length) {
        switch (true) {
          case variable.startsWith('nowDate'):
            const now = new Date();
            
            data = this.prepareDate(now, format);
            break;
          case variable.startsWith('expirationDate'):
          case variable.startsWith('expiresDate'):
            const isExpiration = variable.startsWith('expiration');
            const date = isExpiration ? params.expirationDate : params.expiresDate;
  
            if (date) {
              const parsedDate = moment(date, 'MMM DD, YYYY', 'en');
  
              data = parsedDate.format(format);
            } else {
              data = '';
            }
  
            break;
        }
  
        page += `&${variable}=${data}`;
      }
    });

    return page;
  }

  public async printTimeOut(time: number): Promise<void> {
    return new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), time));
  }

  public async printPage(printUrl: string): Promise<void> {
    this.printUrl = printUrl;
    console.log(printUrl)
    this.logService.writeLog(printUrl);

    const timeoutPromise = new Promise<void>((resolve) => {
      setTimeout(() => {
        this.logService.writeLog('Error: OnButton_Connect timed out after 10 seconds');
        resolve();
      }, 10000);
    });

    const connectPromise = this.OnButton_Connect();

    await Promise.race([connectPromise, timeoutPromise]);
  }

  private findVariables(pageTemplate: string, isDynamic?: boolean): string[] {
    let variables: string[] = [];
    const variablesArray = isDynamic ? TEMPLATE_VARIABLES_DYNAMICS : TEMPLATE_VARIABLES;

    variablesArray.forEach((variable: string) => {
      const patternString = `\\{\\{${variable}${isDynamic ? '.*?' : ''}\\}\\}`;
      const regex = new RegExp(patternString, "g");
      const matches = pageTemplate?.match(regex);

      if (matches) {
        if (isDynamic) {
          variables = [...variables, ...matches.map(x => x.replace('{{', '').replace('}}', ''))];
        } else {
          variables.push(variable);
        }
      }
    });

    return variables;
  }

  private async OnButton_Connect() {
    const self = this;
    self.logService.writeLog('Log: Print service - start connecting to WeblinkClient');
    this.WebLink = await new WebLinkClient('localhost', 5000);
    this.WebLink.AddEventListener(this.OnWebLinkEvent.bind(this));
    this.isConnected = false;
    if (self.isDebugModeEnabled) {
      return new Promise((resolve) => {
        resolve('')
      });
    }
    return new Promise<any>((resolve, reject) => {
      new TaskSequence()
        .Start(function () {
          console.log('Getting Kiosk Id');
          self.logService.writeLog('Log: Print service - call weblink method for getting kiosk id');
          self.snackBar.openFromComponent(SnackbarComponent, {
            panelClass: 'info',
            duration: 5000,
            horizontalPosition: 'end',
            data: {
              type: 'info',
              message: self.snackbar?.text?.info?.printMessage?.length ? self.snackbar.text.info.printMessage : 'Your request is currently in progress. Please wait...',
            },
          });
          return self.WebLink.GetKioskId();
        })
        .Step(function (id: any, error_text: any) {
          if (id == null) {
            console.log('Unable to retrieve Id: ' + error_text);
            self.logService.writeLog('Log: Print service - weblink service return kioskId is null; Error:' + error_text);
            self.isConnected = false;
            reject(new Error());
            return;
          }
          self.kioskId = id;

          // add hardcode license value
          // var data = self.Machines[id];
          self.logService.writeLog('Log: Print service - getting printing machine name');
          var data = self.Machines['NRTKIOSK'];

          if (data === undefined) {
            self.logService.writeLog('Log: Print service - printing machine name is undefined');
            self.isConnected = false;
            reject();
            return;
          }

          console.log('Found license:' + JSON.stringify(data));
          self.logService.writeLog('Log: Found license -' + JSON.stringify(data));
          self.logService.writeLog(`Log: Print service - starting connection to machine by license key(${data.LicenseKey}) and serial number(${data.SerialNumber})` + JSON.stringify(data));
          return self.WebLink.Connect(data.SerialNumber, data.LicenseKey);
        })
        .Step(function (success: any) {
          if (!success) {
            console.log('Failed to connect');
            self.logService.writeLog(`Log: Print service - connection to printing machine failed`);
            self.isConnected = false;
          }
          self.logService.writeLog(`Log: Print service - connection to printing machine established succesfully `);
          resolve('')
        })
        .RunOnce();
    });

  }

  private OnWebLinkEvent(event_class: any, event: any) {
    if (event_class == EventClass.System) {
      if (event) {
        console.log('Connected to CHS');
        this.logService.writeLog(`Log: Print service - connection to CHS established succesfully`);
        this.isConnected = true;

        this.OnButton_PrintReceipt(this.printUrl);
      } else {
        console.log('CHS Disconnected');
        this.logService.writeLog(`Log: Print service - connection to CHS failed`);
        this.isConnected = false;
      }
    } else {
      console.log('Retrieved Event:' + JSON.stringify(event));
      this.logService.writeLog(`Log: Print service - WebLink sreturn an event ${JSON.stringify(event)} `);
    }
  }

  private OnButton_PrintReceipt(url: string) {
    const self = this;
    this.WebLink.PrintReceipt(
      self.FormatText(url, encodeURIComponent(self.kioskId))
    ).Result(function (success: any) {
      if (!success) {
        console.log('PrintReceipt FAILED');
        self.logService.writeLog(`Log: Print service - WebLink service unable to print the receipt`);
      } else {
        self.LogFormat('Receipt Printed');
        self.logService.writeLog(`Log: Print service - WebLink service printed receipt succesfully`);
      }
      self.WebLink.Close();
      self.logService.writeLog(`Log: Print service - WebLink connection closed`);
    });
  }

  private FormatText(text: any, vargs: any) {
    text = '' + text;

    var args: any = [];

    if (vargs instanceof Array && arguments.length == 2) args = vargs;
    else for (var i = 1; i < arguments.length; i++) args.push(arguments[i]);

    var parser = /\{([0-9]+)\}/g;

    text = text.replace(parser, function (match: any, name: any) {
      return args[name];
    });

    return text;
  }

  private LogFormat(text: any, vargs?: any) {
    var args = [];

    if (vargs instanceof Array && arguments.length == 2) args = vargs;
    else for (var i = 1; i < arguments.length; i++) args.push(arguments[i]);

    text = this.FormatText(text, args);
    console.log(text);
    this.logService.writeLog('log - logFormat' + text);
  }

  private prepareDate(date: Date, format: string): string {
    return moment(date).format(format);
  }
}
