export { HomeComponent } from './home/home.component';
export { TemplateComponent } from './template/template.component';
export {
  ViewAddressesComponent,
  ViewEmailComponent,
  ViewNameComponent,
  ViewNickNameComponent,
  ViewPhoneComponent,
  ViewPinComponent,
} from './profile';
export { NotFoundComponent } from './not-found/not-found.component';
export {
  GiftTemplateComponent,
  RedemptionTemplateComponent,
  RedemptionV2TemplateComponent,
} from './template/components';
