<div class="content ksk-modal-wrapper">
    <div class="ksk-modal-close" (click)="closeModal()"></div>
    <div class="gift-card-success-wrapper">
        <mat-icon >check_circle</mat-icon>
        <div [innerHtml]="getInformationHtml(data.html)"></div>
        <div class="qr-code">
            <qrcode [qrdata]="data.orderImageUrl" [width]="styleService.isQt3Kiosk ? 128 : 256" [margin]="0"
                [colorLight]="'#00000000'"></qrcode>
        </div>
        <button class="button-confirm button" (click)="closeModal()" [ngStyle]="
            styleService.getStyleRule(
            [data.styles.buttonConfirm.text],
            [
            data.styles.buttonConfirm.background,
            { color: data.mainTemplate.globalVariables.buttonsColor.primaryColor }
            ],
            [data.styles.buttonConfirm.border])">
            <mat-icon >check</mat-icon>
            {{'Done' | conditionalUppercase
            : styleService.getUppercaseValue(
            data.styles.buttonConfirm.text
            )}}
        </button>
    </div>
</div>