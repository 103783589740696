<ng-container>
  <div
    class="ksk-modal-wrapper"
    [ngStyle]="
      styleService.getStyleRule(
        undefined,
        [data.tab.configurations?.cardOfferDetails?.background],
        undefined
      )
    "
  >
    <div class="ksk-modal-close" (click)="closeModal()"></div>
    <div
      class="ksk-modal-close-button"
      [ngStyle]="
        styleService.getCloseButtonStyles(
          data.tab.configurations.cardOfferDetails?.closeButton
        )
      "
      (click)="closeModal()"
    ></div>
    <div
      class="offer"
      [ngClass]="{
        grayscaled:
          isOfferRedeemed && redeemedOfferDisplaySettings?.isGrayscaled
      }"
    >
      <div class="offer-header">
        <div class="offer-img-wrapper">
          <img
            [src]="data.offer?.extendedDetails?.imageUrl"
            class="offer-img"
            [ngStyle]="
              styleService.getStyleRule(undefined, undefined, [
                data.tab.configurations.cardOfferDetails.imageBorder
              ])
            "
          />
          <img
            *ngIf="
              isOfferRedeemed &&
              redeemedOfferDisplaySettings?.isWatermarkAdded &&
              redeemedOfferDisplaySettings?.isWatermarkOverOfferAndDetails
            "
            [src]="data.tab.configurations.main.redeemedOfferImage.imageLink"
            class="offer-img redeemed-image"
          />
        </div>
        <div
          *ngIf="!isOfferRedeemed"
          class="offer-info"
          [ngStyle]="
            styleService.getStyleRule(
              undefined,
              [
                data.tab.configurations?.cardOfferDetails?.buttonSection
                  ?.background,
                data.mainTemplate.globalVariables.backgroundSecondary
              ],
              [
                data.tab.configurations?.cardOfferDetails?.buttonSection
                  ?.border,
                data.mainTemplate.globalVariables.borders
              ]
            )
          "
        >
          <ng-container *ngIf="!isLoading; else loaderTemplate">
            <p
              class="offer-qr-text"
              [ngStyle]="
                styleService.getStyleRule([
                  data.tab.configurations.cardOfferDetails.printDescription,
                  undefined,
                  'heading4'
                ])
              "
            >
              <span *ngIf="data.offer.extendedDetails.printDescription">
                {{
                  data.offer.extendedDetails.printDescription
                    | conditionalUppercase
                      : styleService.getUppercaseValue(
                          data.tab.configurations.cardOfferDetails
                            .printDescription
                        )
                }}</span
              >
            </p>
            <button
              *ngIf="
                data.offer?.extendedDetails.isPrintable && !isPrintButtonHidden
              "
              class="offer-btn"
              [ngStyle]="
                styleService.getStyleRule(
                  [getPrintRedeemStyle?.text],
                  [getPrintRedeemStyle?.background],
                  [getPrintRedeemStyle?.border]
                )
              "
              (click)="printPage()"
            >
              {{
                getPrintButtonText
                  | conditionalUppercase
                    : styleService.getUppercaseValue(getPrintRedeemStyle?.text)
              }}
            </button>
            <button
              *ngIf="
                data.offer?.extendedDetails.isRedeemable &&
                !data.offer?.extendedDetails.isPrintable &&
                !isOfferRedeemed
              "
              class="offer-btn"
              (click)="redeemReward()"
              [ngStyle]="
                styleService.getStyleRule(
                  [getRedeemStyle?.text],
                  [getRedeemStyle?.background],
                  [getRedeemStyle?.border]
                )
              "
            >
              {{
                getRedeemButtonText
                  | conditionalUppercase
                    : styleService.getUppercaseValue(getRedeemStyle?.text)
              }}
            </button>
          </ng-container>
        </div>
      </div>
      <h1
        class="offer-name"
        [ngStyle]="
          styleService.getStyleRule([
            data.tab.configurations.cardOfferDetails.title,
            undefined,
            'heading2'
          ])
        "
      >
        {{
          data.offer.extendedDetails.title
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  data.tab.configurations.cardOfferDetails.title
                )
        }}
      </h1>
      <div
        class="offer-description"
        [ngStyle]="
          styleService.getStyleRule([
            data.tab.configurations.cardOfferDetails.text,
            undefined,
            'mainText'
          ])
        "
        [ngClass]="{
          uppercase: styleService.getUppercaseValue(
            data.tab.configurations.cardOfferDetails.text
          )
        }"
        [innerHtml]="data.offer.extendedDetails.description"
      ></div>

      <div
        class="offer-description"
        [ngStyle]="
          styleService.getStyleRule([
            data.tab.configurations.cardOfferDetails.text,
            undefined,
            'mainText'
          ])
        "
        [ngClass]="{
          uppercase: styleService.getUppercaseValue(
            data.tab.configurations.cardOfferDetails.text
          )
        }"
      >
        {{ data.tab?.configurations?.cardOfferDetails?.validTillPrefix }}
        {{ formatDate(data.offer.endDate) }}
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loaderTemplate>
  <div class="loader" *ngIf="isLoading">
    <mat-spinner
      *ngIf="!data.mainTemplate.globalVariables.loader?.imageLink?.length"
      [color]="data.mainTemplate.globalVariables.backgroundPrimary.color"
      [diameter]="styleService.isQt3Kiosk ? 100 : 200"
    ></mat-spinner>
    <img
      class="loader-image"
      *ngIf="data.mainTemplate.globalVariables.loader?.imageLink?.length"
      [src]="data.mainTemplate.globalVariables.loader.imageLink"
    />
  </div>
</ng-template>
