<div class="content info-modal-content">
    <div class="message-wrapper">
      <div class="message">
        <div class="message-content">
            <h4 class="message-title">{{data.title}}</h4>
            <p class="message-text">{{data.text}}</p>
        </div>
      </div>
    </div>
    <div class="control-block">
      <button class="button-cancel button" (click)="closeDialog()" [ngStyle]="
      styleService.getStyleRule(
      [data.styles.buttonCancel
      .text],
      [
        data.styles.buttonCancel
        .background,
        { color: data.mainTemplate.globalVariables.buttonsColor.secondaryColor }
      ],
      [data.styles.buttonCancel
      .border])">
            <mat-icon>close</mat-icon>
        {{'Close' | conditionalUppercase
        : styleService.getUppercaseValue(
          data.styles.buttonCancel.text
        )}}
      </button>
    </div>
  </div>