import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  OfferService,
  PrintService,
  ProfileService,
  StyleService,
  TemplateService,
  LogService,
} from '../../services';
import { Campaign, KnownReward } from '../../models';
import { MainTemplate, TemplateSetting } from '../../models/template';
import {
  Subject,
  Subscription,
  fromEvent,
  take,
  takeUntil,
  firstValueFrom,
} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { RedemptionButtonState } from '../../models/redemption-template.model';
import { InteractionType } from '../../models/interaction';

@Component({
  selector: 'app-offer-modal',
  templateUrl: './offer-modal.component.html',
  styleUrls: ['./offer-modal.component.scss'],
})
export class OfferModalComponent implements OnInit, OnDestroy {
  public isLoaded = false;
  public mysteryConfirmed = false;
  public mainTemplate!: MainTemplate;
  public isInteraction = InteractionType.None;
  public offer: Campaign;
  public reward: KnownReward;
  public qrCode = '';
  public tab: TemplateSetting;
  public redemptionButtonState = RedemptionButtonState;
  public isOfferPrinted: boolean = false;
  public isLoading: boolean = false;

  public isSwapEnabled: boolean = false;
  public selectSwapId: string | undefined;
  public swapPage: number = 1;
  public isSwapConfirmed: boolean = false;
  public isNeedToUpdate: boolean = false;

  private accessRequestId: string;
  private lobbyToken: string;
  private indexesOffer: [number, number];
  private isGameResultTranseffered = false;
  private readonly closeListener$ = new Subject();
  private cardOfferDetails: any;
  private mysteryOffer: any;

  constructor(
    public readonly styleService: StyleService,
    private readonly offerService: OfferService,
    private readonly dialogRef: MatDialogRef<any>,
    private readonly printService: PrintService,
    private readonly profileService: ProfileService,
    private readonly templateService: TemplateService,
    private readonly logService: LogService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      indexesOffer: [number, number];
      tab: TemplateSetting;
      offer: Campaign;
      accessRequestId: string;
      lobbyToken: string;
      reward: KnownReward;
      isNeedToReedemOrUpdateBalance: boolean;
    }
  ) {
    this.indexesOffer = data.indexesOffer;
    this.tab = data.tab;
    this.offer = data.offer;
    this.accessRequestId = data.accessRequestId;
    this.lobbyToken = data.lobbyToken;
    this.reward = data.reward;
    this.qrCode = this.reward?.rewardCode || '';

    if (data.isNeedToReedemOrUpdateBalance) {
      this.isNeedToUpdate = true;
    }

    if (
      this.offerService.gameInteractionType(this.offer) !==
        InteractionType.GameInteraction &&
      data.isNeedToReedemOrUpdateBalance
    ) {
      if (this.reward?.customProperties?.printOnIssue) {
        this.checkPrintPage();
      } else if (this.reward?.customProperties?.redeemOnIssue) {
        firstValueFrom(this.offerService.onRedeem(this.reward));
      }

      if (
        !this.reward?.customProperties?.executeUcWebhookOn ||
        this.reward?.customProperties?.executeUcWebhookOn ===
          RedemptionButtonState.Issue
      ) {
        this.offerService.updatePlayerBalanceByRewardValue(this.reward);
      }
    }
  }

  ngOnInit(): void {
    this.cardOfferDetails = this.tab.configurations.cardOfferDetails;
    this.mysteryOffer = this.tab.configurations.mysteryOffer;
    this.isLoaded = false;
    this.templateService
      .templateData$()
      .pipe(take(1))
      .subscribe((template) => {
        this.isInteraction = this.offerService.gameInteractionType(this.offer);
        if (this.isInteraction === InteractionType.GameInteraction) {
          this.subscribeToOfferEvents();
        }
        this.isLoaded = true;
        this.mainTemplate = template;
      });
    this.offerService.setDefaultCustomProperties(this.reward);
  }

  public get offerQr(): string {
    return this.reward.rewardPosCode?.length
      ? this.reward.rewardPosCode
      : this.qrCode;
  }

  public confirmMystery() {
    this.offerService.claimMysteryReward(this.reward.rewardCode).subscribe(
      () => {
        this.mysteryConfirmed = true;
      },
      () => {
        this.snackbar.openFromComponent(SnackbarComponent, {
          panelClass: 'error',
          duration: 3000,
          horizontalPosition: 'end',
          data: {
            type: 'error',
            message: 'Reward confirm failed. Please try again',
          },
        });
      }
    );
  }

  public confirmMysteryOk() {
    this.reward.isMystery = false;
  }

  public printPage() {
    let isPrintProcessing = localStorage.getItem('isPrintProcessing');

    if (isPrintProcessing) return;

    localStorage.setItem('isPrintProcessing', 'true');

    const [pageIndex, templateIndex] = this.indexesOffer;

    const page: string = this.printService.preparePage(
      this.tab.configurations.voucher.html,
      {
        pageIndex,
        templateIndex,
        voucher: this.reward.rewardPosCode?.length
          ? this.reward.rewardPosCode
          : this.qrCode,
        patronName: this.profileService.fallBackDataPointValues().playerName,
        memberNumber: this.offer.knownReward.externalId,
        promotionName: this.offer.title,
        promotionDescription: this.offer.description,
        expiresDate: this.offer.knownReward.expirationDate,
        termsAndConditions: this.offer.termsAndConditions,
        rewardName: this.reward.name,
      },
      'html',
      this.offer.knownReward.campaignRewardItemId
    );

    this.logService.writeLog('Start printing offer');
    Promise.race([
      this.printService.printPage(page),
      // this.printService.printTimeOut(10000)
    ])
      .then(() => {
        const customProps = this.reward?.customProperties;

        this.isOfferPrinted = true;
        this.snackbar.open(`${this.offerService.successPrintMessage}`, '', {
          panelClass: 'success',
          duration: 5000,
          horizontalPosition: 'end',
        });

        localStorage.removeItem('isPrintProcessing');

        if (
          !(
            this.isInteraction !== InteractionType.GameInteraction &&
            this.reward?.customProperties?.redeemOnIssue
          ) &&
          (customProps?.redeemOnIssue ||
            customProps?.redeemOnPrint ||
            customProps?.showRedeemButton)
        ) {
          return firstValueFrom(
            this.offerService.onRedeem(
              this.reward,
              !!this.tab.templateConfig.isNeedToCloseAfterPrint
                ? this.dialogRef
                : null
            )
          );
        }

        if (this.tab.templateConfig.isNeedToCloseAfterPrint) {
          this.closeModal();
        }
      })
      .catch((e) => {
        localStorage.removeItem('isPrintProcessing');
        this.snackbar.openFromComponent(SnackbarComponent, {
          panelClass: 'error',
          duration: 5000,
          horizontalPosition: 'end',
          data: {
            type: 'error',
            message: 'Print failed. Please try again.',
          },
        });
      });
  }

  public rewardRedemptionButtonState(): string {
    const customProps = this.reward?.customProperties;
    if (customProps) {
      if (customProps?.showRedeemButton) {
        return RedemptionButtonState.Redeem;
      } else if (
        customProps?.showPrintButton &&
        customProps?.showRedeemButton
      ) {
        return RedemptionButtonState.Redeem;
      } else if (customProps?.showPrintButton) {
        return RedemptionButtonState.Print;
      }
    }
    return RedemptionButtonState.None;
  }

  public get printRedeemButtonStyles() {
    if (this.rewardRedemptionButtonState() === RedemptionButtonState.Redeem) {
      return this.isRewardRedeemed()
        ? this.cardOfferDetails?.buttonPrintRedeemOfferInactive
        : this.cardOfferDetails?.redeemOfferButton;
    }
    return this.isOfferPrinted
      ? this.cardOfferDetails?.buttonPrintRedeemOfferInactive
      : this.cardOfferDetails?.redeemOfferButton;
  }

  get printRedeemButtonTitle() {
    if (this.rewardRedemptionButtonState() === RedemptionButtonState.Redeem) {
      return this.isRewardRedeemed()
        ? this.cardOfferDetails?.buttonRedeemedOfferText?.trim() || 'Redeemed'
        : this.cardOfferDetails?.buttonRedeemOfferText?.trim() || 'Redeem';
    }
    return this.printButtonText;
  }

  get mysteryButtonTitle() {
    return this.mysteryOffer?.buttonClaimMysteryPrizeText
      ? this.mysteryOffer?.buttonClaimMysteryPrizeText?.trim()
      : 'Claim Mystery Prize';
  }

  get buttonClaimMysteryPrizeStyles() {
    return this.mysteryOffer?.buttonClaimMysteryPrize;
  }

  get mysteryOkButtonTitle() {
    return this.mysteryOffer?.buttonOkClaimMysteryPrizeText
      ? this.mysteryOffer?.buttonOkClaimMysteryPrizeText?.trim()
      : 'OK';
  }

  get buttonOkClaimMysteryPrizeStyles() {
    return this.mysteryOffer?.buttonOkClaimMysteryPrize;
  }

  public get printButtonText() {
    return this.isOfferPrinted
      ? this.cardOfferDetails?.buttonPrintedOfferText?.trim() || 'Printed'
      : this.cardOfferDetails?.buttonPrintOfferText?.trim() || 'Print';
  }

  public getIframeUrl(): string {
    return this.offerService.getGameInteractionUrl(
      this.lobbyToken,
      this.accessRequestId,
      this.offer
    );
  }

  public closeModal(): void {
    this.dialogRef.close(this.isNeedToUpdate);
  }

  public isRewardRedeemed(): boolean {
    if (!this.reward?.rewardStates) {
      return false;
    }
    return this.reward?.rewardStates?.includes('Redeemed');
  }

  public executeRewardAction() {
    if (!this.reward.rewardCode && !this.reward.rewardPosCode) return;

    const customProps = this.reward?.customProperties;

    if (customProps?.printOnIssue || customProps?.showPrintButton) {
      this.checkPrintPage();
    } else {
      firstValueFrom(this.offerService.onRedeem(this.reward, this.dialogRef));
    }
  }

  private printFailed(): void {
    this.snackbar.openFromComponent(SnackbarComponent, {
      panelClass: 'error',
      duration: 5000,
      horizontalPosition: 'end',
      data: {
        type: 'error',
        message: 'Print failed, please try again',
      },
    });
  }

  public checkPrintPage(): void {
    try {
      this.printPage();
    } catch (error) {
      this.printFailed();
    }
  }

  private subscribeToOfferEvents() {
    const self = this;

    fromEvent(window, 'message')
      .pipe(takeUntil(this.closeListener$))
      .subscribe(async (event: any) => {
        let eventData: KnownReward;

        if (this.isGameResultTranseffered) return;

        try {
          eventData = JSON.parse(event.data);
        } catch (error) {
          return;
        }

        if (eventData.customProperties?.['printOnIssue']) {
          self.updateOffer(false, true);
        }

        const rewardCode =
          eventData?.rewardCode ||
          (eventData as any)?.combinedCode?.auxCodeValue ||
          (eventData as any)?.combinedCode?.codeValue;

        if (!this.isGameResultTranseffered && eventData && rewardCode) {
          this.offerService.setDefaultCustomProperties(event);
          this.isLoading = true;

          this.isGameResultTranseffered = true;

          this.offerService.onRedeem(eventData).subscribe({
            next: (respReward) => {
              if (respReward) {
                self.updateOffer(true, false);
              }
            },
          });
        }
      });

    if (
      this.isInteraction === InteractionType.None &&
      this.reward?.customProperties?.['printOnIssue']
    ) {
      this.checkPrintPage();
    }
  }

  public get swapSlides(): KnownReward[] {
    const swaps = [...this.reward?.swaps?.rewards];
    const pageItems = 4 * this.swapPage;

    return swaps.splice(pageItems - 4, pageItems);
  }

  public changeSwapPage(isNext: boolean): void {
    let page = this.swapPage;

    const totalRewards = this.reward?.swaps?.rewards?.length;
    const totalPages = Math.ceil(totalRewards / 4);

    if (isNext) {
      if (this.swapPage < totalPages) {
        page = this.swapPage + 1;
      } else {
        page = 1;
      }
    } else {
      if (this.swapPage > 1) {
        page = this.swapPage - 1;
      } else {
        page = totalPages;
      }
    }

    this.swapPage = page;
  }

  public selectSwap(): void {
    this.isLoading = true;

    const rewardCode =
      this.reward?.rewardCode ||
      this.reward?.combinedCode?.auxCodeValue ||
      this.reward?.combinedCode?.codeValue;

    this.offerService
      .swapReward(this.selectSwapId as string, rewardCode)
      .pipe(take(1))
      .subscribe(() => {
        this.isSwapEnabled = true;
        this.selectSwapId = undefined;
        this.swapPage = 1;
        this.isSwapConfirmed = true;
        this.isLoading = false;
        this.isNeedToUpdate = true;

        setTimeout(() => this.closeModal(), 2000);
      });
  }

  private updateOffer(
    needToCheckInteraction: boolean,
    tryToPrint: boolean
  ): void {
    this.offerService
      .getCampaigns(this.tab)
      .pipe(take(1))
      .subscribe({
        next: (couponBody) => {
          const offer = couponBody.campaigns.find(
            (x) => x.id === this.offer.id
          );

          if (offer) {
            this.offer = offer;
            this.reward = offer.knownReward;
            this.qrCode = this.reward?.rewardCode || '';

            if (tryToPrint) {
              this.checkPrintPage();
            }

            if (needToCheckInteraction) {
              this.isInteraction = this.offerService.gameInteractionType(
                this.offer
              );
              this.isLoading = false;
            }
          }
        },
        error: () => {
          if (tryToPrint) {
            this.printFailed();
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.closeListener$.next(null);
    this.closeListener$.complete();
  }
}
