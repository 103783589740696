import { Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StyleService } from '../../services';
import cloneDeep from 'lodash/cloneDeep';
import { ProfileService } from '../../services/profile.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-html-confirm-modal',
    styleUrls: ['./html-confirm-modal.component.scss'],
    templateUrl: './html-confirm-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class HtmlConfirmModalComponent implements OnDestroy{
    constructor(
        private sanitizer: DomSanitizer,
        public styleService: StyleService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly profileService: ProfileService,
        private readonly dialogRef: MatDialogRef<any>,
    ) {}

    ngOnDestroy(): void {
        const styles = document.getElementById('htmlConfirmModalStyles');
        if (styles) {
            styles.remove();
        }
    }

    public async confirm(): Promise<void> {
        this.closeDialog();
        if (this.data.callback) {     
            await this.data.callback();   
        }
    }

    public closeDialog(): void {
        this.dialogRef.close();
    }

    public getHtml(html: string) {
      if (!html) {
        return;
      }
      const valuesToChange = [
        {
          templateVariable: '{{email}}',
          templateVariableValue: this.data.profile?.clonedProfile?.emails?.[0]?.address ??
          this.data.profile?.emails?.[0]?.address ??
          this.profileService.playerProfile.emails[0].address,
        },
      ];
      valuesToChange.forEach((value) => {
        html = html.replace(
          value.templateVariable,
          String(value.templateVariableValue)
        );
      });
      this.appendConfirmStyles();
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    private appendConfirmStyles() {
      if (document.getElementById('htmlConfirmModalStyles')) {
        return;
      }
      let css = cloneDeep(this.data.css);
      if (this.styleService.isQt3Kiosk) {
        if (this.data.css) {
          css = this.styleService.dividePixelStylesByTwo(css)
        }
      }
      const styles = css;
  
      const body = document.getElementsByTagName('body')[0];
      const style = document.createElement('style');
      style.id = 'htmlConfirmModalStyles';
      style.type = 'text/css';
      style.appendChild(document.createTextNode(styles));
      body.appendChild(style);
    }
}