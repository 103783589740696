import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, catchError, lastValueFrom, map, of, take, tap, iif } from 'rxjs';
import { Token } from '../models';
import { LogService } from './logs.service';
import { ProfileService } from './profile.service';
import { LOCAL_STORAGE_NAME } from './pop-up.service';
import cloneDeep from 'lodash/cloneDeep';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isLogoutStarting = new BehaviorSubject<boolean>(false);
  private debugHost: string | null = null;
  public isDebugEnabled = false;

  constructor(
    private readonly http: HttpClient,
    private readonly logService: LogService,
    private readonly profileService: ProfileService
  ) { }

  public ucAccessToken: any = {};

  private isSignOutClicked = false;

  public get kioskData(): Token | null {
    const kioskData = localStorage.getItem('kioskData');

    if (kioskData) {
      return JSON.parse(kioskData);
    }

    return null;
  }

  public setExitKioskUrl(url: string): void {
    if (url) {
      localStorage.setItem('exitKioskUrl', url);
    }
  }

  public get exitKioskUrl(): string | null {
    const exitKioskUrl = localStorage.getItem('exitKioskUrl');

    return exitKioskUrl;
  }

  public removeExitKioskUrl(): void {
    localStorage.removeItem('exitKioskUrl');
  }

  public setKioskData(token: Token): void {
    localStorage.setItem('kioskData', JSON.stringify(token));
  }

  public get externalId(): string | null {
    const externalId = localStorage.getItem('externalId');

    return externalId;
  }

  public setDeviceId(deviceId: string): void {
    localStorage.setItem('deviceId', deviceId);
  }

  public get deviceId(): string | null {
    const deviceId = localStorage.getItem('deviceId');

    return deviceId;
  }

  public get preparedKioskDeviceId(): string {
    return this.deviceId ?? '';
  }

  public get deviceHash(): string | null {
    const deviceHash = localStorage.getItem('deviceHash');

    return deviceHash;
  }

  public get kioskId(): string | null {
    const kioskId = localStorage.getItem('kioskId');

    return kioskId;
  }

  public setExternalId(externalId: string): void {
    localStorage.setItem('externalId', externalId);
  }

  public setDeviceHash(deviceHash: string): void {
    localStorage.setItem('deviceHash', deviceHash);
  }

  public setKioskId(kioskId: string): void {
    localStorage.setItem('kioskId', kioskId);
  }

  public getUniversalConnectorToken(playerId: string, options: any) {
    const headers = { 'Content-Type': 'application/json; charset=utf-8' };
    let authUrl = '';
    const body = {
      'grant_type': 'client_credentials',
      'client_id': options.clientId,
      'client_secret': options.clientSecret,
      'scope': 'api://5bd17dd9-2583-4fb7-b9f1-0c85f327855f/.default'
    };
    const paramRegexp = /\{[^}]*\}/g;
    const matchPatronId = (param: string) => { return param === '{playerId}' ? playerId : '' };
    authUrl = options.authUrl.replace(paramRegexp, matchPatronId);
    return lastValueFrom(this.http.post(authUrl, body, { headers: headers }).pipe(tap(v => {
      this.ucAccessToken = v as any;
    })));
  }

  public updateTokenByTimeout() {
    if (this.kioskData) {
      const expirationTime = this.kioskData.expires;
      const dateForUpdate = new Date(expirationTime).setSeconds(new Date(expirationTime).getSeconds() - 30);
      const updateTime = dateForUpdate - <any>new Date();
      setTimeout(async () => {
        if (!!this.kioskId && !!this.deviceHash && !!this.externalId)
          await lastValueFrom(this.getToken(this.kioskId, this.deviceHash, this.externalId));
      }, updateTime);
    }
  }

  public getToken(kioskId: string, deviceHash: string, patronId: string, deviceId?: string | null): Observable<Token> {
    const url = `${environment.apiUrl}/token`;
    const request: any = {
      externalId: patronId,
      deviceHash,
      kioskToken: kioskId,
    };

    if (deviceId) {
      request.kioskDeviceId = deviceId;
    }

    return this.http
      .post<Token>(url, request)
      .pipe(
        catchError((err: any) => {
          this.logService.writeLog(JSON.stringify(err));
          throw (err);
        }),
        map((data) => {
          this.setKioskData(data);
          this.updateTokenByTimeout();
          return data;
        }),
      );
  }

  public setUCHost(host: string): void {
    this.debugHost = host;
  }
  
  public logout(): void {
    if (this.isSignOutClicked) return;
    
    this.isSignOutClicked = true;
    this.isLogoutStarting.next(true);
    
    this.logService.writeLog(`start logout: ${new Date()}`);

    iif(
      () => this.profileService.isUcSecondVersion && this.profileService.universalConnectorSettings?.host && this.profileService.playerProfile.playerId,
      this.http.get(`${this.profileService.universalConnectorSettings.host}/api/Player/logout/${this.profileService.cmsName.toLocaleLowerCase()}/${this.profileService.playerProfile.playerId}`),
      of(1)
    ).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.logService.writeLog(`success api logout: ${new Date()}`);
        this.removeData();
      },
      error: () => {
        this.logService.writeLog(`error api logout: ${new Date()}`);
        this.removeData();
      }
    });
  }

  private removeData(): void {
    const advancedExitKioskUrl = this.exitKioskUrl;
    const exitUrl = cloneDeep(advancedExitKioskUrl ? advancedExitKioskUrl : 'http://exitkiosk');
    
    const keepSaveData = localStorage.getItem(LOCAL_STORAGE_NAME); 

    this.logService.writeLog(`success redirect to ${exitUrl} ${new Date()}`);
    
    localStorage.clear();

    if (keepSaveData) {
      localStorage.setItem(LOCAL_STORAGE_NAME, keepSaveData);
    }

    this.isLogoutStarting.complete();
    
    window.location.href = exitUrl;
  }
}
