import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  Injector,
  Input,
} from '@angular/core';
import { BaseComponent } from '../../../../components';
import { MainTemplate } from '../../../../models/template';
import { ProfileService, StyleService } from '../../../../services';
import {
  ConversionType,
  RedemptionTemplateConfigModel,
  RedemptionsConfigModel,
  TierLevels,
} from '../../../../models/redemption-template.model';
import { InfoModalComponent } from './../../../../components/info-modal/info-modal.component';
import { ConfirmRedeemModalComponent } from '../../../../components/confirm-redeem/confirm-redeem-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { TabType } from '../../../../models/template';
import { DataPointService } from '../../../../services/data-point.service';

@Component({
  selector: 'app-redemption-template',
  templateUrl: './redemption-template.component.html',
  styleUrls: ['./redemption-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RedemptionTemplateComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() tab?: any;
  @Input() mainTemplate!: MainTemplate;
  @Input() pageLinkIndex!: number;
  @Input() selectTabIndex!: number;

  public isLoading = false;
  public redemptionAmountValue: number = 0;
  public tabType = TabType;
  public redemptionKeyboardButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  public conversionType = [
    { title: 'Free play', value: 'freePlay' },
    { title: 'Cash', value: 'cash' },
    { title: 'Dining', value: 'dining' },
    { title: 'Bevarage', value: 'bevarage' },
    { title: 'Race & Sports', value: 'raceAndSports' },
    { title: 'Hotel', value: 'hotel' },
    { title: 'Match Play', value: 'matchPlay' },
  ];

  public styleService: StyleService;
  public profileService: ProfileService;
  public dataPointService: DataPointService;
  private sanitizer: DomSanitizer;
  private dialog: MatDialog;

  constructor(protected override readonly injector: Injector) {
    super(injector);

    this.styleService = this.injector.get(StyleService);
    this.profileService = this.injector.get(ProfileService);
    this.dataPointService = this.injector.get(DataPointService);
    this.sanitizer = this.injector.get(DomSanitizer);
    this.dialog = this.injector.get(MatDialog);
  }

  ngOnInit(): void {}

  public get isEmptyConversionRate() {
    const { rateFrom } = this.getConversioRate();
    return !rateFrom;
  }

  public get playerProfile() {
    return this.profileService.playerProfile;
  }

  public getSourceBucketUcValue(value: string) {
    /**TODO: Would not be working */
    if (
      value.includes('a_Points.amount') ||
      value.includes('PlayerAccountBalance') ||
      value === 'playerBalance'
    ) {
      return 'pointBalance';
    }
    return value;
  }

  public clearRedemptionAmount() {
    this.redemptionAmountValue = 0;
  }

  public addRedemptionValue(value: number) {
    if (this.isEmptyConversionRate) {
      return;
    }
    const pointsNedded = this.calculateNeededPointsToRedeem(
      Number(this.redemptionAmountValue.toString() + value)
    ).pointsNeeded;
    if (isNaN(pointsNedded)) {
      return;
    }
    const isValueMoreThanPoints =
      pointsNedded > this.profileService.playerAccountPointBalance;
    if (isValueMoreThanPoints) {
      this.addMaxAmount();
      return;
    }
    this.redemptionAmountValue = Number(
      this.redemptionAmountValue.toString() + value
    );
  }

  public keyboardBackspace() {
    const redemptionAmountValueArr = this.redemptionAmountValue
      .toString()
      .split('');
    this.redemptionAmountValue = Number(
      redemptionAmountValueArr.slice(0, -1).join('')
    );
  }

  public openRedeemConfirmation(): void {
    if (!this.redemptionAmountValue) return;

    const config = this.mainTemplate.template.pageLinks[this.pageLinkIndex]
      .templateSettings[this.selectTabIndex].templateConfig as any;

    if (config?.minValue && this.redemptionAmountValue < config.minValue) {
      return this.openInfoBlock(
        config.ivalidMinAmountTitle,
        config.ivalidMinAmountText,
        '.content.info-modal-content .message-text {color: #F14823;}'
      );
    }

    if (config?.maxValue && this.redemptionAmountValue > config.maxValue) {
      return this.openInfoBlock(
        config.ivalidMaxAmountTitle,
        config.ivalidMaxAmountText,
        '.content.info-modal-content .message-text {color: #F14823;}'
      );
    }

    const conversionType = this.getConversionType();
    this.dialog.open(ConfirmRedeemModalComponent, {
      panelClass: 'profile-dialog',
      backdropClass: 'backDropClass',
      height: '18vh',
      width: '100vw',
      data: {
        callback: async () => {
          await this.adjustingRedemptionValues();
        },
        redeemValue: this.redemptionAmountValue,
        pointToReedem: this.calculatePointsToRedeem(),
        pointsRate: this.getConversioRate(),
        mainTemplate: this.mainTemplate,
        pageIndex: this.pageLinkIndex,
        templateIndex: this.selectTabIndex,
        conversionType: conversionType?.title,
        currentTemplate:
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex],
        styles: (
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex]
            .configurations as unknown as RedemptionsConfigModel
        ).popUp,
      },
    });
  }

  public substractValue(value: string) {
    if (this.redemptionAmountValue - Number(value) < 0) {
      return;
    }
    this.redemptionAmountValue -= Number(value);
  }

  public addMaxAmount() {
    if (this.isEmptyConversionRate) {
      return;
    }

    const { rateFrom, rateTo } = this.getConversioRate();
    const pointsRatio = rateFrom / rateTo;
    if (isNaN(pointsRatio)) {
      return;
    }
    const maxAmount = Math.floor(
      this.profileService.playerAccountPointBalance / pointsRatio
    );
    this.redemptionAmountValue = maxAmount;
  }

  public getButtonStyle(v: any, tab: any, isRemoveButton: boolean) {
    const conversionRate = this.getConversioRate();
    const isConversionRateExists =
      !!conversionRate.rateFrom && !!conversionRate.rateTo;
    let buttonStyleState: string = '';
    if (isRemoveButton) {
      buttonStyleState =
        Number(this.redemptionAmountValue) > 0 &&
        Number(this.redemptionAmountValue) - Number(v) >= 0 &&
        isConversionRateExists
          ? 'removeButton'
          : 'disabledremoveButton';
    } else {
      buttonStyleState =
        !this.isRedemptionValueMoreThanBalance(Number(v)) &&
        isConversionRateExists
          ? 'addButton'
          : 'disabledAddButton';
    }
    return this.styleService.getStyleRule(
      [tab.configurations.preSets[buttonStyleState].text],
      [
        tab.configurations.preSets[buttonStyleState].background,
        {
          color:
            this.mainTemplate.globalVariables.buttonsColor[
              !isRemoveButton ? 'primaryColor' : 'secondaryColor'
            ],
        },
      ],
      [tab.configurations.preSets[buttonStyleState].border]
    );
  }

  public additionRedemptionAmount(value: string) {
    if (this.isEmptyConversionRate) {
      return;
    }
    if (this.isRedemptionValueMoreThanBalance(Number(value))) return;
    this.redemptionAmountValue =
      Number(this.redemptionAmountValue) + Number(value);
  }

  private isRedemptionValueMoreThanBalance(value: number) {
    const accountBalance = this.profileService.playerAccountPointBalance;
    return (
      this.calculateNeededPointsToRedeem(
        Number(this.redemptionAmountValue) + value
      ).pointsNeeded > accountBalance
    );
  }

  public currencyInputChanged(value: string) {
    var num = value.replace(/[$,]/g, '');
    this.redemptionAmountValue = Number(num);
  }

  public getTierRate() {
    if (this.isEmptyConversionRate) {
      return { rateFrom: 'N/A', rateTo: 'N/A' };
    }
    const { rateFrom, rateTo } = this.getConversioRate();
    return { rateFrom, rateTo };
  }

  private getConversioRate() {
    const page =
      this.mainTemplate.template.pageLinks[this.pageLinkIndex].templateSettings[
        this.selectTabIndex
      ];
    if (page.templateName !== this.tabType.Redemptions) {
      return { rateFrom: 0, rateTo: 0 };
    }
    const tierLevelRate = this.getCurrentTierLevel();
    if (!tierLevelRate && !this.mainTemplate.tierLevels.isCmsValueInUse) {
      return { rateFrom: 0, rateTo: 0 };
    }
    const templateConfig: RedemptionTemplateConfigModel =
      page.templateConfig as any;
    const playerTierLevel = this.profileService.isUcSecondVersion
      ? this.playerProfile.club.currentClub
      : this.playerProfile.gamingInfo.tierLevel;
    if (
      templateConfig?.conversionRate === 'tiered' &&
      (tierLevelRate?.status || (tierLevelRate?.from && tierLevelRate?.to))
    ) {
      const rate = (templateConfig.tiredConversionRate as TierLevels[]).find(
        (tier) => tier.guid === tierLevelRate.guid
      );
      if (rate) {
        return {
          rateFrom: Number(rate.rateFrom || 0),
          rateTo: Number(rate.rateTo || 0),
        };
      }
      return { rateFrom: 0, rateTo: 0 };
    }
    if (templateConfig?.conversionRate === 'tiered') {
      const rateConfig = (
        templateConfig.tiredConversionRate as TierLevels[]
      ).find(
        (tier) =>
          tier.tierLevel === playerTierLevel.id ||
          tier.tierLevel === playerTierLevel.name
      );
      if (rateConfig) {
        return {
          rateFrom: Number(rateConfig.rateFrom),
          rateTo: Number(rateConfig.rateTo),
        };
      }
      return { rateFrom: 0, rateTo: 0 };
    }
    return {
      rateFrom: Number(templateConfig.rateFrom),
      rateTo: Number(templateConfig.rateTo),
    };
  }

  private getCurrentTierLevel() {
    if (this.mainTemplate.tierLevels.isCmsValueInUse) {
      return;
    }
    const tiers = this.mainTemplate.tierLevels.tiers;
    const lastTier = tiers.at(-1);
    /**TODO find a way to provide correct value */
    const playerPointsBalance = Number(
      this.profileService
        .fallBackDataPointValues()
        .pointBalance?.replaceAll(',', '') ?? 0
    );
    if (
      (lastTier && (lastTier.to === 0 || !lastTier)) ||
      playerPointsBalance > (lastTier?.to ?? 0)
    ) {
      return lastTier;
    }
    const currentTierIndex = tiers.findIndex((tier, index) => {
      if (tier.to === 0 || (isNaN(tier.to) && index === tiers.length - 1)) {
        tier.to = 9999999999;
      }
      return tier.from <= playerPointsBalance && playerPointsBalance <= tier.to;
    });
    if (currentTierIndex !== -1) {
      return tiers[currentTierIndex];
    }
  }

  public getConversionType() {
    const type = (
      this.mainTemplate.template.pageLinks[this.pageLinkIndex].templateSettings[
        this.selectTabIndex
      ] as any
    )?.templateConfig?.conversionType;
    if (!type) {
      return;
    }
    return this.conversionType.find((v) => v.value === type);
  }

  public getRedemptionInformationHtml(html: string) {
    if (!html) {
      return;
    }
    const valuesToChange = [
      {
        templateVariable: '{{conversionRateFrom}}',
        templateVariableValue: this.getTierRate().rateFrom,
      },
      {
        templateVariable: '{{conversionRateTo}}',
        templateVariableValue: this.getTierRate().rateTo,
      },
      {
        templateVariable: '{{conversionType}}',
        templateVariableValue: this.getConversionType()?.title,
      },
    ];
    valuesToChange.forEach((value) => {
      html = html.replace(
        value.templateVariable,
        String(value.templateVariableValue)
      );
    });
    this.appendRedemptionStyles();
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  private appendRedemptionStyles() {
    if (document.getElementById('redemptionStyles')) {
      return;
    }
    if (this.styleService.isQt3Kiosk) {
      if (
        (
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex] as any
        )?.templateConfig?.description?.css
      ) {
        (
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex] as any
        ).templateConfig.description.css =
          this.styleService.dividePixelStylesByTwo(
            (
              this.mainTemplate.template.pageLinks[this.pageLinkIndex]
                .templateSettings[this.selectTabIndex] as any
            )?.templateConfig?.description?.css
          );
      }
    }
    const styles = (
      this.mainTemplate.template.pageLinks[this.pageLinkIndex].templateSettings[
        this.selectTabIndex
      ] as any
    )?.templateConfig?.description?.css;

    const body = document.getElementsByTagName('body')[0];
    const style = document.createElement('style');
    style.id = 'redemptionStyles';
    style.type = 'text/css';
    style.appendChild(document.createTextNode(styles));
    body.appendChild(style);
  }

  private async adjustingRedemptionValues() {
    return new Promise(async (resolve, reject) => {
      const onBadRequest = (msg: string) => {
        this.redemptionAmountValue = 0;
        this.isLoading = false;
        reject(msg);
      };
      this.isLoading = true;
      const adjRes = await this.profileService.doBalanceAdjustment(
        this.playerProfile?.playerId,
        this.calculatePointsToRedeem(),
        'Points',
        'D'
      );
      if (
        this.getConversionType()?.value === ConversionType.freePlay &&
        !adjRes.status
      ) {
        const res = await this.profileService.doBalanceAdjustment(
          this.playerProfile?.playerId,
          this.redemptionAmountValue,
          'XtraCredit',
          'C'
        );
        if (res.status || res.error) {
          await this.profileService.doBalanceAdjustment(
            this.playerProfile?.playerId,
            this.calculatePointsToRedeem(),
            'Points',
            'C'
          );
          onBadRequest(res.status);
          return;
        }
      }
      if (adjRes.error || adjRes.status) {
        onBadRequest(adjRes.status);
        return;
      }
      if (
        this.profileService.isUcSecondVersion &&
        this.profileService.playerBalances?.a_Points.amount
      ) {
        this.profileService.playerBalances.a_Points.amount =
          this.profileService.playerBalances?.a_Points.amount -
          this.calculatePointsToRedeem();
      } else {
        this.playerProfile.gamingInfo.PlayerAccountBalance =
          this.playerProfile?.gamingInfo?.PlayerAccountBalance -
          this.calculatePointsToRedeem();
      }

      this.redemptionAmountValue = 0;
      await this.profileService.getPlayerBalance(this.playerProfile.playerId);
      this.isLoading = false;
      resolve(true);
    });
  }

  public calculatePointsToRedeem(): number {
    const { pointsNeeded, accountBalance } = this.calculateNeededPointsToRedeem(
      this.redemptionAmountValue
    );
    if (pointsNeeded > accountBalance) {
      return 0;
    }
    return pointsNeeded;
  }

  private calculateNeededPointsToRedeem(dollarsValue: number) {
    if (this.isEmptyConversionRate) {
      return {
        pointsNeeded: 0,
        accountBalance: this.profileService.playerAccountPointBalance,
      };
    }

    const { rateFrom, rateTo } = this.getConversioRate();
    const accountBalance = this.profileService.playerAccountPointBalance;
    const pointsRatio = rateFrom / rateTo;
    const pointsNeeded = dollarsValue * pointsRatio;
    return {
      pointsNeeded: Math.ceil(pointsNeeded),
      accountBalance,
    };
  }

  private openInfoBlock(
    title: string,
    text: string,
    css: string,
    callback?: Function
  ): void {
    this.dialog.open(InfoModalComponent, {
      panelClass: 'profile-dialog',
      backdropClass: 'backDropClass',
      width: '100vw',
      data: {
        callback: async () => {
          if (callback) {
            await callback();
          }
        },
        title,
        text,
        css,
        styles: (
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex]
            .configurations as unknown as RedemptionsConfigModel
        ).popUp,
        mainTemplate: this.mainTemplate,
      },
    });
  }
}
