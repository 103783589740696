import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Campaign,
  CouponBody,
  DrawingEventStatus,
  KnownReward,
  SmallReward,
} from '../models';
import { environment } from '../../environments/environment';
import { Observable, take, map, of, catchError } from 'rxjs';
import { TemplateService } from './template.service';
import {
  CampaignSortingEnum,
  ConditionType,
  FilterFieldType,
  FilterGroup,
  FilterRule,
  FilterType,
  RuleOperator,
  TemplateSetting,
} from '../models/template';
import { AuthService } from './auth.service';
import { ProfileService } from './profile.service';
import cloneDeep from 'lodash/cloneDeep';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogService } from './logs.service';
import { UcWebhookType } from '../models/uc-webhook-type.enum';
import { DataPointService } from './data-point.service';
import { SnackbarComponent } from '../components';
import { rewardCustomProps } from '../../constants';
import { RedemptionButtonState } from '../models/redemption-template.model';
import { InteractionType } from '../models/interaction';
import { ChooseReward } from '../models/chooseReward';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  public get infoMessages() {
    const { redeemMessage, printSuccessMessage, redeemFailMessage } =
      this.templateService.templateData.components?.snackbars?.text?.info;
    return { redeemMessage, printSuccessMessage, redeemFailMessage };
  }

  public get successRedeemMessage(): string {
    return (
      this.infoMessages?.redeemMessage?.trim() ||
      'Reward was redeemed successfully.'
    );
  }

  public get failRedeemMessage(): string {
    return (
      this.infoMessages?.redeemFailMessage?.trim() ||
      'Reward redemption has failed.'
    );
  }

  public get successPrintMessage(): string {
    return (
      this.infoMessages?.printSuccessMessage?.trim() || 'Successfully printed'
    );
  }
  constructor(
    private readonly http: HttpClient,
    private readonly templateService: TemplateService,
    private readonly authService: AuthService,
    private readonly profileService: ProfileService,
    private dataPointService: DataPointService,
    private snackBar: MatSnackBar,
    private logService: LogService
  ) {}

  public getCampaigns(page: TemplateSetting): Observable<CouponBody> {
    const url = `${environment.apiCobraUrl}/api/ext/couponbook`;
    const token = this.templateService.templateData;
    let params = new HttpParams();
    let firstName = '';
    let lastName = '';

    if (this.profileService.isUcSecondVersion) {
      firstName = this.profileService.playerProfile.playerProfile.firstName;
      lastName = this.profileService.playerProfile.playerProfile.lastName;
    } else {
      firstName = this.profileService.playerProfile.name.first;
      lastName = this.profileService.playerProfile.name.last;
    }

    params = params.append('externalId', this.authService.externalId as string);
    params = params.append(
      'api_key',
      decodeURIComponent(token.advancedSettings.oc2ApiKey)
    );
    params = params.append('lobbyToken', page.templateConfig.lobbyToken);
    params = params.append('issueDevice', this.authService.deviceId ?? '');
    params = params.append('firstName', firstName);
    params = params.append('lastName', lastName);
    params = params.append('displayDevice', 'Kiosk');

    if (
      page.templateConfig.sorting === CampaignSortingEnum.CurrentUpcoming ||
      page.templateConfig.sorting === CampaignSortingEnum.UpcomingOnly
    ) {
      params = params.append('includeUpcoming', 1);
    }

    return this.http
      .get<CouponBody>(url, {
        params,
      })
      .pipe(
        map((res) => {
          res.campaigns = res.campaigns.sort((a, b) => {
            return (
              a.customProperties?.['orderNumber'] -
              b.customProperties?.['orderNumber']
            );
          });

          return res;
        })
      );
  }

  public getCmsOffer(): Observable<any> {
    return this.profileService.universalConnector.getCmsOffersList() as Observable<any>;
  }

  public getVoucherUniqCode() {
    return this.http.post(`${environment.apiUrl}/api/Voucher`, {}) as any;
  }

  public setVoucherStatus(status: number, number: string) {
    return this.http.put(
      `${environment.apiUrl}/api/Voucher/${number}/status/${status}`,
      {}
    ) as any;
  }

  public redeemCmsOffer(cmsOffer: any) {
    return this.profileService.universalConnector.redeemOffers(
      cmsOffer
    ) as Observable<any>;
  }

  public getCmsOfferDetails(body: any): Observable<any[]> {
    return this.http.post(
      `${environment.apiUrl}/api/KioskBuilder/offer-decorators`,
      body
    ) as Observable<any[]>;
  }

  public getCamapign(
    campaignId: string,
    accessRequestId: string,
    schedulerId: string
  ): Observable<KnownReward> {
    const url = `${environment.apiCobraUrl}/api/ext/couponBook/execute`;
    const token = this.templateService.templateData;
    let params = new HttpParams();

    params = params.append('api_key', token.advancedSettings.oc2ApiKey);

    return this.http.post<KnownReward>(
      url,
      {
        id: campaignId,
        accessRequestId,
        schedulerId,
      },
      {
        params,
      }
    );
  }

  public claimMysteryReward(rewardCode: string): Observable<any> {
    const url =
      `${environment.apiCobraUrl}/api/ext/rewards/` +
      rewardCode +
      `/claim-mystery`;
    const token = this.templateService.templateData;
    let params = new HttpParams();
    params = params.append('api_key', token.advancedSettings.oc2ApiKey);

    return this.http.post(url, null, {
      params,
    }) as Observable<any>;
  }

  public getChooseRewards(instanceId: string): Observable<ChooseReward> {
    const url =
      `${environment.apiCobraUrl}/api/ext/instance/` +
      instanceId +
      `/chooseReward`;
    const token = this.templateService.templateData;
    let params = new HttpParams();
    params = params.append('api_key', token.advancedSettings.oc2ApiKey);
    return this.http.get<ChooseReward>(url, {
      params,
    });
  }

  public claimChooseRaward(
    instanceId: string,
    chooseRewardItemId: string
  ): Observable<any> {
    const url =
      `${environment.apiCobraUrl}/api/ext/instance/` +
      instanceId +
      `/chooseReward`;
    const token = this.templateService.templateData;
    let params = new HttpParams();
    params = params.append('api_key', token.advancedSettings.oc2ApiKey);

    return this.http.post(
      url,
      { rewardId: chooseRewardItemId },
      {
        params,
      }
    ) as Observable<any>;
  }

  public redeemReward(rewardCode: string): Observable<KnownReward> {
    let params = new HttpParams();
    const token = this.templateService.templateData;
    params = params.append('api_key', token.advancedSettings.oc2ApiKey);
    return this.http.get(
      `${environment.apiCobraUrl}/api/ext/rewards/redeem/${rewardCode}`,
      {
        params,
      }
    ) as Observable<KnownReward>;
  }

  public onRedeem(
    reward: KnownReward,
    dialogRef?: any
  ): Observable<KnownReward | null> {
    if (
      localStorage.getItem('isExecuteProcessing') ||
      reward?.rewardStates?.includes('Redeemed')
    ) {
      return of(null);
    }

    localStorage.setItem('isExecuteProcessing', 'true');

    const rewardCode =
      reward?.rewardCode ||
      (reward as any)?.combinedCode?.auxCodeValue ||
      (reward as any)?.combinedCode?.codeValue;
    if (!rewardCode || rewardCode === '') {
      localStorage.removeItem('isExecuteProcessing');
      this.logService.writeLog(
        `Error:Reward- redemption was failed. Message: Reward code is missing'}`
      );
      return of(null);
    }

    return this.redeemReward(rewardCode).pipe(
      take(1),
      map(() => {
        const customProps = reward?.customProperties;

        if (!reward.rewardStates) {
          reward['rewardStates'] = [];
        }
        if (customProps?.executeUcWebhookOn === RedemptionButtonState.Redeem) {
          this.updatePlayerBalanceByRewardValue(reward);
        }
        localStorage.removeItem('isExecuteProcessing');
        reward.rewardStates.push('Redeemed');
        this.snackBar.openFromComponent(SnackbarComponent, {
          panelClass: 'success',
          duration: 5000,
          horizontalPosition: 'end',
          data: {
            type: 'success',
            message: this.successRedeemMessage,
          },
        });

        if (dialogRef) {
          dialogRef.close(true);
        }

        return reward;
      }),
      catchError((error) => {
        localStorage.removeItem('isExecuteProcessing');
        this.logService.writeLog(
          `Error:Reward-${reward.rewardCode} redemption was failed. Message:${
            JSON.stringify(error) ?? 'empty error message'
          }`
        );
        this.snackBar.openFromComponent(SnackbarComponent, {
          panelClass: 'error',
          duration: 5000,
          horizontalPosition: 'end',
          data: {
            type: 'error',
            message: this.failRedeemMessage,
          },
        });

        return of(null);
      })
    );
  }

  public swapReward(
    rewardId: string,
    rewardCode: string
  ): Observable<SmallReward> {
    let params = new HttpParams();
    params = params.append(
      'api_key',
      this.templateService.templateData.advancedSettings.oc2ApiKey
    );

    const dateOfBirth = this.profileService.isUcSecondVersion
      ? this.profileService.playerProfile.playerProfile.dateOfBirthday
      : this.profileService.playerProfile.birthDate;

    const url = `${environment.apiCobraUrl}/api/ext/rewards/swap`;
    const body = {
      id: rewardId,
      rewardCode,
      birthdate: dateOfBirth,
    };

    return this.http.post<SmallReward>(url, body, {
      params,
    });
  }

  public gameInteractionType(offer: Campaign): InteractionType {
    if (offer.hasInteractions && !offer.knownReward && offer.isAccessible) {
      return InteractionType.GameInteraction;
    } else if (
      offer.hasInteractionOnRewardsLevel &&
      !offer.knownReward &&
      offer.isAccessible
    ) {
      return InteractionType.RewardInteraction;
    } else {
      return InteractionType.None;
    }
  }

  public getGameInteractionUrl(
    lobbyToken: string,
    accessRequestId: string,
    offer: Campaign
  ): string {
    const token = this.templateService.templateData;
    return (
      environment.apiOcCobraUrl +
      '/?lobbyToken=' +
      lobbyToken +
      '&isTouchpoint=true' +
      '&id=' +
      offer.id +
      '&accessRequestId=' +
      accessRequestId +
      '&schedulerId=' +
      offer.schedule?.items[0]?.schedulerId +
      '&apiKey=' +
      token.advancedSettings.oc2ApiKey +
      '&isTouchpoint=true'
    );
  }

  public async updatePlayerBalanceByRewardValue(reward: KnownReward) {
    const rewardCode =
      reward?.rewardCode ||
      (reward as any)?.combinedCode?.codeValue ||
      (reward as any)?.rewardId;
    if (!!localStorage.getItem(rewardCode)) {
      this.logService.writeLog(`
      Log:Balance for that reward was already adjusted.
      Reward code:${rewardCode}
      Reward cost:${reward?.cost},
      Reward name:${reward?.name},
      `);
      return;
    }
    if (reward.ucWebhookType !== UcWebhookType.Undefined && reward.cost > 0) {
      let adjustingType: string = '';
      let balanceToUpdate: any = null;
      if (this.profileService.isUcSecondVersion) {
        if (reward.ucWebhookType === UcWebhookType.PointsUCv1) {
          balanceToUpdate = this.profileService.playerBalances.a_Points;
          adjustingType = balanceToUpdate.type;
        } else if (reward.ucWebhookType === UcWebhookType.FreePlayUCv2) {
          balanceToUpdate = this.profileService.playerBalances.a_FreePlay;
          adjustingType = balanceToUpdate.type;
        } else if (reward.ucWebhookType === UcWebhookType.Comps) {
          balanceToUpdate = this.profileService.playerBalances.a_Comps;
          adjustingType = balanceToUpdate.type;
        } else if (reward.ucWebhookType === UcWebhookType.TierCredits) {
          balanceToUpdate = this.profileService.playerBalances.a_Tier;
          adjustingType = balanceToUpdate.type;
          balanceToUpdate.type = 'TierPoints';
        }
      } else if (
        !this.profileService.isUcSecondVersion &&
        UcWebhookType.FreePlayUCv2
      ) {
        this.logService.writeLog(
          `Error:First version universal connector is not supporting FREE PLAYS`
        );
        this.snackBar.openFromComponent(SnackbarComponent, {
          panelClass: 'error',
          duration: 5000,
          horizontalPosition: 'end',
          data: {
            type: 'error',
            message:
              'Wrong balance adjustment configuration. Please contact helpdesk',
          },
        });
      } else {
        adjustingType = 'Points';
      }
      if (!adjustingType) return;
      localStorage.setItem(rewardCode, 'true');
      this.logService.writeLog(`
      Log:Start updating player balance by ucWebhookType:${reward?.ucWebhookType}
      Adjusting type:${adjustingType},
      Reward code:${rewardCode},
      Player id:${this.profileService.playerProfile.playerId},
      Reward cost:${reward?.cost},
      Reward name:${reward?.name},
      `);
      const { status, error } = await this.profileService.doBalanceAdjustment(
        this.profileService.playerProfile.playerId,
        reward.cost,
        adjustingType,
        'C',
        balanceToUpdate
      );

      if (status || error) {
        this.logService.writeLog(
          `Error:Adjusting points to player ${this.profileService.playerProfile.playerId} was failed; Message: ${status} ${error}`
        );
        this.snackBar.openFromComponent(SnackbarComponent, {
          panelClass: 'error',
          duration: 5000,
          horizontalPosition: 'end',
          data: {
            type: 'error',
            message:
              'Points have not been added to your account. Please contact helpdesk',
          },
        });
        return;
      }
      this.logService.writeLog(`
      Log: Player balance has been successfully replenished by ucWebhookType:${reward?.ucWebhookType};
      Adjusting type:${adjustingType},
      Reward code:${rewardCode},
      Player id:${this.profileService.playerProfile.playerId},
      Reward cost:${reward?.cost},
      Reward name:${reward?.name},
      `);
    }
  }

  public isLiveEarnPeriod(offer: Campaign): boolean {
    return offer.status === DrawingEventStatus.LiveEarnPeriod;
  }

  public filterByGroup(group: FilterGroup, campaign: Campaign): boolean {
    if (group.condition === ConditionType.And) {
      return group.rules.every((rule) => this.filterRouting(rule, campaign));
    } else {
      return group.rules.some((rule) => this.filterRouting(rule, campaign));
    }
  }

  public setDefaultCustomProperties(reward: KnownReward) {
    if (!reward) {
      return;
    }
    if (!reward?.customProperties) {
      reward['customProperties'] = {};
    }
    rewardCustomProps.forEach((property) => {
      reward.customProperties[property.name] =
        reward.customProperties[property.name] ?? property.defaultValue;
    });
  }

  private filterRouting(
    rule: FilterGroup | FilterRule,
    campaign: Campaign
  ): boolean {
    if (rule.type === FilterType.Condition) {
      return this.filterByGroup(rule as FilterGroup, campaign);
    } else {
      return this.filterByRule(rule as FilterRule, campaign);
    }
  }

  private filterByRule(rule: FilterRule, campaign: Campaign): boolean {
    let isFiltred = false;
    let value: any;
    let ruleValue;
    let stringProperty = '';

    const setStringProperty = () => {
      stringProperty = cloneDeep(rule.value);
      stringProperty = stringProperty.replace('cp.', '');
    };

    switch (rule.field) {
      case FilterFieldType.OfferType:
        ruleValue = campaign.customProperties?.offerType;
        value = rule.value;
        break;
      case FilterFieldType.Points:
        setStringProperty();
        value = this.tryToParseNumber(
          this.profileService.fallBackDataPointValues().pointBalance
        );
        ruleValue = campaign.customProperties?.[stringProperty];
        break;
      case FilterFieldType.Tier:
        setStringProperty();
        value = this.profileService.fallBackDataPointValues().tierLevelName;
        ruleValue = campaign.customProperties?.[stringProperty];
        break;
      case FilterFieldType.PointsEarnedToday:
        setStringProperty();
        value = this.tryToParseNumber(
          this.dataPointService.getValueByDataPointProperty('pointsEarnedToday')
        );
        ruleValue = campaign.customProperties?.[stringProperty];
        break;
      case FilterFieldType.TierPointsEarnedToday:
        setStringProperty();
        value = this.tryToParseNumber(
          this.dataPointService.getValueByDataPointProperty('tierPointsEarnedToday')
        );
        ruleValue = campaign.customProperties?.[stringProperty];
        break;
      case FilterFieldType.Age:
        setStringProperty();
        value = this.dataPointService.getValueByDataPointProperty('age');
        ruleValue = campaign.customProperties?.[stringProperty];
        break;
      case FilterFieldType.Gender:
        setStringProperty();
        value = this.dataPointService.getValueByDataPointProperty('gender');
        ruleValue = campaign.customProperties?.[stringProperty];
        break;
      case FilterFieldType.MilitaryStatus:
        setStringProperty();
        const isBooleanRegexp = /^(true|false)$/;
        const status =
          this.dataPointService.getValueByDataPointProperty('militaryStatus');
        value =
          status === 'N/A'
            ? false
            : isBooleanRegexp.test(status.toLowerCase())
            ? JSON.parse(status.toLocaleLowerCase())
            : !!status;
        ruleValue = campaign.customProperties?.[stringProperty];
        break;
      case FilterFieldType.IsBirthday:
        value = this.profileService.getBirthDate();
        ruleValue = true;
        break;
      case FilterFieldType.IsBirthdayMonth:
        value = this.profileService.getIsBirthdayMonth();
        ruleValue = true;
        break;
      case FilterFieldType.IsXDaysAfterBirthday:
        setStringProperty();
        const daysAfter = campaign.customProperties?.[stringProperty];
        value = this.profileService.daysAfterBirthday(
          this.profileService.getBirthdayDate()
        );
        ruleValue = Number(daysAfter);
        break;
      case FilterFieldType.IsXDaysBeforeBirthday:
        setStringProperty();
        const daysBefore = campaign.customProperties?.[stringProperty];
        value = this.profileService.daysBeforeBirthday(
          this.profileService.getBirthdayDate()
        );
        ruleValue = Number(daysBefore);
        break;
      case FilterFieldType.DaysFromEnrollment:
        setStringProperty();
        value = this.profileService.getDaysFromEnrollment(
          this.dataPointService.getValueByDataPointProperty('enrollmentDate')
        );
        ruleValue = Number(campaign.customProperties?.[stringProperty]);
        break;
    }

    switch (rule.operator) {
      case RuleOperator.Equal:
        isFiltred = value == ruleValue;
        break;
      case RuleOperator.NotEqual:
        isFiltred = value !== ruleValue;
        break;
      case RuleOperator.In:
        isFiltred = ruleValue?.includes(value);
        break;
      case RuleOperator.NotIn:
        isFiltred = !ruleValue?.includes(value);
        break;
      case RuleOperator.More:
        isFiltred = value > ruleValue;
        break;
      case RuleOperator.MoreOrEqual:
        isFiltred = value >= ruleValue;
        break;
      case RuleOperator.Less:
        isFiltred = value < ruleValue;
        break;
      case RuleOperator.LessOrEqual:
        isFiltred = value <= ruleValue;
        break;
    }

    return isFiltred;
  }

  private tryToParseNumber(value: string | number): string | number {
    if (typeof value === 'string') {
      return parseFloat(value.replaceAll(',', ''));
    }

    return value;
  }
}
