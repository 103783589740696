<div
  class="content"
  [ngStyle]="
    styleService.getStyleRule(undefined, [
      tabData.configurations.main.background,
      mainTemplate.globalVariables.backgroundPrimary
    ])
  "
>
  <div class="header">
    <div class="header-block">
      <h1
        class="header-title"
        [ngStyle]="
          styleService.getStyleRule([
            currentPage.pageLinkContainerSettings.configurations.title,
            undefined,
            'heading3'
          ])
        "
      >
        <a class="header-title-back" (click)="closeModal()">
          <img
            src="/assets/images/last/back.svg"
            class="header-title-back-icon"
          />
        </a>
        {{
          "Phone"
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  currentPage.pageLinkContainerSettings.configurations.title
                )
        }}
      </h1>
    </div>
  </div>

  <div class="main">
    <ng-container
      *ngIf="!profileService.isUcSecondVersion; else phoneEditVersionTwo"
    >
      <div class="row" *ngIf="getPhoneNumberIndex('Mobile') > -1">
        <div class="col-6">
          <label
            class="label"
            [ngStyle]="
              styleService.getStyleRule([
                tabData.configurations.inputs.title,
                undefined,
                'heading4'
              ])
            "
            >{{
              "Mobile phone"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tabData.configurations.inputs.title
                    )
            }}</label
          >
          <input
            type="text"
            [(ngModel)]="phones[getPhoneNumberIndex('Mobile')].number"
            class="input"
            placeholder="Placeholder"
            kioskInput
          />
        </div>
        <ng-container
          *ngTemplateOutlet="
            optOut;
            context: { phone: phones[getPhoneNumberIndex('Mobile')] }
          "
        ></ng-container>
      </div>
      <div class="row" *ngIf="getPhoneNumberIndex('Home') > -1">
        <div class="col-6">
          <label
            class="label"
            [ngStyle]="
              styleService.getStyleRule([
                tabData.configurations.inputs.title,
                undefined,
                'heading4'
              ])
            "
            >{{
              "Additional number"
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tabData.configurations.inputs.title
                    )
            }}</label
          >
          <input
            type="text"
            [(ngModel)]="phones[getPhoneNumberIndex('Home')].number"
            class="input"
            placeholder="Placeholder"
            kioskInput
          />
        </div>
        <ng-container
          *ngTemplateOutlet="
            optOut;
            context: { phone: phones[getPhoneNumberIndex('Home')] }
          "
        ></ng-container>
      </div>
    </ng-container>

    <kiosk-keyboard [isAlwaysShown]="true"></kiosk-keyboard>
  </div>

  <div class="footer">
    <button
      (click)="checkValidate()"
      [ngStyle]="
        styleService.getStyleRule(
          [tabData.configurations.buttons.primaryButtons.text],
          [
            tabData.configurations.buttons.primaryButtons.background,
            { color: mainTemplate.globalVariables.buttonsColor.primaryColor }
          ],
          [tabData.configurations.buttons.primaryButtons.border]
        )
      "
    >
      {{
        "Confirm"
          | conditionalUppercase
            : styleService.getUppercaseValue(
                tabData.configurations.buttons.primaryButtons.text
              )
      }}
    </button>
    <button
      (click)="closeModal()"
      [ngStyle]="
        styleService.getStyleRule(
          [tabData.configurations.buttons.secondaryButtons.text],
          [
            tabData.configurations.buttons.secondaryButtons.background,
            { color: mainTemplate.globalVariables.buttonsColor.secondaryColor }
          ],
          [tabData.configurations.buttons.secondaryButtons.border]
        )
      "
    >
      {{
        "Cancel"
          | conditionalUppercase
            : styleService.getUppercaseValue(
                tabData.configurations.buttons.secondaryButtons.text
              )
      }}
    </button>
  </div>
</div>

<ng-template #phoneEditVersionTwo>
  <div class="row" *ngIf="getPhoneNumberIndex('Home') > -1">
    <div class="col-6">
      <label
        class="label"
        [ngStyle]="
          styleService.getStyleRule([
            tabData.configurations.inputs.title,
            undefined,
            'heading4'
          ])
        "
        >{{
          "Main phone"
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tabData.configurations.inputs.title
                )
        }}</label
      >
      <input
        type="text"
        [(ngModel)]="phones[getPhoneNumberIndex('Home')].phoneNumber"
        class="input"
        placeholder="Placeholder"
        kioskInput
      />
    </div>
    <ng-container
      *ngTemplateOutlet="
        optOut;
        context: { phone: phones[getPhoneNumberIndex('Home')] }
      "
    ></ng-container>
  </div>
  <div class="row" *ngIf="getPhoneNumberIndex('Alternate') > -1">
    <div class="col-6">
      <label
        class="label"
        [ngStyle]="
          styleService.getStyleRule([
            tabData.configurations.inputs.title,
            undefined,
            'heading4'
          ])
        "
        >{{
          "Additional number"
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tabData.configurations.inputs.title
                )
        }}</label
      >
      <input
        type="text"
        [(ngModel)]="phones[getPhoneNumberIndex('Alternate')].phoneNumber"
        class="input"
        placeholder="Placeholder"
        kioskInput
      />
    </div>
    <ng-container
      *ngTemplateOutlet="
        optOut;
        context: { phone: phones[getPhoneNumberIndex('Alternate')] }
      "
    ></ng-container>
  </div>
</ng-template>

<ng-template #optOut let-phone="phone">
  <div class="row opt-out" *ngIf="tabData.templateConfig?.phoneOptOut">
    <div class="col-6">
      <div class="opt-out-container">
        <div
          class="checkbox-container"
          (click)="setOptOutValue(phone)"
          [ngClass]="{
            'checked ': profileService.isUcSecondVersion
              ? phone?.sendText === 'false'
              : !phone?.permitTextMsg
          }"
          [ngStyle]="
            getCheckboxContainerStyles(
              profileService.isUcSecondVersion
                ? phone?.sendText === 'false'
                : !phone?.permitTextMsg
            )
          "
        >
          <div
            class="checkmark"
            [ngStyle]="{ 'border-color': optOutConfigurations?.checkmarkColor }"
          ></div>
        </div>
        <div class="opt-out-text">
          <span
            [ngStyle]="
              styleService.getStyleRule([
                optOutConfigurations.text,
                undefined,
                'heading4'
              ])
            "
          >
            {{
              (tabData.templateConfig.phoneNumber.optOutPhoneText
                ? tabData.templateConfig.phoneNumber.optOutPhoneText
                : "Opt-out of text marketing"
              )
                | conditionalUppercase
                  : styleService.getUppercaseValue(optOutConfigurations?.text)
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
