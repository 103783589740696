import {
  Background,
  Border,
  Button,
  ButtonSetting,
  ExtendFieldParams,
  FieldParamas,
  Font,
  FontSimple,
  Icon,
  IconButton,
  Image,
  Shadow,
  StatusColors,
} from './common';

export enum UcVersion {
  Version_1 = 'Version_1',
  Version_2 = 'Version_2',
}

interface DebugMode {
  debugModeEnabled: boolean;
  hostName: string;
  paramName: string;
  paramValue: string;
}

export interface MainTemplate {
  id: string;
  templateJson: string;
  tierLevelsJson: string;
  globalVariablesJson: string;
  componentsJson: string;
  carousel: Carousel;
  advancedSettings: {
    oc2ApiKey: string;
    activeCMS: string;
    localResource: LocalResource;
    debugMode: DebugMode | null;
    universalConnector: {
      clientId: string;
      clientSecret: string;
      host: string;
      ucVersion: string;
      authUrl: string;
    };
    exitKioskUrl: string;
  };

  tierLevels: TierLevels;
  globalVariables: GlobalVariables;
  template: Template;
  components: Components;
}

export interface LocalResource {
  isEnabled: boolean;
  fonts: any;
}

export interface Carousel {
  items: CarouselItem[];
  slideInterval: string;
}

export interface CarouselItem {
  fileId: string;
  fileUrl: string;
  order: number;
  contentType: string;
  isVideo?: boolean;
}

export interface TierLevels {
  instanceId: string;
  layout: string;
  barTitle: string;
  cmsValue: string;
  isCmsValueInUse: boolean;
  isThresholdsEnabled: boolean;
  isShowSideBar: boolean;
  tierLevelValue: string;
  creditThresholds: string;
  progressBarCalculationLogic: string;
  dataPoints: tierDataPoint[];
  tiers: Tier[];
  configurations: {
    title: Font;
    border: Border;
    background: Background;
    backgroundTiersColor: string;
    backgroundTierProgressColor: string;
    creditThresholds: Font;
    additionalSection: any;
  };
}

export interface tierDataPoint {
  dataPoint: string;
  dataPointTitle: string;
}

export interface Tier {
  additionalBackground: Background;
  background: Background;
  guid: string;
  from: number;
  to: number;
  color: string;
  status: string;
}

export interface GlobalVariables {
  instanceId: string;
  client: string;
  backgroundPrimary: Background;
  backgroundSecondary: Background;
  borders: Border;
  textSettings: {
    fontFamily: string;
    heading1: FontSimple;
    heading2: FontSimple;
    heading3: FontSimple;
    heading4: FontSimple;
    heading5: FontSimple;
    heading6: FontSimple;
    mainText: FontSimple;
    helpText: FontSimple;
  };
  buttonsColor: {
    primaryColor: string;
    secondaryColor: string;
  };
  loader: Image;
  statusColor: {
    info: string;
    warning: string;
    success: string;
    error: string;
  };
  arrowsColor: {
    arrows: string;
  };
  cssOverride: string;
}

export interface Template {
  id: string;
  dataPoints: DataPoint[];
  additionalInfo: InfoBlock[];
  pageLinks: PageLink[];
  welcomeMessage: {
    dataPoint: string;
    messagePrefix: string;
    configurations: Font;
  };
  signOutButton: {
    title: string;
    iconName: string;
    iconColor: string;
    iconSize: string;
    configurations: {
      text: Font;
      buttonSettings: ButtonSetting;
      background: Background;
      border: Border;
    };
  };
  tierLevelCard: {
    background: Background;
    dataPoints: DataPointSmall[];
    isUsedCurrentTierLvlImg: boolean;
  };
}

export interface DataPoint {
  dataPoint: string;
  title: string;
  secondaryDataPoint?: string;
  secondaryTitle?: string;
  isSecondaryDataPointEnabled?: boolean;
  iconName: string;
  iconColor: string;
  iconSize: string;
  configurations: {
    title: Font;
    value: Font;
    secondaryTitle?: Font;
    secondaryValue?: Font;
    border: Border;
    background: Background;
  };
  prefix?: string;
  suffix?: string;
  secondaryPrefix?: string;
  secondarySuffix?: string;
}

export interface InfoBlock {
  dataPoint: string;
  title: string;
  comment: string;
  configurations: {
    title: Font;
    dataPoint: Font;
    comment: Font;
    background: Background;
    border: Border;
  };
}

export interface PageLink {
  pageLinkContainerSettings: {
    title: string;
    iconName: string;
    iconColor: string;
    iconSize: string;
    configurations: {
      title: Font;
      border: Border;
      background: Background;
      subpages: any;
    };
  };
  templateSettings: TemplateSetting[];
}

export interface TemplateSetting {
  templateName: TabType;
  sectionTitle: string;
  isFacialValidationRequired: boolean;
  isPinRequired: boolean;
  configurations: {
    main: {
      title: Font;
      noOffersText: string;
      noOffers: Font;
      text: Font;
      helpText: Font;
      background: Background;
    };
    inputs: {
      mainConfig: {
        size: string;
        borderRadius: string;
        borderWidth: string;
      };
      backgroundColor: StatusColors;
      borderColor: StatusColors;
      title: Font;
      text: Font;
      placeholder: Font;
      errorText: Font;
    };
    buttons: {
      primaryButtons: Button;
      secondaryButtons: Button;
      buttonsMore: Button;
      buttonsAddNewAddress: IconButton;
      buttonsEdit: Button;
      buttonsRemove: Button;
      buttonsClearAll: Button;
      butonsConfirm: Button;
    };
    cardOffer: any;
    cardOfferDetails: any;
    mysteryOffer: any;
    chooseOffer: any;
    drawingDetails: any;
    freeEntryDetails: any;
    tierEntryDetails: any | undefined;
    multiplierDetails: any;
    cardDrawing: any;
    cardFreeEntry: any;
    cardTierEntry: any | undefined;
    cardMultiplier: any;
    swapOffer: any;
    voucher: any;
    welcomePopUp: WelcomePopUp;
  };
  templateConfig: {
    icon: Icon;
    name: FieldParamas;
    nickName: FieldParamas;
    primaryAddress: FieldParamas;
    preferredAddress: FieldParamas;
    email: ExtendFieldParams;
    phoneNumber: ExtendFieldParams;
    pin: FieldParamas;
    configuration: string;
    displayType: string;
    filterByCriteria: string;
    filterByTreshold: string;
    lobbyToken: string;
    sorting: string;
    fileName: string;
    fileUrl: string;
    url: string;
    phoneOptOut?: boolean;
    emailOptOut?: boolean;
    addressOptOut?: boolean;
    openDetailedView?: {
      isVisible: boolean;
    };
    currentYear?: {
      isVisible: boolean;
    };
    initialText?: {
      errorMessageAfterSubmit: string;
    };
    isNeedToCloseAfterPrint?: boolean;
  };
}

export interface WelcomePopUp {
  isEnabled: boolean;
  schedule: PopUpSchedule;
  weekdaySchedule: {
    isEnabled: boolean;
    selectedDays: ScheduleDays[];
  };
  background: Background;
  border: Border;
  message: string;
  messageStyle: Font;
  buttonText: string;
  button: Button;
}

export enum PopUpSchedule {
  Every = '0',
  PerSession = '1',
  PerDay = '2',
}

export enum ScheduleDays {
  Sunday = 'Su',
  Monday = 'Mo',
  Tuesday = 'Tu',
  Wednesday = 'We',
  Thursday = 'Th',
  Friday = 'Fr',
  Saturday = 'Sa',
}

export enum FilterType {
  Condition = 'condition',
  Rule = 'rule',
}

export enum ConditionType {
  And = 'and',
  Or = 'or',
}

export interface FilterGroup {
  id: string;
  type: FilterType.Condition;
  condition: ConditionType;
  rules: (FilterGroup | FilterRule)[];
}


export enum FilterFieldType {
  Points = 'Points',
  OfferType = 'Offer Type',
  Tier = 'Tier',
  Age = 'Age',
  PointsEarnedToday = 'Points Earned Today',
  TierPointsEarnedToday = 'Tier Points Earned Today',
  IsBirthday = 'Is Birthday',
  IsBirthdayMonth = 'Is Birthday Month',
  IsXDaysAfterBirthday = 'Is X Days After Birthday',
  IsXDaysBeforeBirthday = 'Is X Days Before Birthday',
  Gender = 'Gender',
  MilitaryStatus = 'Military status',
  DaysFromEnrollment = 'DaysFromEnrollment',
}

export interface FilterRule {
  id: string;
  type: FilterType.Rule;
  field: FilterFieldType;
  operator: RuleOperator;
  value: string;
}

export enum RuleOperator {
  Equal = '==',
  NotEqual = '!==',
  In = 'IN',
  NotIn = 'NOT IN',
  More = '>',
  MoreOrEqual = '>=',
  Less = '<',
  LessOrEqual = '<=',
}

export interface DataPointSmall {
  dataPoint: string;
  title: string;
  image: Image;
  configurations: {
    title: Font;
    value: Font;
  };
}

export interface Components {
  id: string;
  welcomeMessage: Font;
  signOutButton: {
    iconName: string;
    iconColor: string;
    iconSize: string;
    configurations: {
      text: Font;
      buttonSettings: ButtonSetting;
      background: Background;
      border: Border;
    };
  };
  backButton: any;
  snackbars: Snackbars;
  dataPoints: {
    configurations: {
      title: Font;
      value: Font;
      secondaryTitle?: Font;
      secondaryValue?: Font;
      border: Border;
      background: Background;
      icons: {
        color: string;
        size: string;
      };
    };
  };
  additionalInformation: {
    background: Background;
    title: Font;
    dataPoint: Font;
    comment: Font;
    border: Border;
  };
  pageLinks: {
    title: Font;
    icon: {
      iconColor: string;
      iconSize: string;
    };
    subpages: {
      selected: {
        background: Background;
        border: {
          borderRadius: string;
          borderWidth: string;
          color: string;
        };
        text: Font;
      };
      unselected: {
        background: Background;
        border: {
          borderRadius: string;
          borderWidth: string;
          color: string;
        };
        text: Font;
      };
    };
    border: Border;
    background: Background;
  };
  tierLevelCard: {
    configurations: {
      title: Font;
      value: Font;
      image: Image;
      background: Background;
    };
  };
  keyboard: KeyboardConfigurationsInterface;
  modalWindow: ModalWindowConfigurtationInterface;
}

export interface Snackbars {
  error: Snackbar;
  info: Snackbar;
  success: Snackbar;
  warning: Snackbar;
  actionIcon: Icon;
  shadow: Shadow;
  snackbarPosition: 'center-center' | 'right-top' | 'right-bottom';
  text: { [k: string]: { [k: string]: string } };
}

export interface Snackbar {
  background: Background;
  border: Border;
  icon: Icon;
  text: Font;
}

export interface ModalWindowConfigurtationInterface {
  background: Background;
  border: {
    color: string;
    radius: string;
    width: string;
  };
  overlayBackgroundColor: string;
  style: string;
}

export interface KeyboardConfigurationsInterface {
  buttonColors: {
    accent: string;
    accentActive: string;
    background: string;
    outline: string;
    text: string;
  };
  letters: Font;
}

export enum TabType {
  Profile = 'Profile',
  Webpage = 'Webpage',
  PDF = 'PDF',
  Offers = 'Offers',
  Redemptions = 'Redemptions',
  Drawing = 'Drawing',
  OffersCms = 'Offers(Cms)',
  OffersAndDrawings = 'Offers & Drawings',
  WinAndLoss = 'Win / Loss',
  DrawingCms = 'Drawing(Cms)',
  GiftCard = 'Gift Cards',
  OffersAndOffersCms = 'Offers & Offers(Cms)',
  OffersCmsOffersAndDrawings = 'Offers, Offers(Cms) & Drawings',
  RedemptionsV2 = 'Redemptions V2',
}

export enum CampaignSortingEnum {
  CurrentOnly = '0',
  CurrentUpcoming = '1',
  UpcomingOnly = '2',
}
