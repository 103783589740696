import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  OfferService,
  PrintService,
  ProfileService,
  StyleService,
  TemplateService,
  LogService
} from '../../services';
import { Campaign, KnownReward } from '../../models';
import { MainTemplate, TemplateSetting } from '../../models/template';
import { Subscription, fromEvent, take } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { RedemptionButtonState } from '../../models/redemption-template.model';

@Component({
  selector: 'app-offer-modal',
  templateUrl: './offer-modal.component.html',
  styleUrls: ['./offer-modal.component.scss'],
})
export class OfferModalComponent implements OnInit, OnDestroy {
  public isLoaded = false;
  public mainTemplate!: MainTemplate;
  public isInteraction = false;
  public offer: Campaign;
  public reward: KnownReward;
  public qrCode = '';
  public tab: TemplateSetting;
  public redemptionButtonState = RedemptionButtonState;
  public isOfferPrinted: boolean = false;

  private accessRequestId: string;
  private lobbyToken: string;
  private indexesOffer: [number, number];
  private isGameResultTranseffered = false;
  private gettingRewardSubscribtion: Subscription = new Subscription();
  private cardOfferDetails: any;

  constructor(
    public readonly styleService: StyleService,
    private readonly offerService: OfferService,
    private readonly dialogRef: MatDialogRef<any>,
    private readonly printService: PrintService,
    private readonly profileService: ProfileService,
    private readonly templateService: TemplateService,
    private readonly logService: LogService,
    private snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      indexesOffer: [number, number];
      tab: TemplateSetting;
      offer: Campaign;
      accessRequestId: string;
      lobbyToken: string;
      reward: KnownReward;
      isNeedToReedemOrUpdateBalance: boolean;
    }
  ) {
    this.indexesOffer = data.indexesOffer;
    this.tab = data.tab;
    this.offer = data.offer;
    this.accessRequestId = data.accessRequestId;
    this.lobbyToken = data.lobbyToken;
    this.reward = data.reward;
    this.qrCode = this.reward?.rewardCode || '';

    if (!this.offerService.isGameInteraction(this.offer) && data.isNeedToReedemOrUpdateBalance) {   
      if (this.reward?.customProperties?.printOnIssue) {
        this.checkPrintPage();
      } else if (this.reward?.customProperties?.redeemOnIssue) {
        this.offerService.onRedeem(this.reward);
      }

      if (!this.reward?.customProperties?.executeUcWebhookOn ||
          this.reward?.customProperties?.executeUcWebhookOn === RedemptionButtonState.Issue
      ) {
        this.offerService.updatePlayerBalanceByRewardValue(this.reward);
      }
    }
  }

  ngOnInit(): void {
    this.cardOfferDetails = this.tab.configurations.cardOfferDetails;
    this.isLoaded = false;
    this.templateService
      .templateData$()
      .pipe(take(1))
      .subscribe((template) => {
        this.isInteraction = this.offerService.isGameInteraction(this.offer);
        if (this.isInteraction) {
          this.subscribeToOfferEvents();
        }
        this.isLoaded = true;
        this.mainTemplate = template;
      });
    this.offerService.setDefaultCustomProperties(this.reward);
  }

  public get offerQr(): string {
    return this.reward.rewardPosCode?.length
      ? this.reward.rewardPosCode
      : this.qrCode;
  }

  public printPage() {
    const [pageIndex, templateIndex] = this.indexesOffer;

    const page: string = this.printService.preparePage(
      this.tab.configurations.voucher.html, {
        pageIndex,
        templateIndex,
        voucher: this.reward.rewardPosCode?.length
          ? this.reward.rewardPosCode
          : this.qrCode,
        patronName: this.profileService.fallBackDataPointValues().playerName,
        memberNumber: this.offer.knownReward.externalId,
        promotionName: this.offer.title,
        promotionDescription: this.offer.description,
        expiresDate: this.offer.knownReward.expirationDate,
        termsAndConditions: this.offer.termsAndConditions,
        rewardName: this.reward.name,
      }, 'html',
      this.offer.knownReward.customProperties?.['printHtml'] ? this.offer.id : undefined
    );

    this.logService.writeLog('Start printing offer');
    Promise.race([
      this.printService.printPage(page),
      // this.printService.printTimeOut(10000)
    ]).then(() => {
      const customProps = this.reward?.customProperties;

      this.isOfferPrinted = true;
      this.snackbar.open(`${this.offerService.successPrintMessage}`, '', {
        panelClass: 'success',
        duration: 5000,
        horizontalPosition: 'end',
      });
      localStorage.removeItem('isExecuteProcessing');

      if (customProps?.redeemOnIssue || customProps?.redeemOnPrint || customProps?.showRedeemButton) {
        return this.offerService.onRedeem(
          this.reward,
          !!this.tab.templateConfig.isNeedToCloseAfterPrint ? this.dialogRef : null
        );
      }

      if (this.tab.templateConfig.isNeedToCloseAfterPrint) {
        this.closeModal();
      }
    }).catch((e) => {
      this.snackbar.openFromComponent(SnackbarComponent, {
        panelClass: 'error',
        duration: 5000,
        horizontalPosition: 'end',
        data: {
          type: 'error',
          message: 'Print failed. Please try again.',
        },
      });
    });
  }

  public rewardRedemptionButtonState(): string {
    const customProps = this.reward?.customProperties;
    if (customProps) {
      if (customProps?.showRedeemButton) {
        return RedemptionButtonState.Redeem;
      } else if (
        customProps?.showPrintButton &&
        customProps?.showRedeemButton
      ) {
        return RedemptionButtonState.Redeem;
      } else if (customProps?.showPrintButton) {
        return RedemptionButtonState.Print;
      }
    }
    return RedemptionButtonState.None;
  }

  public get printRedeemButtonStyles() {
    if (this.rewardRedemptionButtonState() === RedemptionButtonState.Redeem) {
      return this.isRewardRedeemed()
        ? this.cardOfferDetails?.buttonPrintRedeemOfferInactive
        : this.cardOfferDetails?.buttonPrintOffer;
    }
    return this.isOfferPrinted
      ? this.cardOfferDetails?.buttonPrintRedeemOfferInactive
      : this.cardOfferDetails?.buttonPrintOffer;
  }

  get printRedeemButtonTitle() {
    if (this.rewardRedemptionButtonState() === RedemptionButtonState.Redeem) {
      return this.isRewardRedeemed()
        ? this.cardOfferDetails?.buttonRedeemedOfferText?.trim() || 'Redeemed'
        : this.cardOfferDetails?.buttonRedeemOfferText?.trim() || 'Redeem';
    }
    return this.printButtonText;
  }

  public get printButtonText() {
    return this.isOfferPrinted
      ? this.cardOfferDetails?.buttonPrintedOfferText?.trim() || 'Printed'
      : this.cardOfferDetails?.buttonPrintOfferText?.trim() || 'Print';
  }

  public getIframeUrl(): string {
    return this.offerService.getGameInteractionUrl(
      this.lobbyToken,
      this.accessRequestId,
      this.offer
    );
  }

  public closeModal(): void {
    this.dialogRef.close();
  }

  public isRewardRedeemed(): boolean {
    if (!this.reward?.rewardStates) {
      return false;
    }
    return this.reward?.rewardStates?.includes('Redeemed');
  }

  public executeRewardAction() {
    let isProcessing = localStorage.getItem('isExecuteProcessing');
    isProcessing = isProcessing ? JSON.parse(isProcessing) : false;

    if (isProcessing || (!this.reward.rewardCode && !this.reward.rewardPosCode)) return;
    localStorage.setItem('isExecuteProcessing', 'true');

    const customProps = this.reward?.customProperties;

    if (customProps?.printOnIssue || customProps?.showPrintButton) {
      this.checkPrintPage();
    } else {
      this.offerService.onRedeem(this.reward, this.dialogRef);
    }
  }

  private printFailed(): void {
    this.snackbar.openFromComponent(SnackbarComponent, {
      panelClass: 'error',
      duration: 5000,
      horizontalPosition: 'end',
      data: {
        type: 'error',
        message: 'Print failed, please try again',
      },
    });
  }

  public checkPrintPage(): void {
    try {
      this.printPage();
    } catch (error) {
      this.printFailed();
      localStorage.removeItem('isExecuteProcessing');
    }
  }

  private subscribeToOfferEvents() {
    const self = this;

    this.gettingRewardSubscribtion = fromEvent(window, 'message').subscribe(
      async (event: any) => {
        let eventData: KnownReward;

        if (this.isGameResultTranseffered) return;

        try {
          eventData = JSON.parse(event.data);
        } catch (error) {
          return;
        }

        if (event.data && (event.data.eventName === 'OC_GET_REWARD_ON_DEMAND' || event.data.eventName === 'onGetRewardOnDemand')) {
          self.closeModal();
        }

        if (eventData.customProperties?.['printOnIssue']) {
          this.offerService
            .getCampaigns(this.tab)
            .pipe(take(1))
            .subscribe({
              next: (couponBody) => {
                const offer = couponBody.campaigns.find(
                  (x) => x.id === self.offer.id
                );

                if (offer) {
                  self.offer = offer;
                  self.reward = offer.knownReward;
                  self.qrCode = self.reward?.rewardCode || '';
                  self.checkPrintPage();
                }
              },
              error: () => {
                self.printFailed();
              },
            });
        }

        if (!this.isGameResultTranseffered && eventData) {
          this.offerService.setDefaultCustomProperties(event);
          if (eventData?.customProperties?.redeemOnIssue) {
            if (
              !eventData.rewardCode &&
              !(eventData as any)?.combinedCode?.codeValue
            )
              return;
          }

          this.isGameResultTranseffered = true;
          this.gettingRewardSubscribtion.unsubscribe();

          this.offerService.onRedeem(eventData);
        }
      }
    );

    if (
      !this.isInteraction &&
      this.reward?.customProperties?.['printOnIssue']
    ) {
      this.checkPrintPage();
    }
  }

  ngOnDestroy(): void {
    this.gettingRewardSubscribtion.unsubscribe();
  }
}
