import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, share, shareReplay } from 'rxjs';
import { environment } from '../../environments/environment';
import { Border, MainTemplate, Tier } from '../models';
import { ProfileService } from './profile.service';
import { UcVersion } from '../models/template';
import { LogService } from './logs.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private currentTemplate = new BehaviorSubject(null);

  public universalConnector: any = null;
  public universalConnectorSettings: any = null;
  public playerBalancesWinLoss: any = {};

  constructor(
    private readonly http: HttpClient,
    private readonly profileService: ProfileService,
    private readonly logsService: LogService
  ) {}

  public templateData$(): Observable<MainTemplate> {
    return this.currentTemplate.asObservable().pipe(
      map((data) => data || ({} as MainTemplate)),
      shareReplay(1)
    );
  }

  public get templateData(): MainTemplate {
    return this.currentTemplate.getValue() as any;
  }

  public setTemplateData(template: MainTemplate): void {
    this.currentTemplate.next(template as any);
  }

  public getTemplate(): Observable<MainTemplate> {
    const url = `${environment.apiUrl}/api/KioskBuilder/template`;

    return this.http.get<MainTemplate>(url).pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
      map((template) => {
        template.tierLevels = JSON.parse(template.tierLevelsJson);
        template.globalVariables = JSON.parse(template.globalVariablesJson);
        template.template = JSON.parse(template.templateJson);
        template.components = JSON.parse(template.componentsJson);

        if (template.components.pageLinks?.border) {
          template.components.pageLinks.border = this.standardizeBorder(
            template.components.pageLinks.border
          );
        }
        if (template.components?.additionalInformation?.border) {
          template.components.additionalInformation.border =
            this.standardizeBorder(
              template.components?.additionalInformation?.border
            );
        }
        if (template.template?.signOutButton?.configurations?.border) {
          template.template.signOutButton.configurations.border =
            this.standardizeBorder(
              template.template.signOutButton.configurations.border
            );
        }
        if (template.components?.signOutButton?.configurations?.border) {
          template.components.signOutButton.configurations.border =
            this.standardizeBorder(
              template.components.signOutButton.configurations.border
            );
        }

        if (template?.globalVariables?.borders) {
          template.globalVariables.borders = this.standardizeBorder(
            template.globalVariables.borders
          );
        }

        return template;
      })
    );
  }

  public getActiveTiers(mainTemplate: MainTemplate): {
    tiers: [Tier, Tier];
    activeTier: Tier;
    activeTierIndex: number;
  } {
    const tiers = mainTemplate.tierLevels.tiers;
    const isCmsValueInUse = mainTemplate.tierLevels.isCmsValueInUse;
    const points = this.profileService.playerAccountPointBalance;
    let tierLevelName = '';
    let tierLevelId = '';
    if (this.profileService.ucVersion === UcVersion.Version_2) {
      tierLevelName = this.profileService.playerProfile.club.currentClub.name;
      tierLevelId = this.profileService.playerProfile.club.currentClub.suffix;
    } else {
      tierLevelName =
        this.profileService.playerProfile.gamingInfo.tierLevel.name;
      tierLevelId = this.profileService.playerProfile.gamingInfo.tierLevel.id;
    }

    let activeTierIndex = isCmsValueInUse
      ? tiers.findIndex((tier) => {
          let condition: boolean = false;

          switch (mainTemplate.tierLevels.tierLevelValue) {
            case 'Tier level':
              condition =
                tier.status === tierLevelName || tier.status === tierLevelId;
              break;
          }
          return condition;
        })
      : tiers.findIndex((tier) => {
          const tierIsInfinite = Number.isNaN(tier.to);
          return (
            (points >= tier.from && points <= tier.to) ||
            (points >= tier.from && tierIsInfinite)
          );
        });

    if (activeTierIndex === -1) {
      const lastTierIndex = tiers.length - 1;
      const lastTier = tiers[lastTierIndex];

      activeTierIndex = points > lastTier.from ? lastTierIndex : 0;
    }

    return activeTierIndex === 0
      ? {
          tiers: [tiers[0], tiers[1]],
          activeTier: tiers[0],
          activeTierIndex: 0,
        }
      : {
          tiers: [tiers[activeTierIndex - 1], tiers[activeTierIndex]],
          activeTier: tiers[activeTierIndex],
          activeTierIndex: activeTierIndex,
        };
  }

  public getCustomizations(): Observable<any> {
    const url = `${environment.apiUrl}/api/KioskBuilder/customizations`;

    return this.http.get<any>(url).pipe(
      map((customizations) => {
        console.log(customizations);

        return customizations;
      })
    );
  }

  private standardizeBorder(oldBorder: any): Border {
    return {
      borderColor: oldBorder.color ?? oldBorder.borderColor,
      borderRadius: oldBorder.radius ?? oldBorder.borderRadius,
      borderWidth: oldBorder.width ?? oldBorder.borderWidth,
    };
  }

  public getCarouselImages(accountHash: string, carouselType: string) {
    const url = `${environment.apiUrl}/api/KioskCarousel/${carouselType}/${accountHash}`;
    return this.http.get(url);
  }

  private anyToString(el: any): any {
    switch (true) {
      case el === null:
        return '';
      case Array.isArray(el):
        return el.map((child: any) => this.anyToString(child));
      case typeof el === 'object':
        const newEl: any = {};
        Object.entries(el).forEach(([property, child]) => {
          newEl[property] = this.anyToString(child);
        });
        return newEl;
      case typeof el === 'number':
        return el.toString();
      default:
        return el;
    }
  }

  public async getPlayerBalancesWinLoss(
    patronId: string,
    years: string,
    deliveryMethod: string
  ): Promise<any> {
    try {
      const winLossResult =
        await this.universalConnector.getPlayerBalancesWinLoss(
          patronId,
          years,
          deliveryMethod
        );

      console.log('winLossResult', winLossResult);

      if (winLossResult?.result?.errors?.length) {
        this.logsService.writeLog(
          JSON.stringify(`
          Get player balances win/loss from UC failed: /api/Balance/winloss/;
          Date:${new Date()};
          PatronID:${patronId}; 
          Errors:${JSON.stringify(winLossResult?.result?.errors[0]?.message)}`)
        );
        return;
      }

      this.playerBalancesWinLoss = winLossResult;
      console.log('this.playerBalancesWinLoss', this.playerBalancesWinLoss);

      return winLossResult;
    } catch (error) {
      this.logsService.writeLog(
        JSON.stringify(`
      Error fetching player balances win/loss from UC: /api/Balance/winloss/;
      Date:${new Date()};
      PatronID:${patronId}; 
      Errors:${
        !!error
          ? JSON.stringify(error)
          : 'Getting player Win/Loss Statement failed'
      }`)
      );
      return error;
    }
  }

  public async getPlayerBalancesWinLossYears(patronId: string): Promise<any> {
    try {
      // Call the getPlayerBalancesWinLossYears method from the universalConnector
      const yearsResult =
        await this.universalConnector.getPlayerBalancesWinLossYears(patronId);

      if (yearsResult?.result?.errors?.length) {
        this.logsService.writeLog(
          JSON.stringify(`
          Get player balances win/loss years from UC failed: /api/Balance/winlossyears;
          Date:${new Date()};
          PatronID:${patronId}; 
          Errors:${JSON.stringify(yearsResult?.result?.errors[0]?.message)}`)
        );
        return;
      }
      return yearsResult;
    } catch (error) {
      this.logsService.writeLog(
        JSON.stringify(`
      Error fetching player balances win/loss years from UC: /api/Balance/winloss/;
      Date:${new Date()};
      PatronID:${patronId}; 
      Errors:${
        !!error
          ? JSON.stringify(error)
          : 'Getting player Win/Loss Statement failed'
      }`)
      );
      //throw error;
    }
  }
}
