export const NRTKIOSK_CONFIG = {
  SerialNumber: '0695-0452088',
  LicenseKey: 'C49BE69F-208F922F-9233BC24-9C6D664B-3CF59AE5-C4627740',
};

export const MDEV_CONFIG = {
  SerialNumber: '0695-0452088',
  LicenseKey:
    'C49BE69F-208F922F-0512662B-FB64271D-853C1844-C60B3B27-E62ACBDB-1B116E6C',
};

export const FORMAT_VARIABLES_SEPARATOR = '%%%%%';

export const TEMPLATE_VARIABLES = [
  'rewardName',
  'voucher',
  'nowDate',
  'nowDate12h',
  'patronName',
  'memberNumber',
  'promotionName',
  'kioskDeviceId',
  'promotionDescription',
  'expiresDate',
  'expiresDateShort',
  'expiresDateLong',
  'termsAndConditions',
  'voucherImage',
  'title',
  'playerId',
  'playerName',
  'ticketNumber',
  'voucherId',
  'convertedPoints',
  'expirationDate',
  'expirationDateShort',
  'expirationDateLong',
  'companyName',
  'employeeName',
  'employeeSignature',
  'guestName',
  'guestSignature',
  'cmsOfferEndDate',
  'cmsOfferEndTime',
];

export const TEMPLATE_VARIABLES_DYNAMICS = [
  'nowDate',
  'expiresDate',
  'expirationDate',
];
