import { Component, Inject, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StyleService } from '../../services';
import cloneDeep from 'lodash/cloneDeep';
import { ProfileService } from '../../services/profile.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-info-modal',
    styleUrls: ['./info-modal.component.scss'],
    templateUrl: './info-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class InfoModalComponent implements OnInit, OnDestroy{
    constructor(
        private sanitizer: DomSanitizer,
        public styleService: StyleService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly profileService: ProfileService,
        private readonly dialogRef: MatDialogRef<any>,
    ) {}

    ngOnInit(): void {   
      this.appendInfoStyles();
    }

    ngOnDestroy(): void {
        const styles = document.getElementById('infoModalStyles');
        if (styles) {
            styles.remove();
        }
    }

    public async closeDialog(): Promise<void> {
        this.dialogRef.close();
        if (this.data.callback) {     
            await this.data.callback();   
        }
    }

    private appendInfoStyles() {
      if (document.getElementById('infoModalStyles')) {
        return;
      }
      let css = cloneDeep(this.data.css);
      if (this.styleService.isQt3Kiosk) {
        if (this.data.css) {
          css = this.styleService.dividePixelStylesByTwo(css)
        }
      }
      const styles = css;
  
      const body = document.getElementsByTagName('body')[0];
      const style = document.createElement('style');
      style.id = 'infoModalStyles';
      style.type = 'text/css';
      style.appendChild(document.createTextNode(styles));
      body.appendChild(style);
    }
}