<ng-container *ngIf="tab">
    <div class="redemption-container" *ngIf="!isLoading; else loaderTemplate">
        <div class="content-wrapper">
        <div class="redemption-information">
            <div class="information-header">
                <h3 class="gift-title">Your Point Balance: <b>{{ profileService.playerAccountPointBalance | number }}</b></h3>
                <div class="data-point-wrapper">
                    <div class="data-point-container" [ngStyle]="
                styleService.getStyleRule(
                    undefined,
                    [
                    tab.configurations.dataPoints.background,
                    'undefined',
                    'undefined'
                    ],
                    [
                    tab.configurations.dataPoints.border,
                    'undefined',
                    'undefined'
                    ]
                )
                ">
                    <qrcode [qrdata]="tab.templateConfig.qrUrl" [width]="styleService.isQt3Kiosk ? 89 : 178" [margin]="0"
                        [colorLight]="'#00000000'"></qrcode>

                        <div class="gift-data-point-info">
                            <p class="title" [ngStyle]="
                                styleService.getStyleRule([
                                tab.configurations.dataPoints.titlePoints,
                                null,
                                'heading6'
                                ])">{{tab.templateConfig.qrCodeTitle | conditionalUppercase
                                    : styleService.getUppercaseValue(
                                    tab.configurations.dataPoints.titlePoints
                                    ) }}</p>
                            <p class="value" [ngStyle]="
                                styleService.getStyleRule([
                                tab.configurations.dataPoints.value,
                                null,
                                'heading5'
                                ])">{{
                                    tab.templateConfig.qrCodeText |
                                    conditionalUppercase
                                    : styleService.getUppercaseValue(
                                    tab.configurations.dataPoints.value
                                    ) }}</p>
                        </div>
                    </div>    
                </div>
            </div>
            <div
            class="redemption-html-tmpl"
            [ngStyle]="{
                border: !!tab?.templateConfig?.description?.showHtmlBoxBorder
                ? ''
                : 'unset'
            }"
            [innerHtml]="
                getRedemptionInformationHtml(tab?.templateConfig?.description?.html)
            "
            ></div>
        </div>
        <div class="redemptions-content">
            <div class="gift-balance-exchange">
                <h4>{{ tab.templateConfig.beginYourPurchase }}</h4>
                <p>{{ needPointForGetDollars(1) | number }} point = $1</p>
            </div>

            <ng-container
            *ngIf="tab.templateConfig.buttonsOption === 'preSets'; else keyButton"
            >
            <div class="amount-container">
                <div
                class="col-12"
                appInputStyles
                [inputStyle]="
                    styleService.mapInputStyles(
                    tab.configurations.preSets.inputAmount
                    )
                "
                >
                <label class="label">{{ tab?.templateConfig?.enterAmount }}</label>
                <input
                    type="text"
                    [ngModel]="amountValue | currency : 'USD' : 'symbol'"
                    (ngModelChange)="currencyInputChanged($event)"
                    class="input"
                    placeholder="$0.00"
                />
                </div>
            </div>
            <ng-container *ngTemplateOutlet="explanationTmp"></ng-container>
            <div
                class="presets-wrapper"
                *ngIf="tab.templateConfig.buttonsOption === 'preSets'"
            >
                <div
                class="preset"
                *ngFor="let preset of tab.templateConfig.preSets"
                >
                <button
                    class="remove"
                    (click)="substractValue(preset.value)"
                    [ngStyle]="getButtonStyle(preset.value, tab, true)"
                >
                    -
                </button>
                <div
                    class="value"
                    [ngStyle]="
                    styleService.getStyleRule(
                        [
                        tab.configurations.preSets.amountButton.text,
                        null,
                        'heading3'
                        ],
                        [tab.configurations.preSets.amountButton.background, null],
                        [tab.configurations.preSets.amountButton.border]
                    )
                    "
                >
                    $ {{ preset.value }}
                </div>
                <button
                    class="add"
                    (click)="additionAmount(preset.value)"
                    [ngStyle]="getButtonStyle(preset.value, tab, false)"
                >
                    +
                </button>
                </div>
                <button
                class="max-amount"
                (click)="addMaxAmount()"
                [ngStyle]="
                    styleService.getStyleRule(
                    [
                        tab.configurations.preSets.maxAmountButton.text,
                        null,
                        'heading4'
                    ],
                    [
                        tab.configurations.preSets.maxAmountButton.background,
                        {
                        color:
                            mainTemplate.globalVariables.buttonsColor.primaryColor
                        }
                    ],
                    [tab.configurations.preSets.maxAmountButton.border]
                    )
                "
                >
                Max Amount
                </button>
            </div>
            </ng-container>
            <ng-template #keyButton>
            <div class="keyboard-wrapper">
                <div
                class="col-12"
                appInputStyles
                [inputStyle]="
                    styleService.mapInputStyles(
                    tab.configurations.freeEntry.inputAmount
                    )
                "
                >
                <label class="label">{{ tab?.templateConfig?.enterAmount }}</label>
                <input
                    type="text"
                    [ngModel]="amountValue | currency : 'USD' : 'symbol'"
                    (ngModelChange)="currencyInputChanged($event)"
                    class="input"
                    placeholder="$0.00"
                />
                </div>
                <ng-container *ngTemplateOutlet="explanationTmp"></ng-container>
                <div class="keyboard-container">
                <button
                    class="keyboard-button"
                    *ngFor="let item of keyboardButtons"
                    (click)="addValue(item)"
                    [ngStyle]="
                    styleService.getStyleRule(
                        [tab.configurations.freeEntry.numeralButton.text],
                        [
                        tab.configurations.freeEntry.numeralButton.background,
                        {
                            color:
                            mainTemplate.globalVariables.buttonsColor
                                .secondaryColor
                        }
                        ],
                        [tab.configurations.freeEntry.numeralButton.border]
                    )
                    "
                >
                    {{ item }}
                </button>
                <div
                    class="keyboard-button remove"
                    [ngStyle]="
                    styleService.getStyleRule(
                        [tab.configurations.freeEntry.deleteButton.text],
                        [
                        tab.configurations.freeEntry.deleteButton.background,
                        {
                            color:
                            mainTemplate.globalVariables.buttonsColor
                                .secondaryColor
                        }
                        ],
                        [tab.configurations.freeEntry.deleteButton.border]
                    )
                    "
                    (click)="keyboardBackspace()"
                >
                    <!--  -->
                    <mat-icon
                    aria-hidden="false"
                    [fontIcon]="
                        tab.configurations.freeEntry?.deleteButtonIcon?.iconName ||
                        'backspace_outline'
                    "
                    [ngStyle]="
                        styleService.getIconStyleRule(
                        [
                            tab.configurations.freeEntry?.deleteButtonIcon
                            ?.iconSize,
                            ''
                        ],
                        [
                            '',
                            tab.configurations.freeEntry?.deleteButtonIcon
                            ?.iconColor
                        ]
                        )
                    "
                    ></mat-icon>
                </div>
                <button
                    class="keyboard-button"
                    [ngStyle]="
                    styleService.getStyleRule(
                        [tab.configurations.freeEntry.numeralButton.text],
                        [
                        tab.configurations.freeEntry.numeralButton.background,
                        {
                            color:
                            mainTemplate.globalVariables.buttonsColor
                                .secondaryColor
                        }
                        ],
                        [tab.configurations.freeEntry.numeralButton.border]
                    )
                    "
                    (click)="addValue(0)"
                >
                    0
                </button>
                <div
                    class="keyboard-button confirm"
                    [ngStyle]="
                    styleService.getStyleRule(
                        [tab.configurations.freeEntry.confirmationButton.text],
                        [
                        tab.configurations.freeEntry.confirmationButton
                            .background,
                        {
                            color:
                            mainTemplate.globalVariables.buttonsColor
                                .secondaryColor
                        }
                        ],
                        [tab.configurations.freeEntry.confirmationButton.border]
                    )
                    "
                    (click)="addMaxAmount()"
                >
                    MAX
                </div>
                </div>
            </div>
            </ng-template>
        </div>
        </div>

        <div class="control-buttons">
        <button
            class="confirm-button button"
            (click)="openConfirmation()"
            [ngStyle]="
            styleService.getStyleRule(
                [tab.configurations.buttons.butonsConfirm.text, null, 'heading4'],
                [
                tab.configurations.buttons.butonsConfirm.background,
                { color: mainTemplate.globalVariables.buttonsColor.primaryColor }
                ],
                [tab.configurations.buttons.butonsConfirm.border]
            )
            "
        >
            {{
            "Confirm"
                | conditionalUppercase
                : styleService.getUppercaseValue(
                    tab.configurations.buttons.butonsConfirm.text
                    )
            }}
        </button>
        <button
            class="cancel-button button"
            (click)="clearAmount()"
            [ngStyle]="
            styleService.getStyleRule(
                [tab.configurations.buttons.buttonsClearAll.text, null, 'heading4'],
                [
                tab.configurations.buttons.buttonsClearAll.background,
                {
                    color: mainTemplate.globalVariables.buttonsColor.secondaryColor
                }
                ],
                [tab.configurations.buttons.buttonsClearAll.border]
            )
            "
        >
            {{
            "Clear All"
                | conditionalUppercase
                : styleService.getUppercaseValue(
                    tab.configurations.buttons.buttonsClearAll.text
                    )
            }}
        </button>
        </div>
    </div>
</ng-container>


<ng-template #explanationTmp>
    <p class="amount-explanation-text">
        <b
        [ngStyle]="
            styleService.getStyleRule([
            tab.configurations.main?.amountExplanation?.number,
            null,
            'heading4'
            ])
        "
        >
        {{ this.calculatePointsToRedeem() | number }}
    </b>
        <span
        [ngStyle]="
            styleService.getStyleRule([
            tab.configurations.main?.amountExplanation?.text,
            null,
            'heading5'
            ])
        "
        >
        {{
            tab?.templateConfig?.explanation?.text
            | conditionalUppercase
                : styleService.getUppercaseValue(
                    tab.configurations.main?.amountExplanation?.text
                )
        }}
        </span>
    </p>
</ng-template>

<ng-template #loaderTemplate>
    <div
      class="loader"
      *ngIf="isLoading"
    >
      <mat-spinner
        *ngIf="!mainTemplate.globalVariables.loader?.imageLink?.length"
        [color]="mainTemplate.globalVariables.backgroundPrimary.color"
        [diameter]="styleService.isQt3Kiosk ? 100 : 200"
      ></mat-spinner>
      <img
        class="loader-image"
        *ngIf="mainTemplate.globalVariables.loader?.imageLink?.length"
        [src]="mainTemplate.globalVariables.loader.imageLink"
      />
    </div>
  </ng-template>