<ng-container *ngIf="mainTemplate">
  <div
    class="content main-template-container"
    [ngStyle]="
      styleService.getStyleRule(undefined, [
        currentPage.templateSettings[selectTabIndex]?.configurations?.main
          ?.background,
        mainTemplate.globalVariables.backgroundPrimary
      ])
    "
  >
    <div class="header">
      <div class="header-block">
        <h1
          class="header-title"
          [ngStyle]="
            styleService.getStyleRule([
              currentPage.templateSettings[selectTabIndex]?.configurations?.main
                ?.title,
              undefined,
              'heading3'
            ])
          "
        >
          <a
            class="header-title-back"
            [routerLink]="'/'"
            [ngStyle]="
              styleService.getStyleRule(
                undefined,
                [
                  mainTemplate.components.backButton?.configurations
                    ?.background,
                  {
                    color:
                      mainTemplate.globalVariables.buttonsColor.secondaryColor
                  }
                ],
                [mainTemplate.components.backButton?.configurations?.border]
              )
            "
          >
            <img
              *ngIf="!mainTemplate.components?.backButton?.iconName?.length"
              src="/assets/images/last/back.svg"
              class="header-title-back-icon"
            />
            <mat-icon
              aria-hidden="false"
              *ngIf="mainTemplate.components?.backButton?.iconName?.length"
              [fontIcon]="mainTemplate.components.backButton.iconName"
              [ngStyle]="
                styleService.getIconStyleRule(
                  [mainTemplate.components.backButton.iconSize],
                  [mainTemplate.components.backButton.iconColor]
                )
              "
            ></mat-icon>
          </a>
          {{
            currentPage.pageLinkContainerSettings.title
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    currentPage.templateSettings[selectTabIndex]?.configurations
                      ?.main?.title
                  )
          }}
        </h1>
      </div>

      <app-sign-out></app-sign-out>
    </div>

    <div
      class="main"
      *ngIf="currentPage.templateSettings.length"
      [ngClass]="{
        'one-tab': currentPage.templateSettings.length < 2,
        'no-max-height':
          currentPage.templateSettings[selectTabIndex].templateName ===
            tabType.PDF ||
          currentPage.templateSettings[selectTabIndex].templateName ===
            tabType.Webpage
      }"
    >
      <mat-tab-group
        [@.disabled]="true"
        (selectedTabChange)="tabChanged($event)"
      >
        <mat-tab
          [label]="tab.sectionTitle"
          *ngFor="let tab of currentPage.templateSettings"
        >
          <ng-container [ngSwitch]="tab.templateName">
            <ng-container
              *ngSwitchCase="tabType.Offers"
              [ngTemplateOutlet]="offersTemplate"
              [ngTemplateOutletContext]="{ tab: tab }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="tabType.OffersAndDrawings"
              [ngTemplateOutlet]="offersAndDrawingsTemplate"
              [ngTemplateOutletContext]="{ tab: tab }"
            ></ng-container>

            <ng-container
              *ngSwitchCase="tabType.Profile"
              [ngTemplateOutlet]="profileTemplate"
              [ngTemplateOutletContext]="{ tab: tab }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="tabType.PDF"
              [ngTemplateOutlet]="pdfTemplate"
              [ngTemplateOutletContext]="{ tab: tab }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="tabType.Webpage"
              [ngTemplateOutlet]="webTemplate"
              [ngTemplateOutletContext]="{ tab: tab }"
            ></ng-container>
            <ng-container *ngSwitchCase="tabType.Redemptions">
              <app-redemption-template
                [tab]="tab"
                [mainTemplate]="mainTemplate"
                [pageLinkIndex]="pageLinkIndex"
                [selectTabIndex]="selectTabIndex"
              ></app-redemption-template>
            </ng-container>
            <ng-container *ngSwitchCase="tabType.RedemptionsV2">
              <app-redemption-v2-template
                [tab]="tab"
                [mainTemplate]="mainTemplate"
                [pageLinkIndex]="pageLinkIndex"
                [selectTabIndex]="selectTabIndex"
              ></app-redemption-v2-template>
            </ng-container>
            <ng-container
              *ngSwitchCase="tabType.Drawing"
              [ngTemplateOutlet]="drawingTemplate"
              [ngTemplateOutletContext]="{ tab: tab }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="tabType.OffersCms"
              [ngTemplateOutlet]="cmsOffersTemplate"
              [ngTemplateOutletContext]="{ tab: tab }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="tabType.OffersAndOffersCms"
              [ngTemplateOutlet]="cmsOffersAndOffersCmsTemplate"
              [ngTemplateOutletContext]="{ tab: tab }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="tabType.WinAndLoss"
              [ngTemplateOutlet]="winAndLossTemplate"
              [ngTemplateOutletContext]="{ tab: tab }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="tabType.DrawingCms"
              [ngTemplateOutlet]="cmsDrawingTemplate"
              [ngTemplateOutletContext]="{ tab: tab }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="tabType.OffersCmsOffersAndDrawings"
              [ngTemplateOutlet]="OffersCmsOffersAndDrawingsTemplate"
              [ngTemplateOutletContext]="{ tab: tab }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="tabType.GiftCard"
              [ngTemplateOutlet]="giftCardTemplate"
              [ngTemplateOutletContext]="{ tab: tab }"
            ></ng-container>
          </ng-container>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</ng-container>

<ng-template #loaderTemplate>
  <div
    class="loader"
    *ngIf="
      isLoading &&
      (campaigns === null || cmsOffers === null || drawings === null)
    "
  >
    <mat-spinner
      *ngIf="!mainTemplate.globalVariables.loader?.imageLink?.length"
      [color]="mainTemplate.globalVariables.backgroundPrimary.color"
      [diameter]="styleService.isQt3Kiosk ? 100 : 200"
    ></mat-spinner>
    <img
      class="loader-image"
      *ngIf="mainTemplate.globalVariables.loader?.imageLink?.length"
      [src]="mainTemplate.globalVariables.loader.imageLink"
    />
  </div>
</ng-template>

<ng-template #drawingTemplate let-tab="tab">
  <ng-container [ngTemplateOutlet]="loaderTemplate"></ng-container>

  <ng-container *ngIf="!isLoading">
    <ng-container *ngFor="let drawing of drawings">
      <ng-container
        [ngTemplateOutlet]="drawingBlocksTmp"
        [ngTemplateOutletContext]="{ drawing: drawing, tab: tab }"
      ></ng-container>
    </ng-container>
    <p
      class="offers-empty-messages drawings"
      [ngStyle]="
        styleService.getStyleRule([
          tab.configurations?.main?.noDrawingTextConfig,
          undefined,
          'mainText'
        ])
      "
    >
      {{
        tab.configurations?.main?.noDrawingsText ||
          "There are currently no drawings available."
      }}
    </p>
  </ng-container>
</ng-template>

<ng-template #cmsOffersTemplate let-tab="tab">
  <ng-container [ngTemplateOutlet]="loaderTemplate"></ng-container>

  <ng-container *ngIf="!isLoading">
    <p
      class="offers-empty-messages"
      *ngIf="!cmsOffers.length"
      [ngStyle]="
        styleService.getStyleRule([
          tab.configurations?.main?.noOffers,
          undefined,
          'mainText'
        ])
      "
    >
      {{
        tab.configurations?.main?.noOffersText || "No Offers"
          | conditionalUppercase
            : tab.configurations?.main?.noOffers.isUpperCaseEnabled
      }}
    </p>
    <div class="item" *ngFor="let offer of cmsOffers">
      <app-cms-offer-card
        (onDetailClose)="processCmsOffers()"
        [indexes]="[pageLinkIndex, selectTabIndex]"
        [tab]="tab"
        [offer]="offer"
        [mainTemplate]="mainTemplate"
      ></app-cms-offer-card>
    </div>
  </ng-container>
</ng-template>

<ng-template #cmsDrawingTemplate let-tab="tab">
  <ng-container [ngTemplateOutlet]="loaderTemplate"></ng-container>

  <ng-container *ngIf="!isLoading">
    <p
      class="offers-empty-messages"
      *ngIf="!cmsDrawing.length"
      [ngStyle]="
        styleService.getStyleRule([
          tab.configurations?.main?.noDrawing,
          undefined,
          'mainText'
        ])
      "
    >
      {{
        tab.configurations?.main?.noDrawingText || "No CMS Drawing"
          | conditionalUppercase
            : tab.configurations?.main?.noDrawing.isUpperCaseEnabled
      }}
    </p>
    <div class="item" *ngFor="let drawing of cmsDrawing">
      <app-cms-drawing-card
        (onDetailClose)="processCmsDrawing()"
        [indexes]="[pageLinkIndex, selectTabIndex]"
        [tab]="tab"
        [drawingCMS]="drawing"
        [mainTemplate]="mainTemplate"
        [isCmsDrawingDisplayModalView]="bCmsDrawingDisplayModalView"
      ></app-cms-drawing-card>
    </div>
  </ng-container>
</ng-template>

<ng-template #cmsOffersAndOffersCmsTemplate let-tab="tab">
  <ng-container [ngTemplateOutlet]="loaderTemplate"></ng-container>

  <ng-container *ngIf="!isLoading && cmsOffers !== null && campaigns !== null">
    <p
      class="offers-empty-messages"
      *ngIf="!cmsOffers.length && showOfferEmptyMessage"
      [ngStyle]="
        styleService.getStyleRule([
          tab.configurations?.main?.noOffers,
          undefined,
          'mainText'
        ])
      "
    >
      {{
        tab.configurations?.main?.noOffersText || "No Offers"
          | conditionalUppercase
            : tab.configurations?.main?.noOffers.isUpperCaseEnabled
      }}
    </p>
    <div class="item" *ngFor="let offer of cmsOffers">
      <app-cms-offer-card
        (onDetailClose)="processCmsOffers()"
        [indexes]="[pageLinkIndex, selectTabIndex]"
        [tab]="tab"
        [offer]="offer"
        [mainTemplate]="mainTemplate"
      ></app-cms-offer-card>
    </div>
    <ng-container *ngFor="let campaign of campaigns">
      <div class="item" *ngIf="!hasCampaignProhibitedRewardState(campaign)">
        <app-card
          [indexes]="[pageLinkIndex, selectTabIndex]"
          [tab]="tab"
          [campaign]="campaign"
          [accessRequestId]="accessRequestId"
          [lobbyToken]="tab.templateConfig.lobbyToken"
          (onDeleteExpiredCampaign)="removeExpiredCampaign($event)"
          (onDetailClose)="updateOffers()"
        ></app-card>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #OffersCmsOffersAndDrawingsTemplate let-tab="tab">
  <ng-container [ngTemplateOutlet]="loaderTemplate"></ng-container>
  <ng-container
    *ngIf="
      !isLoading ||
      drawings !== null ||
      cmsOffers !== null ||
      campaigns !== null
    "
  >
    <div class="item" *ngFor="let offer of cmsOffers">
      <app-cms-offer-card
        (onDetailClose)="processCmsOffers()"
        [indexes]="[pageLinkIndex, selectTabIndex]"
        [tab]="tab"
        [offer]="offer"
        [mainTemplate]="mainTemplate"
      ></app-cms-offer-card>
    </div>
    <ng-container *ngFor="let campaign of campaigns">
      <div class="item" *ngIf="!hasCampaignProhibitedRewardState(campaign)">
        <app-card
          [indexes]="[pageLinkIndex, selectTabIndex]"
          [tab]="tab"
          [campaign]="campaign"
          [accessRequestId]="accessRequestId"
          [lobbyToken]="tab.templateConfig.lobbyToken"
          (onDeleteExpiredCampaign)="removeExpiredCampaign($event)"
          (onDetailClose)="updateOffers()"
        ></app-card>
      </div>
    </ng-container>
    <ng-container *ngFor="let drawing of drawings">
      <ng-container
        [ngTemplateOutlet]="drawingBlocksTmp"
        [ngTemplateOutletContext]="{ drawing: drawing, tab: tab }"
      ></ng-container>
    </ng-container>
    <p
      class="offers-empty-messages offers-and-drawings"
      [ngStyle]="
        styleService.getStyleRule([
          tab.configurations?.main?.noItems,
          undefined,
          'mainText'
        ])
      "
    >
      {{
        tab.configurations?.main?.noItemsText || "No Offers"
          | conditionalUppercase
            : tab.configurations?.main?.noItems.isUpperCaseEnabled
      }}
    </p>
  </ng-container>
</ng-template>

<ng-template #offersTemplate let-tab="tab">
  <ng-container [ngTemplateOutlet]="loaderTemplate"></ng-container>
  <ng-container *ngIf="!isLoading">
    <p
      class="offers-empty-messages"
      *ngIf="showOfferEmptyMessage"
      [ngStyle]="
        styleService.getStyleRule([
          tab.configurations?.main?.noOffers,
          undefined,
          'mainText'
        ])
      "
    >
      {{ tab.configurations?.main?.noOffersText }}
    </p>
    <ng-container *ngFor="let campaign of campaigns">
      <div class="item" *ngIf="!hasCampaignProhibitedRewardState(campaign)">
        <app-card
          [indexes]="[pageLinkIndex, selectTabIndex]"
          [tab]="tab"
          [campaign]="campaign"
          [accessRequestId]="accessRequestId"
          [lobbyToken]="tab.templateConfig.lobbyToken"
          (onDeleteExpiredCampaign)="removeExpiredCampaign($event)"
          (onDetailClose)="updateOffers()"
        ></app-card>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #offersAndDrawingsTemplate let-tab="tab">
  <ng-container [ngTemplateOutlet]="loaderTemplate"></ng-container>
  <ng-container *ngIf="!isLoading && drawings !== null && campaigns !== null">
    <ng-container *ngFor="let campaign of campaigns">
      <div class="item" *ngIf="!hasCampaignProhibitedRewardState(campaign)">
        <app-card
          [indexes]="[pageLinkIndex, selectTabIndex]"
          [tab]="tab"
          [campaign]="campaign"
          [accessRequestId]="accessRequestId"
          [lobbyToken]="tab.templateConfig.lobbyToken"
          (onDeleteExpiredCampaign)="removeExpiredCampaign($event)"
          (onDetailClose)="updateOffers()"
        ></app-card>
      </div>
    </ng-container>
    <ng-container *ngFor="let drawing of drawings">
      <ng-container
        [ngTemplateOutlet]="drawingBlocksTmp"
        [ngTemplateOutletContext]="{ drawing: drawing, tab: tab }"
      ></ng-container>
    </ng-container>
    <p
      class="offers-empty-messages offers-and-drawings"
      [ngStyle]="
        styleService.getStyleRule([
          tab.configurations?.main?.noItems,
          undefined,
          'mainText'
        ])
      "
    >
      {{ tab.configurations?.main?.noItemsText }}
    </p>
  </ng-container>
</ng-template>

<ng-template #pdfTemplate let-tab="tab">
  <pdf-viewer
    *ngIf="tab.templateConfig.fileUrl"
    [src]="tab.templateConfig.fileUrl"
    [render-text]="true"
    [original-size]="false"
  ></pdf-viewer>
  <pdf-viewer
    *ngIf="tab.templateConfig.url"
    [src]="tab.templateConfig.url"
    [render-text]="true"
    [original-size]="false"
  ></pdf-viewer>
</ng-template>

<ng-template #winAndLossTemplate let-tab="tab">
  <ng-container [ngTemplateOutlet]="loaderTemplate"></ng-container>

  <ng-container *ngIf="!isLoading">
    <div class="win-loss-container" *ngIf="!isLoading">
      <ng-container *ngIf="tab?.templateConfig?.title?.isVisible">
        <div class="win-loss-paragraph" *ngIf="!bSubmitted">
          <p
            [ngStyle]="
              styleService.getStyleRule([
                tab.configurations.inputs.titleWL,
                undefined,
                'main'
              ])
            "
            [innerHTML]="
              tab?.templateConfig?.initialText?.titleText
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.inputs?.titleWL
                    )
            "
          ></p>
        </div>
      </ng-container>

      <ng-container *ngIf="bSubmitted">
        <div class="win-loss-textAfter">
          <p
            *ngIf="!bError"
            [ngStyle]="
              styleService.getStyleRule([
                tab.configurations.inputs.textAfter,
                undefined,
                'main'
              ])
            "
            [innerHTML]="
              tab?.templateConfig?.initialText?.textAfter
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.inputs?.textAfter
                    )
            "
          ></p>
          <p
            *ngIf="bError"
            [ngStyle]="
              styleService.getStyleRule([
                tab.configurations.inputs.errorMessageAfterSubmit,
                undefined,
                'main'
              ])
            "
            [innerHTML]="
              WLErrorMessageText
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.inputs?.errorMessageAfterSubmit
                    )
            "
          ></p>
        </div>
      </ng-container>

      <ng-container *ngIf="!bSubmitted">
        <div class="win-loss-textBefore">
          <p
            [ngStyle]="
              styleService.getStyleRule([
                tab.configurations.inputs.textBefore,
                undefined,
                'main'
              ])
            "
            [innerHTML]="
              tab?.templateConfig?.initialText?.textBefore
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.inputs?.textBefore
                    )
            "
          ></p>
        </div>
      </ng-container>

      <ng-container *ngIf="!bSubmitted">
        <div class="select-boxes">
          <mat-form-field
            autocomplete="nope"
            class="winlossContainer"
            appearance="outline"
            color="primary"
            [ngStyle]="
              styleService.getStyleRule(
                undefined,
                [tab.configurations.main?.yearDropdown?.background],
                [tab.configurations.main?.yearDropdown?.border]
              )
            "
          >
            <mat-select
              [(ngModel)]="selectedYear"
              panelClass="mat-winloss-select"
              [ngStyle]="
                styleService.getStyleRule(
                  [tab.configurations.main?.yearDropdown],
                  [tab.configurations.main?.yearDropdown?.background],
                  [tab.configurations.main?.yearDropdown?.border]
                )
              "
            >
              <mat-option
                class="mat-winloss-option"
                *ngFor="let year of years"
                [value]="year"
                >{{ year }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <button
          class="winLossButton"
          (click)="submitWinLossStatement()"
          [ngStyle]="
            styleService.getStyleRule(
              [
                tab.configurations.buttons?.primaryButtons?.text,
                undefined,
                'main'
              ],
              [
                tab.configurations.buttons?.primaryButtons?.background,
                {
                  color: mainTemplate.globalVariables.buttonsColor.primaryColor
                }
              ],
              [
                tab.configurations.buttons?.primaryButtons?.border,
                undefined,
                mainTemplate.globalVariables.borders
              ]
            )
          "
        >
          {{
            tab.configurations.buttons.innerTextButton
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    tab.configurations.buttons?.primaryButtons?.text
                  )
          }}
        </button>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #webTemplate let-tab="tab">
  <iframe
    [src]="updateWebpageUrl(tab.templateConfig.url) | safeUrl"
    frameborder="0"
    class="web-iframe"
  ></iframe>
</ng-template>

<ng-template #profileTemplate let-tab="tab">
  <ng-container [ngTemplateOutlet]="loaderTemplate"></ng-container>

  <div class="ksk-profile" *ngIf="!isLoading">
    <ng-container
      *ngTemplateOutlet="
        profileRow;
        context: {
          isVisible: tab.templateConfig.name?.isVisible,
          tab: tab,
          title: 'Name',
          text: getProfileName(),
          isEditable: tab.templateConfig.name?.isEditable,
          modalType: modalType.Name
        }
      "
    >
    </ng-container>
    <!-- <ng-container *ngTemplateOutlet="
        profileRow;
        context: {
          isVisible: tab.templateConfig.nickName.isVisible,
          tab: tab,
          title: 'Nickname',
          text: '{UC_VALUE}',
          isEditable: tab.templateConfig.nickName?.isEditable,
          modalType: modalType.Nickname
        }
      ">
    </ng-container> -->
    <ng-container
      *ngTemplateOutlet="
        profileRow;
        context: {
          isVisible: tab.templateConfig.email?.isVisible,
          tab: tab,
          title: 'Email *',
          text: getProfileEmail(),
          isEditable: tab.templateConfig.email?.isEditable,
          modalType: modalType.Email
        }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        profileRow;
        context: {
          isVisible: tab.templateConfig.phoneNumber?.isVisible,
          tab: tab,
          title: 'Phone number *',
          text: getPhoneNumber(profileService.playerProfile.phoneNumbers),
          isEditable: tab.templateConfig.phoneNumber?.isEditable,
          modalType: modalType.Phone
        }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        profileRow;
        context: {
          isVisible: tab.templateConfig.pin?.isVisible,
          tab: tab,
          title: 'Pin *',
          text: '*****',
          isEditable: tab.templateConfig.pin?.isEditable,
          modalType: modalType.Pin
        }
      "
    >
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        profileRow;
        context: {
          isVisible: tab.templateConfig.primaryAddress?.isVisible,
          tab: tab,
          title: 'Primary Address',
          text: getPretifiedAddress(profileService.playerProfile.addresses?.[0]),
          isEditable: tab.templateConfig.primaryAddress?.isEditable,
          modalType: modalType.Address
        }
      "
    >
    </ng-container>
    <ng-container
      *ngFor="
        let address of getPreferedAddresses(
          profileService.playerProfile.addresses
        )
      "
    >
      <ng-container
        *ngTemplateOutlet="
          profileRow;
          context: {
            isVisible: tab.templateConfig.preferredAddress?.isVisible,
            tab: tab,
            title: 'Preferred Address',
            text: getPretifiedAddress(address),
            isEditable: tab.templateConfig.preferredAddress?.isEditable,
            modalType: modalType.Address
          }
        "
      >
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template
  #profileRow
  let-isVisible="isVisible"
  let-tab="tab"
  let-title="title"
  let-text="text"
  let-isEditable="isEditable"
  let-modalType="modalType"
>
  <div class="ksk-profile-row" *ngIf="isVisible && showProfileRow(modalType)">
    <div class="ksk-profile-info">
      <h5
        class="ksk-profile-name"
        [ngStyle]="
          styleService.getStyleRule([
            tab.configurations.main.title,
            undefined,
            'heading4'
          ])
        "
      >
        {{
          title
            | conditionalUppercase
              : styleService.getUppercaseValue(tab.configurations.main.title)
        }}
      </h5>
      <p
        class="ksk-profile-text"
        [ngStyle]="
          styleService.getStyleRule([
            tab.configurations.main.text,
            undefined,
            'mainText'
          ])
        "
      >
        {{
          text
            | conditionalUppercase
              : styleService.getUppercaseValue(tab.configurations.main.text)
        }}
      </p>
    </div>
    <button
      *ngIf="isEditable"
      class="ksk-profile-btn"
      (click)="openModal(modalType, tab)"
      [ngStyle]="
        styleService.getStyleRule(
          [tab.configurations.buttons?.editButton?.text],
          [
            tab.configurations.buttons?.editButton?.background,
            { color: mainTemplate.globalVariables.buttonsColor.primaryColor }
          ],
          [tab.configurations.buttons?.editButton?.border]
        )
      "
    >
      <mat-icon
        aria-hidden="false"
        *ngIf="
          tab.configurations.buttons?.editButtonIcon?.iconName &&
            tab.configurations.buttons.editButtonIcon.iconName.length;
          else textButton
        "
        [fontIcon]="tab.configurations.buttons.editButtonIcon.iconName"
        [ngStyle]="
          styleService.getIconStyleRule(
            [tab.configurations.buttons?.editButtonIcon?.iconSize],
            [tab.configurations.buttons?.editButtonIcon?.iconColor]
          )
        "
      ></mat-icon>
      <ng-template #textButton>
        {{
          tab.configurations.buttons.innerText
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tab.configurations.buttons?.editButton?.text
                )
        }}
      </ng-template>
    </button>
  </div>
</ng-template>

<ng-template #giftCardTemplate let-tab="tab">
  <app-gift-template
    [tab]="tab"
    [mainTemplate]="mainTemplate"
    [pageLinkIndex]="pageLinkIndex"
    [selectTabIndex]="selectTabIndex"
  ></app-gift-template>
</ng-template>

<ng-template #drawingBlocksTmp let-tab="tab" let-drawing="drawing">
  <div
    id="drawingItem"
    class="item"
    *ngIf="
      drawing.drawingEvents.length &&
      (tab.templateConfig?.displaySettings?.showDrawings ?? true)
    "
  >
    <app-drawing
      type="Campaign"
      [drawing]="drawing"
      [tab]="tab"
      (onActivate)="reloadTab()"
    ></app-drawing>
  </div>
  <div
    id="drawingItem"
    class="item"
    *ngIf="
      drawing.awardFreeEntry &&
      !drawing.isFreeEntryClaimed &&
      (tab.templateConfig?.displaySettings?.showFreeEntries ?? true)
    "
  >
    <app-drawing
      type="FreeEntry"
      [drawing]="drawing"
      [tab]="tab"
      (onActivate)="reloadTab()"
    ></app-drawing>
  </div>
  <ng-container
    *ngIf="tab.templateConfig?.displaySettings?.showMultipliers ?? true"
  >
    <div
      id="drawingItem"
      class="item"
      *ngFor="let multiplier of drawing.drawingMultiplyEntries"
    >
      <app-drawing
        type="Multiplier"
        [drawing]="drawing"
        [tab]="tab"
        [multiplier]="multiplier"
        (onActivate)="reloadTab()"
      ></app-drawing>
    </div>
  </ng-container>
  <ng-container
    *ngIf="tab.templateConfig?.displaySettings?.showTierEntries ?? true"
  >
    <div
      id="drawingItem"
      class="item"
      *ngFor="let tierEntry of drawing?.patronAwardTierEntries"
    >
      <app-drawing
        type="TierEntry"
        [drawing]="drawing"
        [tab]="tab"
        [tierEntry]="tierEntry"
        (onActivate)="reloadTab()"
      ></app-drawing>
    </div>
  </ng-container>
</ng-template>

<ng-template #giftCardTemplate let-tab="tab">
  <app-gift-template
    [tab]="tab"
    [mainTemplate]="mainTemplate"
    [pageLinkIndex]="pageLinkIndex"
    [selectTabIndex]="selectTabIndex"
  ></app-gift-template>
</ng-template>
