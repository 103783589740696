import { Component, Injector, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  SnackbarComponent,
  ViewModalComponent,
} from 'projects/kiosk-app/src/app/components';

@Component({
  selector: 'app-view-email',
  templateUrl: './view-email.component.html',
  styleUrls: ['./view-email.component.scss'],
})
export class ViewEmailComponent extends ViewModalComponent implements OnInit {
  public emails: any[] = [];

  constructor(private readonly snackBar: MatSnackBar, injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.currentViewModalType = 'email';
    this.emails = this.cloneProfile.emails;
  }

  public getMainEmailIndex() {
    if (this.profileService.isUcSecondVersion) {
      const { a_PrimaryEmail, a_HomeEmail } = this.profileService.playerProfile;
      const emailIndex = this.emails.findIndex(
        (e) => e.type === a_PrimaryEmail.type || e.type === a_HomeEmail.type
      );
      return emailIndex;
    }
    return this.emails.findIndex((e) => e.location === 'Main Email');
  }

  public setOptOutValue() {
    this.emails[this.getMainEmailIndex()].sendTo === 'true'
      ? (this.emails[this.getMainEmailIndex()].sendTo = 'false')
      : (this.emails[this.getMainEmailIndex()].sendTo = 'true');
  }

  override updateFields(): void {
    this.cloneProfile.emails = this.emails;
  }

  public get isValidEmail(): boolean {
    const email = this.emails[this.getMainEmailIndex()].address;

    const regexLocal = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const regexBuilder = new RegExp(
      eval(this.tabData?.templateConfig?.email?.regex)
    );
    return regexBuilder ? regexBuilder.test(email) : regexLocal.test(email);
  }

  public checkValidate(): void {
    const value = this.emails[this.getMainEmailIndex()]?.address;
    if (value) {
      if (!this.isValidEmail) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          panelClass: 'error',
          duration: 5000,
          horizontalPosition: 'end',
          data: {
            type: 'error',
            message: 'Please enter a valid email address',
          },
        });
        return;
      }
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        panelClass: 'error',
        duration: 5000,
        horizontalPosition: 'end',
        data: {
          type: 'error',
          message: 'Please enter an email address',
        },
      });
      return;
    }

    this.closeModal(true);
  }
}
