import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService, LogService, PrintService, ProfileService, StyleService } from '../../services';
import { environment } from './../../../environments/environment';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as uuid from 'uuid';
import moment from 'moment';

@Component({
  selector: 'app-confirm-redeem-modal',
  templateUrl: './confirm-redeem-modal.component.html',
  styleUrls: ['./confirm-redeem-modal.component.scss']
})
export class ConfirmRedeemModalComponent implements OnInit {

  constructor(
    private readonly dialogRef: MatDialogRef<any>,
    private readonly printService: PrintService,
    private readonly profileService: ProfileService,
    private readonly authService: AuthService,
    private readonly snackbar: MatSnackBar,
    private readonly logService: LogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public styleService: StyleService
  ) { }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    let backDropStyles: CSSStyleDeclaration = (document.querySelectorAll('.backDropClass')[0] as any)?.style;
    backDropStyles.background = this.data.styles.overlay || '#00000052';
    const dialogSurface = document.querySelectorAll('.mat-mdc-dialog-surface')[0];
    let contentStyles: CSSStyleDeclaration = (dialogSurface.getElementsByClassName('content')[0] as HTMLElement).style;
    (dialogSurface as HTMLElement).style.borderRadius = this.data.styles.border.borderRadius + 'px';
    (dialogSurface as HTMLElement).style.borderColor = this.data.styles.border.borderColor;
    (dialogSurface as HTMLElement).style.borderWidth = this.data.styles.border.borderWidth + 'px';
    contentStyles.background = this.data.styles.background.color;
    contentStyles.backgroundImage = `url(${this.data.styles.background.imageLink})`
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public async confirmRedemption() {
    if (this.data.currentTemplate.templateConfig.isPrintVoucherRequired) {
      this.closeDialog();
      (this.data.callback() as Promise<boolean | string>).then(() => {
        this.printPage();
        this.logService.writeLog(`
        User ${this.profileService.playerProfile.playerId} converted
        ${this.data.pointToReedem} points to ${this.data?.conversionType} ${this.data.redeemValue}$ `);
      }).catch((err) => {
        this.logService.writeLog(
          `User ${this.profileService.playerProfile.playerId} tried to convert ${this.data.pointToReedem} points to ${this.data?.conversionType}: ${this.data.redeemValue}
          Error:${JSON.stringify(err)}
          `
        );
      })
    } else {
      this.closeDialog();
      try {
        await this.data.callback();
        this.logService.writeLog(`User ${this.profileService.playerProfile.playerId} converted
        ${this.data.pointToReedem} points to ${this.data?.conversionType} ${this.data.redeemValue}$ `);

      } catch (error) {
        this.logService.writeLog(`User ${this.profileService.playerProfile.playerId} tried to convert ${this.data.pointToReedem} points to ${this.data?.conversionType}: ${this.data.redeemValue}
        Error:${JSON.stringify(error)}
        `);
      }
    }
  }

  public async printPage(): Promise<void> {
    const page = this.printService.preparePage(this.data.currentTemplate.configurations.voucher.html, {
      pageIndex: this.data.pageIndex,
      templateIndex: this.data.templateIndex,
      voucherId: uuid.v4(),
      convertedPoints: this.data.redeemValue,
      promotionName: this.data.conversionType,
      expirationDate: this.getExpiratonDate(),
      patronName: this.getPatronName(),
    }, 'html');

    this.logService.writeLog('Start printing redeem voucher');
    await Promise.race([
      this.printService.printPage(page),
      // this.printService.printTimeOut(10000)
    ]).then(() => {}).catch((e) => {
      if (e.message === 'Timeout') {
        this.logService.writeLog('Start printing reedem vouchet failed due to timeout');
      }

      this.snackbar.openFromComponent(SnackbarComponent, {
        panelClass: 'error',
        duration: 5000,
        horizontalPosition: 'end',
        data: {
          type: 'error',
          message: 'Print failed, but your point was converted.',
        },
      });
    });
  }

  private getPatronName(): string {
    if (this.profileService.isUcSecondVersion) {
      return `${this.profileService.playerProfile.playerProfile.firstName} ${this.profileService.playerProfile.playerProfile.lastName}`
    }
    return `${this.profileService.playerProfile.name.first} ${this.profileService.playerProfile.name.last}`;
  }

  private getExpiratonDate() {
    if (!!this.data.currentTemplate.configurations.voucher.expirationDays) {
      return this.getDate(true, true, this.data.currentTemplate.configurations.voucher.expirationDays);
    }
    return 'Unexpired'
  }

  private getDate(isFull: boolean, is24Hours?: boolean, expirationDate?: number): string {
    const now = new Date();
    if (!!expirationDate) {
      now.setDate(now.getDate() + Number(expirationDate))
    }

    const days = now.getDate();
    const month = now.getMonth() + 1;
    const year = now.getFullYear().toString().substr(-2);
    const hours = is24Hours ? now.getHours() : now.getHours() % 12 || 12;
    const minutes = now.getMinutes();
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const AMPM = now.getHours() >= 12 ? 'PM' : 'AM';

    let preparedString = `${month}/${days}/${year}`;

    if (isFull) {
      preparedString += ` ${hours}:${minutes}:${seconds}`;
    }

    if (!is24Hours) {
      preparedString += ` ${AMPM}`;
    }

    return preparedString;
  }

}
