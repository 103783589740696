import { Component, Inject, OnInit, SkipSelf } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LogService, OfferService, PrintService, ProfileService, StyleService } from '../../services';
import { lastValueFrom } from 'rxjs';
import { cloneDeep } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VoucherStatus } from '../../models/voucher-status.enum';
import { SnackbarComponent } from '../snackbar/snackbar.component';

enum SessionStorageItems {
  PrintedOffers = 'printedOffers',
  FailedPrintOffers = 'failedPrintOffers'
}

@Component({
  selector: 'app-cms-offer-modal',
  templateUrl: './cms-offer-modal.component.html',
  styleUrls: ['./cms-offer-modal.component.scss']
})
export class CmsOfferModalComponent implements OnInit {

  public printCounter = 0;

  public isLoading = false;

  public redeemedOfferDisplaySettings: any;

  public voucherNumber = '';

  public get isOfferRedeemed() {
    return this.data.offer.couponStatus === 'Redeemed';
  }

  public get isPrintButtonHidden() {
    return !!this.getSessionStorageItem(this.data.offer.id, SessionStorageItems.PrintedOffers) || !!this.getSessionStorageItem(this.data.offer.id, SessionStorageItems.FailedPrintOffers) || this.printCounter === 3;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @SkipSelf() public styleService: StyleService,
    private readonly printService: PrintService,
    private profileService: ProfileService,
    private offerService: OfferService,
    private snackBar: MatSnackBar,
    private logService: LogService,
    private readonly dialogRef: MatDialogRef<any>,
  ) { }

  ngOnInit() {
    this.setSessionStorageProps();
    this.redeemedOfferDisplaySettings = this.data.tab.configurations.main.redeemedOfferDisplaySettings;
  }

  private setSessionStorageProps() {
    Object.values(SessionStorageItems).forEach(value => {
      if (!sessionStorage.getItem(value)) {
        sessionStorage.setItem(value, JSON.stringify([]));
      }
    });
  }

  public getSessionStorageItem(id: string, type: string) {
    const storageValues = sessionStorage.getItem(type);
    if (storageValues?.length) {
      return (JSON.parse(storageValues) as []).find(offerId => offerId === id);
    }
  }

  public setSessionStorageItem(id: string, type: string) {
    const storageValues: string[] = JSON.parse(sessionStorage.getItem(type)!);
    storageValues.push(id);
    sessionStorage.setItem(type, JSON.stringify(storageValues));
  }

  public get getPrintButtonText(): string {
    const printBtn = this.data.tab.configurations.cardOfferDetails?.buttonPrintOfferText;
    return printBtn?.length ? printBtn : "Print";
  }

  public get getPrintRedeemStye(): any {
    const offerDetais = this.data.tab.configurations.cardOfferDetails;
    return offerDetais.buttonPrintOffer ?? offerDetais.redeemOfferButton;
  }

  public async printPage() {
    if (this.printCounter === 2) {
      this.setSessionStorageItem(this.data.offer.id, SessionStorageItems.FailedPrintOffers)
      lastValueFrom(this.offerService.setVoucherStatus(VoucherStatus.Error, this.voucherNumber))
    }

    ++this.printCounter
    this.isLoading = true;

    const [pageIndex, templateIndex] = this.data.indexes;

    const details = this.data.offer.extendedDetails;
    const profile = this.profileService.playerProfile;
    if (!this.voucherNumber) {
      const { number } = await lastValueFrom(this.offerService.getVoucherUniqCode()) as any;
      this.voucherNumber = number;
    }

    const page = this.printService.preparePage(this.data.tab.configurations.voucher.cmsHtml ?? this.data.tab.configurations.voucher.html, {
      pageIndex,
      templateIndex,
      voucher: this.voucherNumber,
      voucherImage: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn7dIFpb4Q2BlY19WChxV6-NbgJCLgsCZzdg&usqp=CAU',
      title: details.title,
      playerId: profile.playerId,
      playerName: this.profileService.fallBackDataPointValues().playerName,
      ticketNumber: this.voucherNumber,
    }, this.data.tab.configurations.voucher.cmsHtml ? 'cmsHtml' : 'html');

    if (this.data.offer.extendedDetails.isRedeemable && this.data.offer.couponStatus !== 'Redeemed') {
      this.redeemReward(true);
    }

    this.logService.writeLog('Start printing cms offer');
    Promise.race([
      this.printService.printPage(page),
      // this.printService.printTimeOut(10000)
    ]).then((res) => {
      this.setSessionStorageItem(this.data.offer.id, SessionStorageItems.PrintedOffers)
      lastValueFrom(this.offerService.setVoucherStatus(VoucherStatus.Printed, this.voucherNumber));
      this.snackBar.open(`${this.offerService.successPrintMessage}`, '', {
        panelClass: 'success',
        duration: 5000,
        horizontalPosition: 'end',
      });
      
      if (this.data.tab.templateConfig.isNeedToCloseAfterPrint) {
        this.closeModal();
      }
    }).catch((e) => {
      if (e.message === 'Timeout') {
        this.logService.writeLog('Start printing cms offer failed due to timeout');
      }

      this.snackBar.openFromComponent(SnackbarComponent, {
        panelClass: 'error',
        duration: 5000,
        horizontalPosition: 'end',
        data: {
          type: 'error',
          message: 'Print failed. Please try again.',
        },
      });
      lastValueFrom(this.offerService.setVoucherStatus(VoucherStatus.Error, this.voucherNumber));
    });
    this.isLoading = false;
  };

  public closeModal(): void {
    this.dialogRef.close();
  }


  public redeemReward(isRedemptionWithPrinting?: boolean) {
    if (!isRedemptionWithPrinting) {
      this.isLoading = true;
    }
    let offerToTransfer = cloneDeep(this.data.offer);
    delete offerToTransfer.extendedDetails;
    this.offerService.redeemCmsOffer(offerToTransfer).subscribe(v => {
      if (!v?.errors?.length || v?.status === 'Redeemed') {

        this.data.offer.couponStatus = 'Redeemed';
        this.snackBar.open(`${this.offerService.successRedeemMessage}`, '', {
          panelClass: 'success',
          duration: 5000,
          horizontalPosition: 'end',
        });
        this.logService.writeLog(`
          Coupon ${this.data.offer.extendedDetails.title} was sucessfully redeemed.
        `);
      } else {
        this.handleRedeemError(v?.errors[0]?.message)
      }
      if (!isRedemptionWithPrinting) {
        this.isLoading = false;
      }
    }, (error) => {
      this.handleRedeemError(error);
      if (!isRedemptionWithPrinting) {
        this.isLoading = false;
      }
    });
  }

  private handleRedeemError(error: any) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      panelClass: 'error',
      duration: 5000,
      horizontalPosition: 'end',
      data: {
        type: 'error',
        message: this.offerService.failRedeemMessage,
      },
    });
    this.logService.writeLog(JSON.stringify(error));
  }
}
