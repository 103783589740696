import { Component, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StyleService } from '../../services/style.service';
import cloneDeep from 'lodash/cloneDeep';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-gift-card-success',
    styleUrls: ['./gift-card-success.component.scss'],
    templateUrl: './gift-card-success.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class GiftCardSuccessComponent implements OnDestroy {
    constructor(
        private sanitizer: DomSanitizer,
        public styleService: StyleService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readonly dialogRef: MatDialogRef<any>,
    ) {}

    ngOnDestroy(): void {
        const styles = document.getElementById('giftCardSuccessStyles');
        if (styles) {
            styles.remove();
        }
    }

    public closeModal(): void {
      this.dialogRef.close();
    }

    public getInformationHtml(html: string) {
      if (!html) {
        return;
      }
      const valuesToChange = [
        {
          templateVariable: '{{confirmCode}}',
          templateVariableValue: this.data.orderId,
        },
      ];
      valuesToChange.forEach((value) => {
        html = html.replace(
          value.templateVariable,
          String(value.templateVariableValue)
        );
      });
      this.appendRedemptionStyles();
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    private appendRedemptionStyles() {
      if (document.getElementById('giftCardSuccessStyles')) {
        return;
      }
      let css = cloneDeep(this.data.css);
      if (this.styleService.isQt3Kiosk) {
        if (this.data.css) {
          css = this.styleService.dividePixelStylesByTwo(css)
        }
      }
      const styles = css;
  
      const body = document.getElementsByTagName('body')[0];
      const style = document.createElement('style');
      style.id = 'giftCardSuccessStyles';
      style.type = 'text/css';
      style.appendChild(document.createTextNode(styles));
      body.appendChild(style);
    }
}