import { Component, OnDestroy, OnInit, ViewEncapsulation, Injector, Input } from '@angular/core';
import { BaseComponent, GiftCardSuccessComponent, InfoModalComponent } from '../../../../components';
import { StyleService, IntegrationService, ProfileService, LogService } from '../../../../services';
import {  MainTemplate } from '../../../../models/template';
import { MatDialog } from '@angular/material/dialog';
import { DataPointService } from '../../../../services/data-point.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take, filter, switchMap } from 'rxjs/operators';
import { SnackbarComponent } from '../../../../components/snackbar/snackbar.component';
import { firstValueFrom } from 'rxjs';
import {
  RedemptionTemplateConfigModel,
  RedemptionsConfigModel,
  TierLevels,
} from '../../../../models/redemption-template.model';
import { DomSanitizer } from '@angular/platform-browser';
import { HtmlConfirmModalComponent } from '../../../../components';
import { ViewEmailComponent } from '../../../profile/view-email/view-email.component';
import { add } from 'lodash';
@Component({
    selector: 'app-gift-template',
    templateUrl: './gift-template.component.html',
    styleUrls: ['./gift-template.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GiftTemplateComponent extends BaseComponent implements OnInit, OnDestroy {
    @Input() tab?: any;
    @Input() mainTemplate!: MainTemplate;
    @Input() pageLinkIndex!: number;
    @Input() selectTabIndex!: number;

    public isLoading = false;
    public amountValue = 0;
    public keyboardButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    public get playerProfile() {
      return this.profileService.playerProfile;
    }

    public get isEmptyConversionRate() {
      const { rateFrom } = this.getConversioRate();
      return !rateFrom;
    }

    constructor(
        public readonly styleService: StyleService,
        public readonly profileService: ProfileService,
        private readonly logService: LogService,
        private readonly snackBar: MatSnackBar,
        public dataPointService: DataPointService,
        private readonly integrationService: IntegrationService,
        private readonly dialog: MatDialog,
        private readonly sanitizer: DomSanitizer,
        protected override readonly injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit(): void {
    }

    public getSourceBucketUcValue(value: string) {
      /**TODO: Would not be working */
      if (value.includes('a_Points.amount') || value.includes('PlayerAccountBalance') || value === 'playerBalance') {
        return 'pointBalance';
      }
      return value;
    }

    public getButtonStyle(v: any, tab: any, isRemoveButton: boolean) {
      const conversionRate = this.getConversioRate();
      const isConversionRateExists =
        !!conversionRate.rateFrom && !!conversionRate.rateTo;
      let buttonStyleState: string = '';
      if (isRemoveButton) {
        buttonStyleState =
          Number(this.amountValue) > 0 &&
            Number(this.amountValue) - Number(v) >= 0 &&
            isConversionRateExists
            ? 'removeButton'
            : 'disabledremoveButton';
      } else {
        buttonStyleState =
          !this.isValueMoreThanBalance(Number(v)) &&
            isConversionRateExists
            ? 'addButton'
            : 'disabledAddButton';
      }
      return this.styleService.getStyleRule(
        [tab.configurations.preSets[buttonStyleState].text],
        [
          tab.configurations.preSets[buttonStyleState].background,
          {
            color:
              this.mainTemplate.globalVariables.buttonsColor[
              !isRemoveButton ? 'primaryColor' : 'secondaryColor'
              ],
          },
        ],
        [tab.configurations.preSets[buttonStyleState].border]
      );
    }

    public needPointForGetDollars(dollars: number) {
      const { pointsNeeded, accountBalance } = this.calculateNeededPointsToRedeem(dollars);

      return pointsNeeded;
    }

    public getRedemptionInformationHtml(html: string) {
      if (!html) {
        return;
      }
      const valuesToChange = [
        {
          templateVariable: '{{conversionRateFrom}}',
          templateVariableValue: this.getTierRate().rateFrom,
        },
        {
          templateVariable: '{{conversionRateTo}}',
          templateVariableValue: this.getTierRate().rateTo,
        },
      ];
      valuesToChange.forEach((value) => {
        html = html.replace(
          value.templateVariable,
          String(value.templateVariableValue)
        );
      });
      this.appendRedemptionStyles();
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    public getTierRate() {
      if (this.isEmptyConversionRate) {
        return { rateFrom: 'N/A', rateTo: 'N/A' };
      }
      const { rateFrom, rateTo } = this.getConversioRate();
      return { rateFrom, rateTo };
    }

    public additionAmount(value: string) {
      if (this.isEmptyConversionRate) {
        return;
      }
      if (this.isValueMoreThanBalance(Number(value))) return;
      this.amountValue =
        Number(this.amountValue) + Number(value);
    }

    public substractValue(value: string) {
      if (this.amountValue - Number(value) < 0) {
        return;
      }
      this.amountValue -= Number(value);
    }

    private isValueMoreThanBalance(value: number) {
      const accountBalance =
        this.profileService.playerAccountPointBalance;
      return (
        this.calculateNeededPointsToRedeem(
          Number(this.amountValue) + value
        ).pointsNeeded > accountBalance
      );
    }

    public addMaxAmount() {
      if (this.isEmptyConversionRate) {
        return;
      }
  
      const { rateFrom, rateTo } = this.getConversioRate();
      const pointsRatio = rateFrom / rateTo;
      if (isNaN(pointsRatio)) {
        return;
      }
      const maxAmount = Math.floor(
        this.profileService.playerAccountPointBalance /
        pointsRatio
      );
      this.amountValue = maxAmount;
    }

    public openConfirmation(): void {
      if (!this.amountValue) return;

      if (this.amountValue < this.tab.templateConfig.minValue) {
        return this.openInfoBlock(
          this.tab.templateConfig.ivalidMinAmountTitle,
          this.tab.templateConfig.ivalidMinAmountText,
          '.content.info-modal-content .message-text {color: #F14823;}'
        );
      }

      if (this.amountValue > this.tab.templateConfig.maxValue) {
        return this.openInfoBlock(
          this.tab.templateConfig.ivalidMaxAmountTitle,
          this.tab.templateConfig.ivalidMaxAmountText,
          '.content.info-modal-content .message-text {color: #F14823;}'
        );
      }

      if (!this.playerProfile?.emails?.[0]?.address?.length && !this.tab.templateConfig.canEditEmail) {
        return this.openInfoBlock(
          this.tab.templateConfig.missingEmailTitle,
          this.tab.templateConfig.missingEmailText,
          '.content.info-modal-content .message-text {color: #F14823;}',
        );
      }

      if (this.tab.templateConfig.canEditEmail) {
        const dialogRef = this.dialog.open(ViewEmailComponent, {
          panelClass: 'profile-dialog',
          backdropClass: 'backDropClass',
          data: {
            currentPage: this.mainTemplate.template.pageLinks[this.pageLinkIndex],
            tabData: this.tab,
            propertyName: 'email',
            additionalTitle: 'Confirm email',
            additionalText: 'Enter an email address where your gift card will be sent.',
          },
        });

        if (this.profileService.isUcSecondVersion) {
          dialogRef.afterClosed().pipe(filter(Boolean), take(1)).subscribe((clonedProfile) => {
            this.isLoading = false;
            this.openConfirmDialog(clonedProfile);
          }, () => {
            this.isLoading = false;
          });
        } else {
          dialogRef
            .afterClosed()
            .pipe(
              filter((cloneProfile: any | null) => {
                return cloneProfile !== null;
              }),
              take(1)
            )
            .subscribe((clonedProfile) => {
              this.openConfirmDialog(clonedProfile);
              this.isLoading = false;
            },
            () => {
              this.isLoading = false;
            });
        }

        return;
      }

      this.openConfirmDialog();
    }

    private openConfirmDialog(clonedProfile?: any): void {
      this.dialog.open(HtmlConfirmModalComponent, {
        panelClass: 'profile-dialog',
        backdropClass: 'backDropClass',
        width: '100vw',
        data: {
          callback: async () => {
            await this.adjustingRedemptionValues(clonedProfile);
          },
          html: this.tab.templateConfig.confirmTextHtml,
          css: this.tab.templateConfig.confirmTextCss,
          profile: clonedProfile,
          styles: (
            this.mainTemplate.template.pageLinks[this.pageLinkIndex]
              .templateSettings[this.selectTabIndex]
              .configurations as unknown as RedemptionsConfigModel
          ).popUp,
          mainTemplate: this.mainTemplate,
        },
      });
    }

    private openInfoBlock(title: string, text: string, css: string, callback?: Function): void {
      this.dialog.open(InfoModalComponent, {
        panelClass: 'profile-dialog',
        backdropClass: 'backDropClass',
        width: '100vw',
        data: {
          callback: async () => {
            if (callback) {
              await callback();
            }
          },
          title,
          text,
          css,
          styles: (
            this.mainTemplate.template.pageLinks[this.pageLinkIndex]
              .templateSettings[this.selectTabIndex]
              .configurations as unknown as RedemptionsConfigModel
          ).popUp,
          mainTemplate: this.mainTemplate,
        },
      });
    }

    public clearAmount(): void {
        this.amountValue = 0;
    }

    public keyboardBackspace() {
      const redemptionAmountValueArr = this.amountValue
        .toString()
        .split('');
      this.amountValue = Number(
        redemptionAmountValueArr.slice(0, -1).join('')
      );
    }

    public addValue(value: number) {
      if (this.isEmptyConversionRate) {
        return;
      }
      const pointsNedded = this.calculateNeededPointsToRedeem(
        Number(this.amountValue.toString() + value)
      ).pointsNeeded;
      if (isNaN(pointsNedded)) {
        return;
      }
      const isValueMoreThanPoints =
        pointsNedded >
        this.profileService.playerAccountPointBalance;
      if (isValueMoreThanPoints) {
        this.addMaxAmount();
        return;
      }
      this.amountValue = Number(
        this.amountValue.toString() + value
      );
    }

    public currencyInputChanged(value: string) {
      var num = value.replace(/[$,]/g, '');
      this.amountValue = Number(num);
    }

    public calculatePointsToRedeem(): number {
      const { pointsNeeded, accountBalance } = this.calculateNeededPointsToRedeem(
        this.amountValue
      );
      if (pointsNeeded > accountBalance) {
        return 0;
      }
      return pointsNeeded;
    }

    private calculateNeededPointsToRedeem(dollarsValue: number) {
  
      if (this.isEmptyConversionRate) {
        return {
          pointsNeeded: 0,
          accountBalance:
            this.profileService.playerAccountPointBalance,
        };
      }
  
      const { rateFrom, rateTo } = this.getConversioRate();
      const accountBalance = this.profileService.playerAccountPointBalance;
      const pointsRatio = rateFrom / rateTo;
      const pointsNeeded = dollarsValue * pointsRatio;
      return {
  
        pointsNeeded: Math.ceil(pointsNeeded),
        accountBalance,
      };
    }

    private getCurrentTierLevel() {
      if (this.mainTemplate.tierLevels.isCmsValueInUse) {
        return;
      };
      const tiers = this.mainTemplate.tierLevels.tiers;
      const lastTier = tiers.at(-1);
      /**TODO find a way to provide correct value */
      const playerPointsBalance = Number(this.profileService.fallBackDataPointValues().pointBalance?.replaceAll(',', '') ?? 0);
      if (lastTier && (lastTier.to === 0 || !lastTier) || playerPointsBalance > (lastTier?.to ?? 0)) {
        return lastTier;
      }
      const currentTierIndex = tiers.findIndex((tier, index) => {
        if (tier.to === 0 || isNaN(tier.to) && (index === tiers.length - 1)) {
          tier.to = 9999999999;
        }
        return tier.from <= playerPointsBalance && playerPointsBalance <= tier.to;
      });
      if (currentTierIndex !== -1) {
        return tiers[currentTierIndex];
      }
    }

    private async adjustingRedemptionValues(clonedProfile?: any) {
      const config = this.tab.templateConfig;
      const self = this;
    
      this.isLoading = true;
    
      const onBadRequest = (msg: string) => {
        self.amountValue = 0;
        self.isLoading = false;
        self.logService.writeLog(msg);
        self.snackBar.openFromComponent(SnackbarComponent, {
          panelClass: 'error',
          duration: 5000,
          horizontalPosition: 'end',
          data: {
            type: 'error',
            message: msg,
          },
        });
      };
    
      try {
        const deductionAmount = self.calculatePointsToRedeem();
        const adjRes = await self.profileService.doBalanceAdjustment(
          self.playerProfile?.playerId,
          deductionAmount,
          'Points',
          'D'
        );
    
        if (adjRes.error || adjRes.status) {
          onBadRequest(adjRes.status || 'Balance deduction failed.');
          return;
        }
    
        const giftCardRes = await firstValueFrom(
          self.integrationService.createTangoGiftCard(
            config.accountIdentifier,
            config.utid,
            self.amountValue,
            clonedProfile?.clonedProfile?.emails?.[0]?.address ?? clonedProfile?.emails?.[0]?.address ?? self.playerProfile.emails[0].address,
            self.playerProfile?.playerProfile?.firstName ?? self.playerProfile?.name?.first,
            self.playerProfile?.playerProfile?.lastName ?? self.playerProfile?.name?.last,
          )
        );
    
        if (self.profileService.isUcSecondVersion && self.profileService.playerBalances?.a_Points.amount !== undefined) {
          self.profileService.playerBalances.a_Points.amount -= deductionAmount;
        } else if (self.playerProfile.gamingInfo?.PlayerAccountBalance !== undefined) {
          self.playerProfile.gamingInfo.PlayerAccountBalance -= deductionAmount;
        }
    
        self.amountValue = 0;
    
        await self.profileService.getPlayerBalance(self.playerProfile.playerId);
        self.isLoading = false;
    
        self.dialog.open(GiftCardSuccessComponent, {
          panelClass: 'profile-dialog',
          backdropClass: 'backDropClass',
          width: '100vw',
          data: {
            mainTemplate: self.mainTemplate,
            html: self.tab.templateConfig.doneTextHtml,
            css: self.tab.templateConfig.doneTextCss,
            tab: self.tab,
            orderImageUrl: giftCardRes.url,
            orderId: giftCardRes.referenceOrderID,
            styles: (
              self.mainTemplate.template.pageLinks[self.pageLinkIndex]
                .templateSettings[self.selectTabIndex]
                .configurations as any
            ).popUp,
          },
        });
      } catch (error) {
        try {
          const refundRes = await self.profileService.doBalanceAdjustment(
            self.playerProfile?.playerId,
            self.calculatePointsToRedeem(),
            'Points',
            'C'
          );
    
          if (refundRes.error || refundRes.status) {
            onBadRequest('Failed to create gift card and refund the balance. Please contact support.');
          } else {
            onBadRequest('Failed to create gift card. Your balance has been refunded.');
          }
        } catch (refundError) {
          onBadRequest('Failed to create gift card and refund the balance. Please contact support.');
        } finally {
          self.isLoading = false;
        }
      }
    }

    private getConversioRate() {
      const page =
        this.mainTemplate.template.pageLinks[this.pageLinkIndex].templateSettings[
        this.selectTabIndex
        ];
      const tierLevelRate = this.getCurrentTierLevel();
      if (!tierLevelRate && !this.mainTemplate.tierLevels.isCmsValueInUse) {
        return { rateFrom: 0, rateTo: 0 };
      }
      const templateConfig: RedemptionTemplateConfigModel =
        page.templateConfig as any;
      const playerTierLevel = this.profileService.isUcSecondVersion ? this.playerProfile.club.currentClub : this.playerProfile.gamingInfo.tierLevel;
      if (
        templateConfig?.conversionRate === 'tiered' &&
        (tierLevelRate?.status || (tierLevelRate?.from && tierLevelRate?.to))
      ) {
        const rate = (templateConfig.tiredConversionRate as TierLevels[]).find(
          (tier) => tier.guid === tierLevelRate.guid
        );
        if (rate) {
          return {
            rateFrom: Number(rate.rateFrom || 0),
            rateTo: Number(rate.rateTo || 0),
          };
        }
        return { rateFrom: 0, rateTo: 0 };
      }
      if (templateConfig?.conversionRate === 'tiered') {
        const rateConfig = (
          templateConfig.tiredConversionRate as TierLevels[]
        ).find(
          (tier) =>
            tier.tierLevel === playerTierLevel.id ||
            tier.tierLevel === playerTierLevel.name
        );
        if (rateConfig) {
          return {
            rateFrom: Number(rateConfig.rateFrom),
            rateTo: Number(rateConfig.rateTo),
          };
        }
        return { rateFrom: 0, rateTo: 0 };
      }
      return {
        rateFrom: Number(templateConfig.rateFrom),
        rateTo: Number(templateConfig.rateTo),
      };
    }

    private appendRedemptionStyles() {
      if (document.getElementById('redemptionStyles')) {
        return;
      }
      if (this.styleService.isQt3Kiosk) {
        if ((this.mainTemplate.template.pageLinks[this.pageLinkIndex]
          .templateSettings[this.selectTabIndex] as any)?.templateConfig?.description?.css) {
          (this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex] as any).templateConfig.description.css =
            this.styleService.dividePixelStylesByTwo((this.mainTemplate.template.pageLinks[this.pageLinkIndex].templateSettings[this.selectTabIndex] as any)?.templateConfig?.description?.css)
        }
      }
      const styles = (this.mainTemplate.template.pageLinks[this.pageLinkIndex]
        .templateSettings[this.selectTabIndex] as any)?.templateConfig?.description?.css;
  
      const body = document.getElementsByTagName('body')[0];
      const style = document.createElement('style');
      style.id = 'redemptionStyles';
      style.type = 'text/css';
      style.appendChild(document.createTextNode(styles));
      body.appendChild(style);
    }
}