import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class IntegrationService {
    public apiUrl = environment.apiUrl;
    public baseUrl = `${this.apiUrl}/api/Integration`;

    constructor(
        private readonly http: HttpClient,
    ) {}

    public createTangoGiftCard(
        accountIdentifier: string,
        utid: string,
        amount: number,
        email: string,
        firstName: string,
        lastName: string,
    ): Observable<TangoGiftCardResponse> {
        const url = `${this.baseUrl}/tango-api/create-card`;

        return this.http.post<TangoGiftCardResponse>(url, {
            accountIdentifier,
            utid,
            amount,
            sendEmail: true,
            recipient: {
                email: email,
                firstName: firstName,
                lastName: lastName,
            }
        });
    }
}

export interface TangoGiftCardResponse {
    referenceOrderID: string;
    purchaseOrderNumber: string;
    url: string;
    externalRefID: string;
}