<div class="redemption-container" *ngIf="!isLoading; else loaderTemplate">
  <div class="content-wrapper">
    <div class="redemption-information">
      <div class="data-point-wrapper">
        <div
          class="data-point-container"
          [ngStyle]="
            styleService.getStyleRule(
              undefined,
              [
                tab.configurations.dataPoints.background,
                'undefined',
                'undefined'
              ],
              [tab.configurations.dataPoints.border, 'undefined', 'undefined']
            )
          "
        >
          <span
            class="title"
            [ngStyle]="
              styleService.getStyleRule([
                tab.configurations.dataPoints.titlePoints,
                null,
                'heading6'
              ])
            "
            >{{
              tab.templateConfig.sourceBucketTitle
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.dataPoints.titlePoints
                    )
            }}</span
          >
          <span
            class="value"
            [ngStyle]="
              styleService.getStyleRule([
                tab.configurations.dataPoints.value,
                null,
                'heading5'
              ])
            "
            >{{
              dataPointService.getValueByDataPointProperty(
                getSourceBucketUcValue(tab.templateConfig.sourceBucket)
              )
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.dataPoints.value
                    )
            }}</span
          >
        </div>
        <div
          *ngIf="getConversionType()?.value === 'freePlay'"
          class="data-point-container"
          [ngStyle]="
            styleService.getStyleRule(
              undefined,
              [
                tab.configurations.dataPoints.background,
                'undefined',
                'undefined'
              ],
              [tab.configurations.dataPoints.border, 'undefined', 'undefined']
            )
          "
        >
          <span
            class="title"
            [ngStyle]="
              styleService.getStyleRule([
                tab.configurations.dataPoints.titlePoints,
                null,
                'heading6'
              ])
            "
            >{{
              tab.templateConfig.conversionTypeTitle
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.dataPoints.titlePoints
                    )
            }}</span
          >
          <span
            class="value"
            [ngStyle]="
              styleService.getStyleRule([
                tab.configurations.dataPoints.value,
                null,
                'heading5'
              ])
            "
            >{{
              getConversionType()?.value === "freePlay"
                ? (profileService.playerBalances.xtraCreditBalanceLocal
                  | currency : "USD" : "symbol")
                : ""
            }}
          </span>
        </div>
      </div>
      <div
        class="redemption-html-tmpl"
        [ngStyle]="{
          border: !!tab?.templateConfig?.description?.showHtmlBoxBorder
            ? ''
            : 'unset'
        }"
        [innerHtml]="
          getRedemptionInformationHtml(tab?.templateConfig?.description?.html)
        "
      ></div>
    </div>
    <div class="redemptions-content">
      <ng-container
        *ngIf="tab.templateConfig.buttonsOption === 'preSets'; else keyButton"
      >
        <div class="amount-container">
          <div
            class="col-12"
            appInputStyles
            [inputStyle]="
              styleService.mapInputStyles(
                tab.configurations.preSets.inputAmount
              )
            "
          >
            <label class="label">Enter an amount</label>
            <input
              type="text"
              [ngModel]="redemptionAmountValue | currency : 'USD' : 'symbol'"
              (ngModelChange)="currencyInputChanged($event)"
              class="input"
              placeholder="$0.00"
            />
          </div>
        </div>
        <div class="amount-explanation">
          <h4
            [ngStyle]="
              styleService.getStyleRule([
                tab.configurations.main?.amountExplanation?.number,
                null,
                'heading4'
              ])
            "
          >
            {{ this.calculatePointsToRedeem() }}
          </h4>
          <h5
            [ngStyle]="
              styleService.getStyleRule([
                tab.configurations.main?.amountExplanation?.text,
                null,
                'heading5'
              ])
            "
          >
            {{
              tab?.templateConfig?.explanation?.text
                | conditionalUppercase
                  : styleService.getUppercaseValue(
                      tab.configurations.main?.amountExplanation?.text
                    )
            }}
          </h5>
        </div>
        <div
          class="presets-wrapper"
          *ngIf="tab.templateConfig.buttonsOption === 'preSets'"
        >
          <div class="preset" *ngFor="let preset of tab.templateConfig.preSets">
            <button
              class="remove"
              (click)="substractValue(preset.value)"
              [ngStyle]="getButtonStyle(preset.value, tab, true)"
            >
              -
            </button>
            <div
              class="value"
              [ngStyle]="
                styleService.getStyleRule(
                  [
                    tab.configurations.preSets.amountButton.text,
                    null,
                    'heading3'
                  ],
                  [tab.configurations.preSets.amountButton.background, null],
                  [tab.configurations.preSets.amountButton.border]
                )
              "
            >
              $ {{ preset.value }}
            </div>
            <button
              class="add"
              (click)="additionRedemptionAmount(preset.value)"
              [ngStyle]="getButtonStyle(preset.value, tab, false)"
            >
              +
            </button>
          </div>
          <button
            class="max-amount"
            (click)="addMaxAmount()"
            [ngStyle]="
              styleService.getStyleRule(
                [
                  tab.configurations.preSets.maxAmountButton.text,
                  null,
                  'heading4'
                ],
                [
                  tab.configurations.preSets.maxAmountButton.background,
                  {
                    color:
                      mainTemplate.globalVariables.buttonsColor.primaryColor
                  }
                ],
                [tab.configurations.preSets.maxAmountButton.border]
              )
            "
          >
            Max Amount
          </button>
        </div>
      </ng-container>
      <ng-template #keyButton>
        <div class="keyboard-wrapper">
          <div
            class="col-12"
            appInputStyles
            [inputStyle]="
              styleService.mapInputStyles(
                tab.configurations.freeEntry.inputAmount
              )
            "
          >
            <label class="label">Enter an amount</label>
            <input
              type="text"
              [ngModel]="redemptionAmountValue | currency : 'USD' : 'symbol'"
              (ngModelChange)="currencyInputChanged($event)"
              class="input"
              placeholder="$0.00"
            />
          </div>
          <div class="amount-explanation">
            <h4
              [ngStyle]="
                styleService.getStyleRule([
                  tab.configurations.main?.amountExplanation?.number,
                  null,
                  'heading4'
                ])
              "
            >
              {{ this.calculatePointsToRedeem() | number }}
            </h4>
            <h5
              [ngStyle]="
                styleService.getStyleRule([
                  tab.configurations.main?.amountExplanation?.text,
                  null,
                  'heading5'
                ])
              "
            >
              {{
                tab?.templateConfig?.explanation?.text
                  | conditionalUppercase
                    : styleService.getUppercaseValue(
                        tab.configurations.main?.amountExplanation?.text
                      )
              }}
            </h5>
          </div>
          <div class="keyboard-container">
            <button
              class="keyboard-button"
              *ngFor="let item of redemptionKeyboardButtons"
              (click)="addRedemptionValue(item)"
              [ngStyle]="
                styleService.getStyleRule(
                  [tab.configurations.freeEntry.numeralButton.text],
                  [
                    tab.configurations.freeEntry.numeralButton.background,
                    {
                      color:
                        mainTemplate.globalVariables.buttonsColor.secondaryColor
                    }
                  ],
                  [tab.configurations.freeEntry.numeralButton.border]
                )
              "
            >
              {{ item }}
            </button>
            <div
              class="keyboard-button remove"
              [ngStyle]="
                styleService.getStyleRule(
                  [tab.configurations.freeEntry.deleteButton.text],
                  [
                    tab.configurations.freeEntry.deleteButton.background,
                    {
                      color:
                        mainTemplate.globalVariables.buttonsColor.secondaryColor
                    }
                  ],
                  [tab.configurations.freeEntry.deleteButton.border]
                )
              "
              (click)="keyboardBackspace()"
            >
              <!--  -->
              <mat-icon
                aria-hidden="false"
                [fontIcon]="
                  tab.configurations.freeEntry?.deleteButtonIcon?.iconName ||
                  'backspace_outline'
                "
                [ngStyle]="
                  styleService.getIconStyleRule(
                    [
                      tab.configurations.freeEntry?.deleteButtonIcon?.iconSize,
                      ''
                    ],
                    [
                      '',
                      tab.configurations.freeEntry?.deleteButtonIcon?.iconColor
                    ]
                  )
                "
              ></mat-icon>
            </div>
            <button
              class="keyboard-button"
              [ngStyle]="
                styleService.getStyleRule(
                  [tab.configurations.freeEntry.numeralButton.text],
                  [
                    tab.configurations.freeEntry.numeralButton.background,
                    {
                      color:
                        mainTemplate.globalVariables.buttonsColor.secondaryColor
                    }
                  ],
                  [tab.configurations.freeEntry.numeralButton.border]
                )
              "
              (click)="addRedemptionValue(0)"
            >
              0
            </button>
            <div
              class="keyboard-button confirm"
              [ngStyle]="
                styleService.getStyleRule(
                  [tab.configurations.freeEntry.confirmationButton.text],
                  [
                    tab.configurations.freeEntry.confirmationButton.background,
                    {
                      color:
                        mainTemplate.globalVariables.buttonsColor.secondaryColor
                    }
                  ],
                  [tab.configurations.freeEntry.confirmationButton.border]
                )
              "
              (click)="addMaxAmount()"
            >
              MAX
            </div>
          </div>
        </div>
      </ng-template>
      <div class="help-text">
        <h5
          [ngStyle]="
            styleService.getStyleRule([
              tab.configurations.main?.explanation,
              null,
              'heading5'
            ])
          "
        >
          {{
            tab?.templateConfig?.explanation?.helpText
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    tab.configurations.main?.explanation?.helpText
                  )
          }}
        </h5>
      </div>
    </div>
  </div>

  <div class="control-buttons">
    <button
      class="confirm-button button"
      (click)="openRedeemConfirmation()"
      [ngStyle]="
        styleService.getStyleRule(
          [tab.configurations.buttons.butonsConfirm.text, null, 'heading4'],
          [
            tab.configurations.buttons.butonsConfirm.background,
            { color: mainTemplate.globalVariables.buttonsColor.primaryColor }
          ],
          [tab.configurations.buttons.butonsConfirm.border]
        )
      "
    >
      {{
        "Confirm"
          | conditionalUppercase
            : styleService.getUppercaseValue(
                tab.configurations.buttons.butonsConfirm.text
              )
      }}
    </button>
    <button
      class="cancel-button button"
      (click)="clearRedemptionAmount()"
      [ngStyle]="
        styleService.getStyleRule(
          [tab.configurations.buttons.buttonsClearAll.text, null, 'heading4'],
          [
            tab.configurations.buttons.buttonsClearAll.background,
            {
              color: mainTemplate?.globalVariables?.buttonsColor?.secondaryColor
            }
          ],
          [tab.configurations.buttons.buttonsClearAll.border]
        )
      "
    >
      {{
        "Clear All"
          | conditionalUppercase
            : styleService.getUppercaseValue(
                tab.configurations.buttons.buttonsClearAll.text
              )
      }}
    </button>
  </div>
</div>

<ng-template #loaderTemplate>
  <div class="loader" *ngIf="isLoading">
    <mat-spinner
      *ngIf="!mainTemplate.globalVariables.loader?.imageLink?.length"
      [color]="mainTemplate.globalVariables.backgroundPrimary.color"
      [diameter]="styleService.isQt3Kiosk ? 100 : 200"
    ></mat-spinner>
    <img
      class="loader-image"
      *ngIf="mainTemplate.globalVariables.loader?.imageLink?.length"
      [src]="mainTemplate.globalVariables.loader.imageLink"
    />
  </div>
</ng-template>
