<div
  class="content"
  [ngStyle]="
    styleService.getStyleRule(undefined, [
      tabData.configurations.main.background,
      mainTemplate.globalVariables.backgroundPrimary
    ])
  "
>
  <div class="header">
    <div class="header-block">
      <h1
        class="header-title"
        [ngStyle]="
          styleService.getStyleRule([
            currentPage.pageLinkContainerSettings.configurations.title,
            undefined,
            'heading3'
          ])
        "
      >
        <a class="header-title-back" (click)="closeModal()">
          <img
            src="/assets/images/last/back.svg"
            class="header-title-back-icon"
          />
        </a>
        {{
          "Email"
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  currentPage.pageLinkContainerSettings.configurations.title
                )
        }}
      </h1>
    </div>
  </div>

  <h1 class="email-additional-title" *ngIf="additionalTitle">
    {{ additionalTitle }}
  </h1>
  <p class="email-additional-text" *ngIf="additionalText">
    {{ additionalText }}
  </p>

  <div class="main">
    <div class="row">
      <div class="col-6">
        <span
          [ngStyle]="
            styleService.getStyleRule([
              tabData.configurations.inputs?.title,
              undefined,
              'heading4'
            ])
          "
          >{{
            "Email Address"
              | conditionalUppercase
                : styleService.getUppercaseValue(
                    tabData.configurations.inputs?.title
                  )
          }}</span
        >
        <input
          type="text"
          [(ngModel)]="emails[getMainEmailIndex()].address"
          class="input"
          placeholder="Placeholder"
          kioskInput
        />
      </div>
      <div
        class="row"
        *ngIf="
          this.profileService.isUcSecondVersion &&
          tabData.templateConfig?.emailOptOut
        "
      >
        <div class="col-6">
          <div class="opt-out-container">
            <div
              class="checkbox-container"
              (click)="setOptOutValue()"
              [ngClass]="{
                'checked ': emails[getMainEmailIndex()].sendTo === 'false'
              }"
              [ngStyle]="
                getCheckboxContainerStyles(
                  emails[getMainEmailIndex()].sendTo === 'false'
                )
              "
            >
              <div
                class="checkmark"
                [ngStyle]="{
                  'border-color': optOutConfigurations?.checkmarkColor
                }"
              ></div>
            </div>
            <div class="opt-out-text">
              <span
                [ngStyle]="
                  styleService.getStyleRule([
                    optOutConfigurations?.text,
                    undefined,
                    'heading4'
                  ])
                "
                >{{
                  (tabData.templateConfig.email.optOutEmailText
                    ? tabData.templateConfig.email.optOutEmailText
                    : "Opt-out of email marketing"
                  )
                    | conditionalUppercase
                      : styleService.getUppercaseValue(
                          optOutConfigurations?.text
                        )
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <kiosk-keyboard [isAlwaysShown]="true"></kiosk-keyboard>
    </div>

    <div class="footer">
      <button
        [disabled]="!isValidEmail"
        (click)="checkValidate()"
        [ngStyle]="
          styleService.getStyleRule(
            [tabData.configurations.buttons?.primaryButtons?.text],
            [
              tabData.configurations.buttons?.primaryButtons?.background,
              { color: mainTemplate.globalVariables.buttonsColor.primaryColor }
            ],
            [tabData.configurations.buttons?.primaryButtons?.border]
          )
        "
      >
        {{
          "Confirm"
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tabData.configurations.buttons?.primaryButtons?.text
                )
        }}
      </button>
      <button
        (click)="closeModal()"
        [ngStyle]="
          styleService.getStyleRule(
            [tabData.configurations.buttons?.secondaryButtons?.text],
            [
              tabData.configurations.buttons?.secondaryButtons?.background,
              {
                color: mainTemplate.globalVariables.buttonsColor.secondaryColor
              }
            ],
            [tabData.configurations.buttons?.secondaryButtons?.border]
          )
        "
      >
        {{
          "Cancel"
            | conditionalUppercase
              : styleService.getUppercaseValue(
                  tabData.configurations.buttons?.secondaryButtons?.text
                )
        }}
      </button>
    </div>
  </div>
</div>
