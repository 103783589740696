import { UcVersion } from "./app/models/template";

export const dataPointsValues: { [k: string]: any } = {
    "values": [
        {
            "title": "Account Number",
            "name": "accountNumber",
            "valueV1": "playerProfile.playerId",
            "valueV2": "playerProfile.playerId",
            "cmsAvailability": [
                "Envoy",
                "OLKG",
                "IGT",
                "Local"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Tier Points Balance",
            "name": "tierPointBalance",
            "valueV1": "",
            "valueV2": "playerBalances.a_Tier.amount",
            "cmsAvailability": [
                "Envoy",
                "OLKG",
                "IGT",
            ],
            "options": ['NumberToLocaleString'],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },

        {
            "title": "Card Number",
            "name": "cardNumber",
            "valueV1": "playerProfile.cardNumbers.cardId",
            "valueV2": "playerProfile.playerId",
            "cmsAvailability": [
                "IGT",
                "Local",
                "Envoy",
                "OLKG"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Martial Status",
            "name": "martialStatus",
            "valueV1": "",
            "valueV2": "playerProfile.playerProfile.martialStatus",
            "cmsAvailability": [
                "Envoy",
                "OLKG",
                "IGT",
            ],
            "availableAt": []
        },
        {
            "title": "Gender",
            "name": "gender",
            "valueV1": "playerProfile.gender",
            "valueV2": "playerProfile.playerProfile.gender",
            "cmsAvailability": [
                "Local",
                "Envoy",
                "OLKG",
                "IGT",
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Military Status",
            "name": "militaryStatus",
            "valueV1": "playerProfile.militaryStatus",
            "valueV2": "playerProfile.militaryStatus",
            "cmsAvailability": [
                "Local",
                "Envoy",
                "OLKG"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Profile Title",
            "name": "profileTitle",
            "valueV1": "playerProfile.name.title",
            "valueV2": "playerProfile.playerProfile.title",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "First Name",
            "name": "firstName",
            "valueV2": "playerProfile.playerProfile.firstName",
            "valueV1": "playerProfile.name.first",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Last Name",
            "name": "lastName",
            "valueV2": "playerProfile.playerProfile.lastName",
            "valueV1": "playerProfile.name.last",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Middle Name",
            "name": "middleName",
            "valueV1": "playerProfile.name.middle",
            "valueV2": "playerProfile.playerProfile.middleName",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Full Name",
            "name": "fullName",
            "valueV1": "playerProfile.name.first + \" \" + this.playerProfile.name.last",
            "valueV2": "playerProfile.playerProfile.fullName",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Preferred Name",
            "name": "prefferedName",
            "valueV1": "playerProfile.name.preferred",
            "valueV2": "playerProfile.playerProfile.preferredName",
            "value": "",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Generation",
            "name": "generation",
            "valueV1": "playerProfile.name.generation",
            "valueV2": "playerProfile.playerProfile.generation",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Email",
            "name": "email",
            "valueV1": "playerProfile.emails[0].address",
            "valueV2": "playerProfile.a_HomeEmail.address",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Mobile Phone",
            "name": "phone",
            "valueV1": "playerProfile.phoneNumbers[0].number",
            "valueV2": "playerProfile.a_HomePhone.phoneNumber",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Date of Enrollment",
            "name": "enrollmentDate",
            "valueV1": "playerProfile.gamingInfo.registeredDate",
            "valueV2": "playerProfile.playerProfile.dateOfEnrollment",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Point Balance",
            "name": "pointBalance",
            "valueV1": "playerProfile.gamingInfo.PlayerAccountBalance",
            "valueV2": "playerBalances.a_Points.amount",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "options": ['NumberToLocaleString'],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Loyalty Points Balance",
            "name": "loyaltyPointBalance",
            "valueV1": "playerProfile.gamingInfo.PlayerAccountBalance",
            "valueV2": "playerBalances.a_Points.amount",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "options": ['NumberToLocaleString'],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Tier level name",
            "name": "tierLevelName",
            "valueV1": "playerProfile.gamingInfo.tierLevel.name",
            "valueV2": "playerProfile.club.currentClub.name",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Tier level ID",
            "name": "tierLevelId",
            "valueV1": "playerProfile.gamingInfo.tierLevel.id",
            "valueV2": "playerProfile.club.currentClub.suffix",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": []
        },
        {
            "title": "Players Points Earned Today",
            "name": "pointsEarnedToday",
            "valueV1": "playerProfile.gamingInfo.pointsEarnedToday",
            "valueV2": "playerActivity.a_PointsEarned",
            "options": ['NumberToLocaleString'],
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Loyalty Points Earned Today",
            "name": "loyaltyPointsEarnedToday",
            "valueV1": "playerProfile.gamingInfo.pointsEarnedToday",
            "valueV2": "playerActivity.a_PointsEarned",
            "options": ['NumberToLocaleString'],
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Tier Points Earned Today",
            "name": "tierPointsEarnedToday",
            "valueV1": "playerProfile.gamingInfo.pointsEarnedToday",
            "valueV2": "playerActivity.a_PointsEarned",
            "options": ['NumberToLocaleString'],
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Birth Date",
            "name": "birthDate",
            "valueV1": "playerProfile.birthDate",
            "valueV2": "playerProfile.playerProfile.dateOfBirthday",
            "cmsAvailability": [
                "Local",
            ],
            "options": ['BirthDateToMonth'],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Image",
            "name": "image",
            "valueV1": "image",
            "valueV2": "image",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "availableAt": [
                "tierLevelCard"
            ]
        },
        {
            "title": "Age",
            "name": "age",
            "valueV1": "playerProfile.birthDate",
            "valueV2": "playerProfile.playerProfile.dateOfBirthday",
            "cmsAvailability": [
                "IGT",
                "Local",
                "OLKG",
                "Envoy"
            ],
            "options":['BirthDateToAge'],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Free play balance",
            "name": "freePlayBalance",
            "valueV1": "playerProfile.freePlayBalance",
            "valueV2": "playerBalances.a_FreePlay.amount",
            "options": ['NumberToLocaleString'],
            "cmsAvailability": [
                "Local",
                "Envoy",
                "OLKG",
                "IGT",
            ],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        },
        {
            "title": "Moreplay",
            "name": "moreplayBalance",
            "valueV1": "playerProfile.freePlayBalance",
            "valueV2": "playerBalances.a_FreePlay.amount",
            "cmsAvailability": [
                "Local",
                "Envoy",
                "OLKG",
                "IGT",
            ],
            "options": ['NumberToLocaleString'],
            "availableAt": [
                "welcomeMessage",
                "dataPoints",
                "additionalInfo",
                "tierLevelCard"
            ]
        }
    ]
};

export const availableCms = [
    {
        cms: 'IGT',
        label: 'IGT(v1)',
        value: '0',
        version: UcVersion.Version_1
    },
    {
        cms: 'Envoy',
        label: 'Envoy',
        value: '1',
        version: UcVersion.Version_2
    },
    {
        cms: 'Local',
        label: 'Local',
        value: '3',
        version: UcVersion.Version_1
    },
    {
        cms: 'IGT',
        label: 'IGT(v2)',
        value: '4',
        version: UcVersion.Version_2
    },
    {
        cms: 'OLKG',
        label: 'OLKG',
        value: '5',
        version: UcVersion.Version_2
    },
];

export const rewardCustomProps = [
    {
        name: 'showQR',
        defaultValue: false
    },
    {
        name: 'showPrintButton',
        defaultValue: true
    },
    {
        name: 'showRedeemButton',
        defaultValue: false
    },
    {
        name: 'rewardActionText',
        defaultValue: ''
    },
    {
        name: 'redeemOnIssue',
        defaultValue: false
    },
    {
        name: 'redeemOnPrint',
        defaultValue: false
    },
    {
        name: 'executeUcWebhookOn',
        defaultValue: 'Issue'
    },
];
