import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  BaseComponent,
  PinModalComponent,
  SnackbarComponent,
} from '../../components';
import {
  DrawingService,
  LogService,
  OfferService,
  PopUpService,
  StyleService,
  TemplateService,
} from '../../services';
import {
  Campaign,
  CouponBody,
  Drawing,
  MainTemplate,
  ModalType,
} from '../../models';
import { Observable, Subject, iif, catchError, timer, concatMap, filter, map, mergeMap, of, switchMap, take, takeUntil, tap, throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  CampaignSortingEnum,
  FilterGroup,
  PageLink,
  TabType,
  TemplateSetting,
} from '../../models/template';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { ViewNameComponent } from '../profile/view-name/view-name.component';
import { ViewNickNameComponent } from '../profile/view-nick-name/view-nick-name.component';
import { ViewEmailComponent } from '../profile/view-email/view-email.component';
import { ViewPhoneComponent } from '../profile/view-phone/view-phone.component';
import { ViewPinComponent } from '../profile/view-pin/view-pin.component';
import { ViewAddressesComponent } from '../profile/view-addresses/view-addresses.component';
import { ProfileService } from '../../services/profile.service';
import {
  ConversionType,
  RedemptionTemplateConfigModel,
  RedemptionsConfigModel,
  TierLevels,
} from '../../models/redemption-template.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmRedeemModalComponent } from '../../components/confirm-redeem/confirm-redeem-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { DataPointService } from '../../services/data-point.service';
import { CmsDrawingMerged, CmsDrawingUC } from '../../models/cmsDrawing';
import { InfoModalComponent } from './../../components/info-modal/info-modal.component';
@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TemplateComponent extends BaseComponent implements OnInit, OnDestroy {


  public get isEmptyConversionRate() {
    const { rateFrom } = this.getConversioRate();
    return !rateFrom;
  }

  public get playerProfile() {
    return this.profileService.playerProfile;
  }

  public isLoading: boolean = true;
  public accessRequestId: string = '';
  public currentPage!: PageLink;
  public mainTemplate!: MainTemplate;
  public pageLinkIndex!: number;
  public campaigns: Campaign[] | null = [];
  public cmsOffers: any = [];
  public drawings: Drawing[] | null = [];
  public selectTabIndex = 0;
  public tabType = TabType;
  public modalType = ModalType;
  public redemptionAmountValue: number = 0;
  public redemptionKeyboardButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  public conversionType = [
    { title: 'Free play', value: 'freePlay' },
    { title: 'Cash', value: 'cash' },
    { title: 'Dining', value: 'dining' },
    { title: 'Bevarage', value: 'bevarage' },
    { title: 'Race & Sports', value: 'raceAndSports' },
    { title: 'Hotel', value: 'hotel' },
    { title: 'Match Play', value: 'matchPlay' },
  ];

  public cmsDrawing: any = [];

  public bCmsDrawingDisplayModalView: any;


  private unsubscriber$: Subject<any> = new Subject();


  public selectedYear?: string;
  public years: string[] = [];
  public WLErrorMessageText = '';
  public bSubmitted = false;
  public bIsCurrentYearVisible = false;
  public bIsCurrentYearAvailable = false;
  public bError = true;
  public email?: string = '';


  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly templateService: TemplateService,
    private readonly offerService: OfferService,
    private readonly popupService: PopUpService,
    public readonly styleService: StyleService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    public readonly profileService: ProfileService,
    public dataPointService: DataPointService,
    private readonly drawingService: DrawingService,
    private readonly logService: LogService,
    private sanitizer: DomSanitizer,
    injector: Injector
  ) {
    super(injector);
    console.log('Profile', this.playerProfile);
    console.log(this.profileService.playerBalances);

    this.selectedYear = new Date().getFullYear().toString();

  }

  ngOnInit(): void {
    this.pageLinkIndex = this.activatedRoute.snapshot.params['pageLinkIndex'];

    this.email = this.getProfileEmail();

    this.templateService
      .templateData$()
      .pipe(take(1))
      .subscribe((template) => {
        this.mainTemplate = template;

        console.log(this.mainTemplate.globalVariables);
        this.currentPage =
          this.mainTemplate.template.pageLinks[this.pageLinkIndex];

        this.checkPinCode(
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex]
        );

        this.setStyle();

        this.isLoading = false;
        switch (
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex]?.templateName
        ) {
          case TabType.Offers:
            this.campaigns = null;
            this.addScrollbarStyles(this.mainTemplate);
            this.getCampaigns(
              this.mainTemplate.template.pageLinks[this.pageLinkIndex]
                .templateSettings[this.selectTabIndex]
            );
            break;
          case TabType.Drawing:
            this.drawings = null;
            this.addScrollbarStyles(this.mainTemplate);
            this.getDrawing(true);
            break;
          case TabType.OffersCms:
            this.cmsOffers = null;
            this.addScrollbarStyles(this.mainTemplate);
            this.processCmsOffers();
            break;
          case TabType.OffersAndOffersCms:
            this.cmsOffers = null;
            this.campaigns = null;
            this.addScrollbarStyles(this.mainTemplate);
            this.processCmsOffers();
            this.getCampaigns(
              this.mainTemplate.template.pageLinks[this.pageLinkIndex]
                .templateSettings[this.selectTabIndex]
            );
            break;
          case TabType.OffersAndDrawings:
            this.campaigns = null;
            this.drawings = null;
            this.addScrollbarStyles(this.mainTemplate);
            this.getDrawing(true);
            this.getCampaigns(
              this.mainTemplate.template.pageLinks[this.pageLinkIndex]
                .templateSettings[this.selectTabIndex]
            );
            break;
          case TabType.WinAndLoss:
            this.loadWinLoss();
            break;
          case TabType.DrawingCms:
            this.bCmsDrawingDisplayModalView = this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex].templateConfig.openDetailedView?.isVisible;
            this.addScrollbarStyles(this.mainTemplate);
            this.processCmsDrawing()
            break;
          case TabType.OffersCmsOffersAndDrawings:
            this.cmsOffers = null;
            this.campaigns = null;
            this.drawings = null;
            this.addScrollbarStyles(this.mainTemplate);
            this.getDrawing(true);
            this.processCmsOffers();
            this.getCampaigns(
              this.mainTemplate.template.pageLinks[this.pageLinkIndex]
                .templateSettings[this.selectTabIndex]
            );
            break;  
        }
      });

    console.log(
      'template',
      this.mainTemplate.template.pageLinks[this.pageLinkIndex].templateSettings[
        this.selectTabIndex
      ]
    );
  }

  private checkPopUp(): void {
    const welcomePopUp = this.mainTemplate.template.pageLinks[this.pageLinkIndex]
      .templateSettings[this.selectTabIndex].configurations.welcomePopUp;

    this.popupService.checkPopUp(welcomePopUp, this.pageLinkIndex, this.selectTabIndex);
  }
  
  private async loadWinLoss() {
    this.isLoading = true;
    try {
      const result = await this.profileService.getPlayerBalancesWinLossYears(
        this.playerProfile.playerId
      );
      this.years = result?.years ?? [];
      const currentYear = new Date().getFullYear().toString();
      if (this.isCurrentYearVisible() && !this.years.includes(currentYear)) {
        this.bIsCurrentYearVisible = true;
        this.bIsCurrentYearAvailable = true;
        this.years.push(currentYear);
      }
      else {
        this.selectedYear = this.years[this.years.length - 1];
      }

      this.bSubmitted = false;
      this.isLoading = false;

     if (!this.years || this.years.length === 0) {
        this.WLErrorMessageText =
          'There are no available years to select. Please contact our help desk for assistance.';
        this.bError = true;
        this.bSubmitted = true;
        return;
      }
     
    } catch (error) {
       this.isLoading = false;
      console.error('Failed to load years:', error);
    }
  }

  private isCurrentYearVisible(): boolean {
    return !!this.mainTemplate.template.pageLinks[this.pageLinkIndex]
      .templateSettings[this.selectTabIndex]?.templateConfig?.currentYear
      ?.isVisible;
  }

  public async submitWinLossStatement() {
    const strTextAfter =
      (
        this.currentPage.templateSettings[this.selectTabIndex]
          .templateConfig as any
      ).initialText.textAfter ??
      'Your Win/Loss statement has been sent to the email on file';

    const strErrorMessageAfterSubmit =
      (
        this.currentPage.templateSettings[this.selectTabIndex]
          .templateConfig as any
      ).initialText.errorMessageAfterSubmit ??
      'There was a problem processing your request. Please contact our help desk for assistance';

    this.bSubmitted = true;

  if (!this.selectedYear) {
    this.WLErrorMessageText =
    strErrorMessageAfterSubmit || 
    'No years are available to select. Please contact our Help Desk for assistance';

    this.bError = true;
    return;
  }

    if (this.isCurrentYearUnavailable() ) {
      this.WLErrorMessageText =
        strErrorMessageAfterSubmit || 
        'There is no available data for this year. Please contact our help desk for assistance.';
      this.bError = true;
      return;
    }

    try {
      const response = await this.profileService.getPlayerBalancesWinLoss(
        this.playerProfile.playerId,
        this.selectedYear || '',
        'Email'
      );

      if (response?.error) {
        this.WLErrorMessageText = strErrorMessageAfterSubmit;
        console.error('Response Error:', response.error);
        this.bError = true;
      } else {
        console.log('Response:', response.result);
        if ( response.result.toLowerCase().startsWith('fail') 
        ) {
          this.WLErrorMessageText = strErrorMessageAfterSubmit;
          this.bError = true;
        } else {
          this.bError = false;
        }
      }
    } catch (error: any) {
      this.WLErrorMessageText = error;
      this.bError = true;
      console.error('Error:', strErrorMessageAfterSubmit);
    }
  }

  private isCurrentYearUnavailable(): boolean {
    return (
      this.bIsCurrentYearAvailable &&
      this.bIsCurrentYearVisible &&
      this.selectedYear == new Date().getFullYear().toString()
    );
  }

  public processCmsOffers() {
    this.isLoading = true;
    const identifierName =
      (
        this.currentPage.templateSettings[this.selectTabIndex]
          .templateConfig as any
      ).fieldToMatch ?? 'description';
    try {
      this.offerService
        .getCmsOffer()
        .pipe(
          takeUntil(this.unsubscriber$),
          map((offers) => this.filterValidOffers(offers)),
          mergeMap((validOffers) =>
            this.fetchOfferDetails(validOffers, identifierName)
          ),
          map((response) =>
            this.mergeDetailsWithOffers(response, identifierName)
          ),
          catchError((error) => this.handleProcessingError(error))
        )
        .subscribe({
          next: (offers) => this.handleSuccess(offers),
          error: (finalError) => this.handleFinalError(finalError),
        });
    } catch (error) {
      this.handleCriticalError(error);
    }
  }

  public processCmsDrawing() {
    this.isLoading = true;
    const identifierName = (this.currentPage.templateSettings[this.selectTabIndex].templateConfig as any).fieldToMatch ?? 'accountId';
    
    this.drawingService.getCmsDrawing().pipe(
      takeUntil(this.unsubscriber$),
      map(drawing => this.filterValidDrawings(drawing)),
      mergeMap(validDrawing => this.fetchDrawingDetails(validDrawing, identifierName)),
      map(response => this.mergeDetailsWithDrawing(response, identifierName)),
      catchError(error => this.handleProcessingError(error))
    ).subscribe(
      {
        next: (drawing) => this.handleDrawingSuccess(drawing),
        error: (finalError) => this.handleFinalError(finalError)
      }
    );
  }
  

  private filterValidOffers(offers: any[]): any[] {
    if (!offers?.length) {
      return [];
    }
    const removeFromDisplay = !!(
      this.currentPage.templateSettings[this.selectTabIndex].configurations
        .main as any
    )?.redeemedOfferDisplaySettings?.removeFromDisplay;
    const filteredOffers = offers.filter(
      (offer) =>
        (offer.couponStatus === 'Issued' ||
          offer.couponStatus === 'Redeemed') &&
        !(offer.couponStatus === 'Redeemed' && removeFromDisplay)
    );
    this.cmsOffers = filteredOffers;
    return filteredOffers;
  }

  private filterValidDrawings(drawing: any[]): any[] {
    if (!drawing?.length) {
      return [];
    }; 
    //no statuses yet at this point, but leave it here for future
   //const filteredDrawing = drawing.filter(draw => (draw.drawingStatus === 'Active') );
    const filteredDrawing = drawing
    this.cmsDrawing = filteredDrawing ;
    return filteredDrawing ;
  }

  private fetchOfferDetails(offers: any[], identifierName: string): Observable<any> {

    const identifiers = Array.from(new Set(offers.map(offer => offer[identifierName])));  
    return this.offerService.getCmsOfferDetails({ identifiers }).pipe(
      
      tap(details => {
        console.log('Offer details received:', details)
      }), // Log the data received from the service
      catchError(error => {
        console.error('Error fetching offer details:', error); // Already logging errors
        return throwError(() => error);
      })
    );
    
  }

  private fetchDrawingDetails(drawings: CmsDrawingUC[], identifierName: keyof CmsDrawingUC): Observable<CmsDrawingMerged[]> {
    const identifiers = Array.from(new Set(drawings.map((draw: CmsDrawingUC) => draw[identifierName])));

    return this.drawingService.getCmsDrawingDetails({ identifiers }).pipe(
      tap(details => {
        console.log('Drawing details received:', details);
      }),
      catchError(error => {
        console.error('Error fetching drawing details:', error);
        return throwError(() => error);
      })
    );
}

  private mergeDetailsWithOffers(response: any, identifierName: string) {
    const validDecorators = response?.items?.filter((decorator: { offerDecorator: any; }) => !!decorator.offerDecorator) ?? [];
   
    if (!validDecorators.length) {
      return [];
    }
    return validDecorators.map((details: any) => {
      const foundOffers: any[] = this.cmsOffers?.filter((offer: any) => offer[identifierName] === details.identifier) ?? [];
      if (foundOffers) {
        foundOffers.forEach((value: any) => value.extendedDetails = details.offerDecorator);
      }
      return foundOffers;
    }).flat();
  }

  private mergeDetailsWithDrawing(response: any, identifierName: string) {
    const validDecorators = response?.items?.filter((decorator: { drawingDecorator: any; }) => !!decorator.drawingDecorator) ?? [];
    if (!validDecorators.length) {
      return [];
    }

    return validDecorators.map((details: any) => {
      const foundDrawing: any[] = this.cmsDrawing.filter((draw: any) => draw[identifierName] === details.identifier);    
      if (foundDrawing) {
        foundDrawing.forEach((value: any) => value.extendedDetails = details.drawingDecorator);
      }
      return foundDrawing;
    }).flat();
  }

  private handleProcessingError(error: any): Observable<never> {
    console.error('Error processing offers:', error);
    return throwError(() => error);
  }

  private handleSuccess(offers: any[]): void {
    const sortedOffers = offers.sort(
      (a, b) => (new Date(a.startDate) as any) - (new Date(b.startDate) as any)
    );
    this.cmsOffers = sortedOffers;
    this.isLoading = false;
  }

  private handleDrawingSuccess(drawings: any[]): void {
    const sortedDrawings = drawings.sort((a, b) => (new Date(a.startDate) as any) - (new Date(b.startDate) as any))
    this.cmsDrawing = sortedDrawings;
    this.isLoading = false;
  }

  private handleFinalError(finalError: any): void {
    console.error('Final error:', finalError);
    this.isLoading = false;
  }

  private handleCriticalError(criticalError: any): void {
    console.error('Critical error in processing CMS offers:', criticalError);
    this.isLoading = false;
  }

  public isDrawingListHidden(tab: any): boolean {
    const displaySettings = tab.templateConfig?.displaySettings;
    if (!displaySettings) {
      return false;
    }
    return (
      !displaySettings?.showFreeEntries &&
      !displaySettings?.showDrawings &&
      !displaySettings?.showMultipliers
    );
  }

  public get isOnlyDisablesMultipliers(): boolean {
    return !!this.drawings?.every((x) => !x.drawingMultiplyEntries.length);
  }

  public updateWebpageUrl(url: string) {
    if (!url) {
      return '';
    }
    const paramRegexp = /\{[^}]*\}/g;
    const matchPatronId = (param: string) => {
      return param === '{patronId}' ? this.playerProfile.playerId : '';
    };
    return url.replace(paramRegexp, matchPatronId);
  }

  public getPretifiedAddress(address: any): string {
    if (!address) {
      return '';
    }
    let availableValues: string[] = [];
    if (this.profileService.isUcSecondVersion) {
      availableValues = [
        'lineOne',
        'lineTwo',
        'lineThree',
        'city',
        'state',
        'postCode',
      ];
    } else {
      availableValues = ['address1', 'address2', 'city', 'state', 'zipCode'];
    }
    const filteredAddressValues = Object.entries(address)
      .map(([key, value]) => ({ key, value }))
      .filter((entry) => !!entry.value && availableValues.includes(entry.key));
    const addressOnlyValues = filteredAddressValues.map((entry) =>
      (entry.value as string).trim()
    );
    return addressOnlyValues.join(', ');
  }

  public getPreferedAddresses(addresses: any[]): any[] {
    return addresses.reduce(
      (preferredAddress: any[], currentAddress: any, index: number) => {
        if (!index) return preferredAddress;

        if (currentAddress.preferred) {
          preferredAddress.push(currentAddress);
        }

        return preferredAddress;
      },
      []
    );
  }

  private getDrawing(showPopUp = false): void {
    this.isLoading = true;

    
      this.drawingService.getListLiveDrawings().pipe(
        concatMap((drawings) => {
          return iif(
            () => this.profileService.isUcSecondVersion,
            this.drawingService.getUCDrawingBalance(this.playerProfile?.playerId).pipe(
              catchError(() => of({ drawings, balance: []})),
              map(balances => { 
                return { drawings, balances };
              })
            ),
            of({drawings, balances: []})
          );
        }), 
        takeUntil(this.unsubscriber$)
      )
      .subscribe({
        next: ({drawings, balances}) => {
          this.isLoading = false;
          
          console.log('drawings', drawings);
          this.drawings = drawings;

          (balances as any[])?.forEach((balance) => {
            const findDrawing = this.drawings?.find(x => x.drawingId === balance.ofcDrawingId);

            if (findDrawing) {
              findDrawing.ucBalance = parseFloat(balance.awardAutoClaimTierEntries) ?? 0;
            }
          });
          
          if (showPopUp) {
            this.checkPopUp();
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.drawings = [];
          this.logService.writeLog(JSON.stringify(err));
        },
      });
  }

  public updateOffers(): void {
    this.getCampaigns(
      this.mainTemplate.template.pageLinks[this.pageLinkIndex].templateSettings[
        this.selectTabIndex
      ],
      true
    );
  }

  public getSourceBucketUcValue(value: string) {
    /**TODO: Would not be working */
    if (
      value.includes('a_Points.amount') ||
      value.includes('PlayerAccountBalance') ||
      value === 'playerBalance'
    ) {
      return 'pointBalance';
    }
    return value;
  }

  public hasCampaignProhibitedRewardState(campaign: Campaign): boolean {
    const prohibitedStates = ['Redeemed', 'Expired'];
    if (!campaign.knownReward || !campaign.knownReward?.rewardStates) {
      return false;
    }
    return campaign.knownReward.rewardStates?.some((state) =>
      prohibitedStates.includes(state)
    );
  }

  private getCampaigns(templateSetting: TemplateSetting, hasDelay?: boolean): void {
    this.isLoading = true;

    timer(hasDelay ? 300 : 0).pipe(
        switchMap(() => this.offerService.getCampaigns(templateSetting)),
        takeUntil(this.unsubscriber$)
      ).subscribe({
        next: (couponBody) => {
          this.handleCouponbookBody(couponBody, templateSetting);
        },
        error: (err) => {
          this.isLoading = false;
          this.campaigns = [];

          this.logService.writeLog(JSON.stringify(err));
          this.snackBar.openFromComponent(SnackbarComponent, {
            panelClass: 'error',
            duration: 5000,
            horizontalPosition: 'end',
            data: {
              type: 'error',
              message: 'Offers unavailable',
            },
          });
        },
      });
  }

  public get showOfferEmptyMessage(): boolean {
    return (
      !!this.campaigns &&
      (!this.campaigns?.length ||
        this.campaigns?.every((camp) => {
          return this.hasCampaignProhibitedRewardState(camp);
        }))
    );
  }

  private handleCouponbookBody(
    couponBody: CouponBody,
    templateSetting: TemplateSetting
  ) {
    this.isLoading = false;
    if (couponBody.message) {
      this.logService.writeLog(couponBody.message);
    }

    this.updateCampaigns(this.filterByStatus(couponBody, templateSetting));
  }

  private filterByStatus(
    couponBody: CouponBody,
    templateSetting: TemplateSetting
  ) {
    if (
      templateSetting.templateConfig.sorting == CampaignSortingEnum.UpcomingOnly
    ) {
      return {
        ...couponBody,
        campaigns: couponBody.campaigns.filter(
          (campaign) => campaign.status === 'Approved'
        ),
      };
    }
    return couponBody;
  }

  private setStyle(): void {
    setTimeout(() => {
      const templateContainer = document.getElementsByClassName(
        'main-template-container'
      )[0];
      const pageTabsStyle = this.styleService.getTabsStyles(
        this.currentPage.pageLinkContainerSettings.configurations?.subpages,
        this.mainTemplate.components?.pageLinks?.subpages
      );
      templateContainer.appendChild(pageTabsStyle);
    });
  }

  private checkPinCode(template: TemplateSetting): void {
    if (template?.isPinRequired) {
      this.dialog.open(PinModalComponent, {
        panelClass: 'profile-dialog',
        disableClose: true,
        backdropClass: 'backDropClass',
      });
    }
  }

  private updateCampaigns(couponBody: CouponBody): void {
    const originFilterByCriteria =
      this.mainTemplate.template.pageLinks[this.pageLinkIndex].templateSettings[
        this.selectTabIndex
      ].templateConfig?.filterByCriteria;
    let filterByCriteria: FilterGroup = originFilterByCriteria?.length
      ? JSON.parse(originFilterByCriteria)
      : null;

    const originFilterByTrashold =
      this.mainTemplate.template.pageLinks[this.pageLinkIndex].templateSettings[
        this.selectTabIndex
      ].templateConfig?.filterByTreshold;
    let filterByTrashold: FilterGroup = originFilterByTrashold?.length
      ? JSON.parse(originFilterByTrashold)
      : null;

    this.accessRequestId = couponBody.accessRequestId;
    if (
      filterByCriteria &&
      Object.keys(filterByCriteria)?.length &&
      couponBody?.campaigns?.length
    ) {
      this.campaigns = couponBody?.campaigns.filter((campaign) => {
        return this.offerService.filterByGroup(filterByCriteria, campaign);
      });
    } else {
      this.campaigns = couponBody.campaigns ?? [];
    }

    if (filterByTrashold && Object.keys(filterByTrashold).length) {
      this.campaigns = this.campaigns.map((campaign) => {
        campaign.isTrashold = true;

        if (this.offerService.filterByGroup(filterByTrashold, campaign)) {
          campaign.isTrashold = false;
        }

        return campaign;
      });
    }

    this.isLoading = false;
  }

  public reloadTab(): void {
    this.getDrawing();
  }

  public tabChanged(tabChangeEvent: any): void {
    this.isLoading = false;
    this.unsubscriber$.next(null);

    this.selectTabIndex = (tabChangeEvent as MatTabChangeEvent).index;

    const page =
      this.mainTemplate.template.pageLinks[this.pageLinkIndex].templateSettings[
        this.selectTabIndex
      ];

    // this.checkPinCode(page);
    console.log(page);
    const isScrollbarStylesExists = !!document.getElementById('scrollStyles');

    switch (page.templateName) {
      case this.tabType.Offers:
        this.isLoading = true;
        this.campaigns = null;

        this.offerService
          .getCampaigns(page)
          .pipe(takeUntil(this.unsubscriber$))
          .subscribe({
            next: (couponBody) => {
              this.handleCouponbookBody(couponBody, page);
              if (!isScrollbarStylesExists) {
                this.addScrollbarStyles(this.mainTemplate);
              }
            },
            error: (err) => {
              this.logService.writeLog(JSON.stringify(err));
            },
          });
        break;
      case this.tabType.OffersAndOffersCms:
        this.campaigns = null;
        this.cmsOffers = null;

        if (!isScrollbarStylesExists) {
          this.addScrollbarStyles(this.mainTemplate);
        }

        this.processCmsOffers();
        this.getCampaigns(
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex]
        );
        break;
      case this.tabType.OffersCms:
        this.cmsOffers = null;
        this.isLoading = true;
        if (!isScrollbarStylesExists) {
          this.addScrollbarStyles(this.mainTemplate);
        }
        this.processCmsOffers();
        break;
      case this.tabType.Drawing:
        this.drawings = null;
        if (!isScrollbarStylesExists) {
          this.addScrollbarStyles(this.mainTemplate);
        }
        this.getDrawing(true);
        break;
      case this.tabType.OffersAndDrawings:
        this.campaigns = null;
        this.drawings = null;
        if (!isScrollbarStylesExists) {
          this.addScrollbarStyles(this.mainTemplate);
        }
        this.getDrawing(true);
        this.getCampaigns(
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex]
        );
        break;
      case this.tabType.OffersCmsOffersAndDrawings:
        this.cmsOffers = null;
        this.campaigns = null;
        this.drawings = null;
        if (!isScrollbarStylesExists) {
          this.addScrollbarStyles(this.mainTemplate);
        }
        this.getDrawing(true);
        this.processCmsOffers();
        this.getCampaigns(
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex]
        );
        break;
      case this.tabType.Redemptions:
      case this.tabType.GiftCard:
        this.removeScrollbarStyles();
        this.redemptionAmountValue = 0;
        break;

      case this.tabType.WinAndLoss:
        this.loadWinLoss();
        break;


        case this.tabType.DrawingCms:
        this.bCmsDrawingDisplayModalView = page.templateConfig.openDetailedView?.isVisible;
        if (!isScrollbarStylesExists) {
          this.addScrollbarStyles(this.mainTemplate);
        }
        this.processCmsDrawing()
        break

      default:
        this.removeScrollbarStyles();
    }
  }

  public removeScrollbarStyles() {
    document.getElementById('scrollStyles')?.remove();
  }

  private addScrollbarStyles(template: any) {
    const styles = `
    .mat-mdc-tab-body-content {
      margin-right:0 !important;
      margin-left:0 !important;
      margin-top:0 !important;
      overflow:auto !important;
    }
      ${template?.globalVariables?.scrollSettings?.width ? '::-webkit-scrollbar {width:' + 
        template?.globalVariables?.scrollSettings?.width + 'px;}' : ''}
    ::-webkit-scrollbar-thumb {
      ${template?.globalVariables?.scrollSettings?.color ? 'background:' + template?.globalVariables?.scrollSettings?.color + ';' : ''}
      ${template?.globalVariables?.scrollSettings?.radius ? 'border-radius:' + template?.globalVariables?.scrollSettings?.radius + ';' : ''}
    }
    `;
    const body = document.getElementsByTagName('body')[0];
    const stylesheet = document.createElement('style');
    stylesheet.id = 'scrollStyles';
    stylesheet.type = 'text/css';
    stylesheet.appendChild(document.createTextNode(styles));
    body.appendChild(stylesheet);
  }

  public showProfileRow(type: ModalType): boolean {
    switch (type) {
      case ModalType.Email:
        return !!this.profileService.playerProfile?.emails?.length;
      case ModalType.Phone:
        return (
          !!this.profileService.playerProfile?.phones?.length ||
          !!this.profileService.playerProfile?.phoneNumbers?.length
        );
      case ModalType.Address:
        return !!this.profileService.playerProfile?.addresses?.length;
      default:
        return true;
    }
  }

  public openModal(type: ModalType, tab: TemplateSetting): void {
    let modal: any;
    let propertyName: string = '';

    switch (type) {
      case ModalType.Name:
        modal = ViewNameComponent;
        propertyName = 'name';
        break;
      case ModalType.Nickname:
        modal = ViewNickNameComponent;
        propertyName = 'nickName';
        break;
      case ModalType.Email:
        modal = ViewEmailComponent;
        propertyName = 'email';
        break;
      case ModalType.Phone:
        modal = ViewPhoneComponent;
        propertyName = 'phoneNumber';
        break;
      case ModalType.Pin:
        modal = ViewPinComponent;
        propertyName = 'pin';
        break;
      case ModalType.Address:
        modal = ViewAddressesComponent;
        propertyName = 'preferredAddress';
        break;
    }

    const dialogRef = this.dialog.open(modal, {
      panelClass: 'profile-dialog',
      backdropClass: 'backDropClass',
      data: {
        currentPage: this.currentPage,
        tabData: tab,
        propertyName,
      },
    });

    if (this.profileService.isUcSecondVersion) {
      dialogRef
        .afterClosed()
        .pipe(
          filter(Boolean),
          switchMap((clonedProfile: any) => {
            this.isLoading = true;
            return this.profileService.updateProfile(
              clonedProfile,
              propertyName
            );
          })
        )
        .subscribe(
          () => {
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          }
        );
    } else {
      dialogRef
        .afterClosed()
        .pipe(
          filter((cloneProfile: any | null) => {
            return cloneProfile !== null;
          }),
          switchMap((cloneProfile: any) => {
            this.isLoading = true;
            return this.profileService.updateProfile(
              cloneProfile,
              propertyName
            );
          }),
          take(1)
        )
        .subscribe(
          () => {
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          }
        );
    }
  }

  public removeExpiredCampaign(id: string) {
    this.isLoading = true;
    const index = this.campaigns?.findIndex((campaign) => campaign.id === id);
    if (index !== -1) {
      this.campaigns?.splice(index as number, 1);
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    } else {
      this.updateOffers();
    }
  }

  public getConversionType() {
    const type = (
      this.mainTemplate.template.pageLinks[this.pageLinkIndex].templateSettings[
        this.selectTabIndex
      ] as any
    )?.templateConfig?.conversionType;
    if (!type) {
      return;
    }
    return this.conversionType.find((v) => v.value === type);
  }

  public getRedemptionInformationHtml(html: string) {
    if (!html) {
      return;
    }
    const valuesToChange = [
      {
        templateVariable: '{{conversionRateFrom}}',
        templateVariableValue: this.getTierRate().rateFrom,
      },
      {
        templateVariable: '{{conversionRateTo}}',
        templateVariableValue: this.getTierRate().rateTo,
      },
      {
        templateVariable: '{{conversionType}}',
        templateVariableValue: this.getConversionType()?.title,
      },
    ];
    valuesToChange.forEach((value) => {
      html = html.replace(
        value.templateVariable,
        String(value.templateVariableValue)
      );
    });
    this.appendRedemptionStyles();
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  private appendRedemptionStyles() {
    if (document.getElementById('redemptionStyles')) {
      return;
    }
    if (this.styleService.isQt3Kiosk) {
      if (
        (
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex] as any
        )?.templateConfig?.description?.css
      ) {
        (
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex] as any
        ).templateConfig.description.css =
          this.styleService.dividePixelStylesByTwo(
            (
              this.mainTemplate.template.pageLinks[this.pageLinkIndex]
                .templateSettings[this.selectTabIndex] as any
            )?.templateConfig?.description?.css
          );
      }
    }
    const styles = (
      this.mainTemplate.template.pageLinks[this.pageLinkIndex].templateSettings[
        this.selectTabIndex
      ] as any
    )?.templateConfig?.description?.css;

    const body = document.getElementsByTagName('body')[0];
    const style = document.createElement('style');
    style.id = 'redemptionStyles';
    style.type = 'text/css';
    style.appendChild(document.createTextNode(styles));
    body.appendChild(style);
  }

  public addRedemptionValue(value: number) {
    if (this.isEmptyConversionRate) {
      return;
    }
    const pointsNedded = this.calculateNeededPointsToRedeem(
      Number(this.redemptionAmountValue.toString() + value)
    ).pointsNeeded;
    if (isNaN(pointsNedded)) {
      return;
    }
    const isValueMoreThanPoints =
      pointsNedded > this.profileService.playerAccountPointBalance;
    if (isValueMoreThanPoints) {
      this.addMaxAmount();
      return;
    }
    this.redemptionAmountValue = Number(
      this.redemptionAmountValue.toString() + value
    );
  }

  public keyboardBackspace() {
    const redemptionAmountValueArr = this.redemptionAmountValue
      .toString()
      .split('');
    this.redemptionAmountValue = Number(
      redemptionAmountValueArr.slice(0, -1).join('')
    );
  }

  public substractValue(value: string) {
    if (this.redemptionAmountValue - Number(value) < 0) {
      return;
    }
    this.redemptionAmountValue -= Number(value);
  }

  public addMaxAmount() {
    if (this.isEmptyConversionRate) {
      return;
    }

    const { rateFrom, rateTo } = this.getConversioRate();
    const pointsRatio = rateFrom / rateTo;
    if (isNaN(pointsRatio)) {
      return;
    }
    const maxAmount = Math.floor(
      this.profileService.playerAccountPointBalance / pointsRatio
    );
    this.redemptionAmountValue = maxAmount;
  }

  public clearRedemptionAmount() {
    this.redemptionAmountValue = 0;
  }

  public getButtonStyle(v: any, tab: any, isRemoveButton: boolean) {
    const conversionRate = this.getConversioRate();
    const isConversionRateExists =
      !!conversionRate.rateFrom && !!conversionRate.rateTo;
    let buttonStyleState: string = '';
    if (isRemoveButton) {
      buttonStyleState =
        Number(this.redemptionAmountValue) > 0 &&
        Number(this.redemptionAmountValue) - Number(v) >= 0 &&
        isConversionRateExists
          ? 'removeButton'
          : 'disabledremoveButton';
    } else {
      buttonStyleState =
        !this.isRedemptionValueMoreThanBalance(Number(v)) &&
        isConversionRateExists
          ? 'addButton'
          : 'disabledAddButton';
    }
    return this.styleService.getStyleRule(
      [tab.configurations.preSets[buttonStyleState].text],
      [
        tab.configurations.preSets[buttonStyleState].background,
        {
          color:
            this.mainTemplate.globalVariables.buttonsColor[
              !isRemoveButton ? 'primaryColor' : 'secondaryColor'
            ],
        },
      ],
      [tab.configurations.preSets[buttonStyleState].border]
    );
  }

  public additionRedemptionAmount(value: string) {
    if (this.isEmptyConversionRate) {
      return;
    }
    if (this.isRedemptionValueMoreThanBalance(Number(value))) return;
    this.redemptionAmountValue =
      Number(this.redemptionAmountValue) + Number(value);
  }

  private isRedemptionValueMoreThanBalance(value: number) {
    const accountBalance = this.profileService.playerAccountPointBalance;
    return (
      this.calculateNeededPointsToRedeem(
        Number(this.redemptionAmountValue) + value
      ).pointsNeeded > accountBalance
    );
  }

  public currencyInputChanged(value: string) {
    var num = value.replace(/[$,]/g, '');
    this.redemptionAmountValue = Number(num);
  }

  public getPhoneNumber(phoneNumbers: any[]): string {
    if (this.profileService.isUcSecondVersion) {
      const mobilePhone = this.playerProfile.phones.find(
        (x: { type: string }) => x.type === 'Home'
      )?.phoneNumber;
      const additionalPhone = this.playerProfile.phones.find(
        (x: { type: string }) => x.type === 'Alternate'
      )?.phoneNumber;
      return mobilePhone || additionalPhone || '';
    }
    const mobilePhone = phoneNumbers.find(
      (x) => x.location === 'Mobile'
    )?.number;
    const additionalPhone = phoneNumbers.find(
      (x) => x.location === 'Home'
    )?.number;

    return mobilePhone || additionalPhone || '';
  }

  private openInfoBlock(title: string, text: string, css: string, callback?: Function): void {
    this.dialog.open(InfoModalComponent, {
      panelClass: 'profile-dialog',
      backdropClass: 'backDropClass',
      width: '100vw',
      data: {
        callback: async () => {
          if (callback) {
            await callback();
          }
        },
        title,
        text,
        css,
        styles: (
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex]
            .configurations as unknown as RedemptionsConfigModel
        ).popUp,
        mainTemplate: this.mainTemplate,
      },
    });
  }

  public openRedeemConfirmation(): void {
    if (!this.redemptionAmountValue) return;

    const config = this.mainTemplate.template.pageLinks[this.pageLinkIndex]
    .templateSettings[this.selectTabIndex].templateConfig as any;

    if (config?.minValue && this.redemptionAmountValue < config.minValue) {
      return this.openInfoBlock(
        config.ivalidMinAmountTitle,
        config.ivalidMinAmountText,
        '.content.info-modal-content .message-text {color: #F14823;}'
      );
    }

    if (config?.maxValue && this.redemptionAmountValue > config.maxValue) {
      return this.openInfoBlock(
        config.ivalidMaxAmountTitle,
        config.ivalidMaxAmountText,
        '.content.info-modal-content .message-text {color: #F14823;}'
      );
    }

    const conversionType = this.getConversionType();
    this.dialog.open(ConfirmRedeemModalComponent, {
      panelClass: 'profile-dialog',
      backdropClass: 'backDropClass',
      height: '18vh',
      width: '100vw',
      data: {
        callback: async () => {
          await this.adjustingRedemptionValues();
        },
        redeemValue: this.redemptionAmountValue,
        pointToReedem: this.calculatePointsToRedeem(),
        pointsRate: this.getConversioRate(),
        mainTemplate: this.mainTemplate,
        pageIndex: this.pageLinkIndex,
        templateIndex: this.selectTabIndex,
        conversionType: conversionType?.title,
        currentTemplate:
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex],
        styles: (
          this.mainTemplate.template.pageLinks[this.pageLinkIndex]
            .templateSettings[this.selectTabIndex]
            .configurations as unknown as RedemptionsConfigModel
        ).popUp,
      },
    });
  }

  private async adjustingRedemptionValues() {
    return new Promise(async (resolve, reject) => {
      const onBadRequest = (msg: string) => {
        this.redemptionAmountValue = 0;
        this.isLoading = false;
        reject(msg);
      };
      this.isLoading = true;
      const adjRes = await this.profileService.doBalanceAdjustment(
        this.playerProfile?.playerId,
        this.calculatePointsToRedeem(),
        'Points',
        'D'
      );
      if (
        this.getConversionType()?.value === ConversionType.freePlay &&
        !adjRes.status
      ) {
        const res = await this.profileService.doBalanceAdjustment(
          this.playerProfile?.playerId,
          this.redemptionAmountValue,
          'XtraCredit',
          'C'
        );
        if (res.status || res.error) {
          await this.profileService.doBalanceAdjustment(
            this.playerProfile?.playerId,
            this.calculatePointsToRedeem(),
            'Points',
            'C'
          );
          onBadRequest(res.status);
          return;
        }
      }
      if (adjRes.error || adjRes.status) {
        onBadRequest(adjRes.status);
        return;
      }
      if (
        this.profileService.isUcSecondVersion &&
        this.profileService.playerBalances?.a_Points.amount
      ) {
        this.profileService.playerBalances.a_Points.amount =
          this.profileService.playerBalances?.a_Points.amount -
          this.calculatePointsToRedeem();
      } else {
        this.playerProfile.gamingInfo.PlayerAccountBalance =
          this.playerProfile?.gamingInfo?.PlayerAccountBalance -
          this.calculatePointsToRedeem();
      }

      this.redemptionAmountValue = 0;
      await this.profileService.getPlayerBalance(this.playerProfile.playerId);
      this.isLoading = false;
      resolve(true);
    });
  }

  public calculatePointsToRedeem(): number {
    const { pointsNeeded, accountBalance } = this.calculateNeededPointsToRedeem(
      this.redemptionAmountValue
    );
    if (pointsNeeded > accountBalance) {
      return 0;
    }
    return pointsNeeded;
  }

  private calculateNeededPointsToRedeem(dollarsValue: number) {
    if (this.isEmptyConversionRate) {
      return {
        pointsNeeded: 0,
        accountBalance: this.profileService.playerAccountPointBalance,
      };
    }

    const { rateFrom, rateTo } = this.getConversioRate();
    const accountBalance = this.profileService.playerAccountPointBalance;
    const pointsRatio = rateFrom / rateTo;
    const pointsNeeded = dollarsValue * pointsRatio;
    return {
      pointsNeeded: Math.ceil(pointsNeeded),
      accountBalance,
    };
  }

  private getCurrentTierLevel() {
    if (this.mainTemplate.tierLevels.isCmsValueInUse) {
      return;
    }
    const tiers = this.mainTemplate.tierLevels.tiers;
    const lastTier = tiers.at(-1);
    /**TODO find a way to provide correct value */
    const playerPointsBalance = Number(
      this.profileService
        .fallBackDataPointValues()
        .pointBalance?.replaceAll(',', '') ?? 0
    );
    if (
      (lastTier && (lastTier.to === 0 || !lastTier)) ||
      playerPointsBalance > (lastTier?.to ?? 0)
    ) {
      return lastTier;
    }
    const currentTierIndex = tiers.findIndex((tier, index) => {
      if (tier.to === 0 || (isNaN(tier.to) && index === tiers.length - 1)) {
        tier.to = 9999999999;
      }
      return tier.from <= playerPointsBalance && playerPointsBalance <= tier.to;
    });
    if (currentTierIndex !== -1) {
      return tiers[currentTierIndex];
    }
  }

  private getConversioRate() {
    const page =
      this.mainTemplate.template.pageLinks[this.pageLinkIndex].templateSettings[
        this.selectTabIndex
      ];
    if (page.templateName !== this.tabType.Redemptions) {
      return { rateFrom: 0, rateTo: 0 };
    }
    const tierLevelRate = this.getCurrentTierLevel();
    if (!tierLevelRate && !this.mainTemplate.tierLevels.isCmsValueInUse) {
      return { rateFrom: 0, rateTo: 0 };
    }
    const templateConfig: RedemptionTemplateConfigModel =
      page.templateConfig as any;
    const playerTierLevel = this.profileService.isUcSecondVersion
      ? this.playerProfile.club.currentClub
      : this.playerProfile.gamingInfo.tierLevel;
    if (
      templateConfig?.conversionRate === 'tiered' &&
      (tierLevelRate?.status || (tierLevelRate?.from && tierLevelRate?.to))
    ) {
      const rate = (templateConfig.tiredConversionRate as TierLevels[]).find(
        (tier) => tier.guid === tierLevelRate.guid
      );
      if (rate) {
        return {
          rateFrom: Number(rate.rateFrom || 0),
          rateTo: Number(rate.rateTo || 0),
        };
      }
      return { rateFrom: 0, rateTo: 0 };
    }
    if (templateConfig?.conversionRate === 'tiered') {
      const rateConfig = (
        templateConfig.tiredConversionRate as TierLevels[]
      ).find(
        (tier) =>
          tier.tierLevel === playerTierLevel.id ||
          tier.tierLevel === playerTierLevel.name
      );
      if (rateConfig) {
        return {
          rateFrom: Number(rateConfig.rateFrom),
          rateTo: Number(rateConfig.rateTo),
        };
      }
      return { rateFrom: 0, rateTo: 0 };
    }
    return {
      rateFrom: Number(templateConfig.rateFrom),
      rateTo: Number(templateConfig.rateTo),
    };
  }

  public getTierRate() {
    if (this.isEmptyConversionRate) {
      return { rateFrom: 'N/A', rateTo: 'N/A' };
    }
    const { rateFrom, rateTo } = this.getConversioRate();
    return { rateFrom, rateTo };
  }

  public getProfileName() {
    /**TODO find a way to provide correct value */
    return this.profileService.fallBackDataPointValues().playerName;
  }

  public getProfileEmail() {
    return this.playerProfile.emails?.[0]?.address;
  }

  override ngOnDestroy() {
    this.removeScrollbarStyles();
    this.unsubscriber$.next(null);
  }

}
